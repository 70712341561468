export default {
    "settings.network.connectionType":"Connection type",
    "settings.network.dynamic":"Dynamic IP Address",
    "settings.network.static":"Static IP Address",
    "settings.network.ip":"Ip Address",
    "settings.network.mask":"Subnet Mask",
    "settings.network.gateway":"Gateway",
    "settings.network.DNS":"DNS",
    "settings.network.validIP":"Please input a valid ip address",
    "settings.network.change.title":"Device will restart.",
    "settings.network.change.content":"IP has modified and the device need to restart.",
    "settings.camera.type":"Camera type",
    "settings.password.oldpassword":"Old Password",
    "settings.network.speed.tips":"Current network speed{value}(Recommended network speed 1000Mb/s)",
    "settings.network.se3.tips":"SE3 does not support LAN port settings",
    "settings.password.newpassword":"New Password",
    "settings.password.repassword":"Retype New Password",
    "settings.password.length":"new password cannot be length then 10 charactors",
    "settings.password.notMatch":"New password and repeat new password are not match",
    "settings.password.originalPasswordError":"Original Password not correct",
    "settings.workmode.local":"Stand-alone mode",
    "settings.workmode.appia":"Management platform mode",
    "settings.workmode.appia.url":"Management platform login url",
    "settings.workmode.appia.devsno":"Sno",
    "settings.workmode.mq.login_url.help":'Please contact the management platform administrator for the address. For example: http: //xx.xx.xx.xx: 9061/api/gateway/login',
    "settings.workmode.mq.notExist":'The requested sno code does not exist',
    "settings.workmode.mq.get":"Contact the management platform administrator for the sno",
    "settings.workmode.change.title":"Are you sure to change work mode?",
    "settings.workmode.change.content":"Change work mode will reset DataBase and restart device or restart application,user data will be droped!",
    "settings.workmode.mq":"Management platform",
    "setting.box.restart.loading":'The box is being restarted. Please wait...',
    "settings.workmode.mq.change.title":"Are you sure to modify the configuration of the mode?",
    "settings.workmode.mq.content":"Modifying the configuration will restart the device and may result in loss of user data!",
    "settings.isSuportStranger.setSuccess":'Successfully set isSuportStranger',
    "settings.duplicateTime.setSuccess":'Successfully set duplicateTime',
    "settings.setThreshold.setSuccess":'Successfully set threshold',
    "settings.threshold":"THRESHOLD",
    "settings.IsSuportStranger":"Strangers automatically warehousing",
    "settings.duplicateTime":"Identification record de-duplication interval",
    "settings.threshold.invalid":"Please enter an integer from 0 to 100",
    "settings.duplicateTime.invalid":"Please enter an integer from 1 to 60",
    "settings.event.realTime.tag":"Realtime Event Server URL",
    "settings.event.tips":"Input a list of url, split urls with ','",
    "settings.event.url":"Server URL",
    "settings.event.History.tag":"History Event Server URL",
    "settings.event.History.peroid":"Report peroid",
    "settings.event.History.seconds":"Second(s)",
    "settings.event.contentType":"Content type",
    "settings.update.public.tag":"Public Cloud",
    "settings.update.private.tag":"Private Cloud",
    "settings.update.local.tag":"Local Update",
    "settings.update.currentVersion":"Current version",
    "settings.update.latestVersion":"Latest version",
    "settings.update.privateUrl":"OTA server address",
    "settings.update.local.title":"Support upload file:*.zip, *.tar or *.tgz",
    "settings.update.local.msg":`Please package the upgrade file into zip,tar or tgz format upload at one time.Then rename the file name as the version number.Version number naming like "VxRxCxx"`,
    "settings.update.local.speed":"{percent}%, speed: {speed} M/s",
    "settings.update.local.Upload":"Upload",
    "settings.update.local.updating...":"Updating...",
    "settings.update.local.checking file...":"Checking file...",
    "settings.update.local.Reading file...":"Reading file...",
    "settings.update.local.Uploading file...":"Uploading file...",
    "settings.update.public.updating":"{msg},do not restart or shutdown!!!",
    "settings.update.public.noVersion":"No public cloud version  :{msg}",
    "settings.update.private.url":"Set url success",
    "settings.update.local.readFileError":"Read file error",
    "settings.update.local.updating":"Device is updating...do not restart or shutdown!",
    "settings.update.local.versionNotMatch":"File name not match version naming rule",
    "settings.update.local.versionOld":"The uploaded version must newer than current version",
    "settings.update.local.fileError":"Please upload zip,tar or tgz file",
    "settings.relogin.content":"Re-login to enable the change, continue?",
    "settings.relogin.ipc.content":"This switch device type will reset the database and restart the device.",
    "settings.update.pulic":"Public cloud settings will recover private cloud settings",
    "settings.update.private":"Private cloud settings will recover public cloud settings",
    "settings.update.error":"Device update error!",
    "settings.update.complete":"Device update complete!",
    "settings.update.version":"Current version is: {version}",
    "settings.time.ntp.label":"Ntp server",
    "settings.time.manual.label":"Manual setting",
    "settings.time.manual.date":"Date",
    "settings.time.manual.time":"Time",
    "settings.time.currenttime":"Current time",
    "settings.time.type":"Type",
    "settings.time.stay":"Stay",
    "settings.time.manual.warning.required":"Must set date or time",
    "settings.monitor.url":"Monitor Server URL",
    "settings.event.url.placeholder":`Input a list of url, split urls with ","`,
    "settings.event.peroid":"At least {peroid}s",
    "settings.event.peroid.format":"Please enter a integer greater than or equal to {peroid}",
    "settings.event.dev_sno":"Dev_sno",
    "settings.event.login_url":"Login_url",
    "settings.event.cloud_server_address":"Cloud Server Address",
    "settings.event.mq.tag":"MQ setting",

    "settings.reboot.weeks.never":'Never',
    "settings.reboot.weeks.daily":'Daily',
    "settings.reboot.weeks.monday":'Every monday',
    "settings.reboot.weeks.tuesday":'Every tuesday',
    "settings.reboot.weeks.wednesday":'Every wednesday',
    "settings.reboot.weeks.thursday":'Every thursday',
    "settings.reboot.weeks.friday":'Every friday' ,
    "settings.reboot.weeks.saturday":'Every saturday',
    "settings.reboot.weeks.sunday":'Every sunday',

    "settings.usage.type":'Usage',
    "settings.usage.normal" : 'Generic type',
    "settings.usage.fiqa" :'FIQA only',
    "settings.usage.feature":'Special for feature extraction',


    "setting.smart.attributes.tab.title": 'Face attributes',
    "setting.smart.recognition.tab.title": 'Face recognition parameters',
    "setting.smart.capture.tab.title": 'Face capture parameters',
    "setting.smart.capture.form.mode.applyMode": 'Open capture mode',
    "setting.smart.capture.form.time": 'Capture time period {value}',
    "setting.smart.capture.form.threshold": 'Sensitivity',
    "setting.smart.capture.form.grabMode": 'Capture mode',
    "setting.smart.capture.form.grabMode.speed": 'Quick capture',
    "setting.smart.capture.form.grabMode.quality": 'Quality first',
    "setting.smart.capture.form.photoUpload": 'Picture upload',
    "setting.smart.capture.form.photoUpload.face": 'Face map',
    "setting.smart.capture.form.photoUpload.back": 'Large background',
    "setting.smart.capture.form.photoUpload.backFace": 'Face image + background image',
    "setting.smart.capture.form.qualityFilter": 'Quality filtration standard',
    "setting.smart.capture.form.qualityFilter.off": 'Off',
    "setting.smart.capture.form.qualityFilter.low": 'Low',
    "setting.smart.capture.form.qualityFilter.middle": 'Middle',
    "setting.smart.capture.form.qualityFilter.high": 'High',
    "setting.smart.capture.form.clarity": 'Definition',
    "setting.smart.capture.form.brightness": 'Brightness',
    "setting.smart.capture.form.occlude": 'Occlusion',
    "setting.smart.capture.form.pitch": 'Pitch angle',
    "setting.smart.capture.form.yaw": 'Yaw angle',
    "setting.smart.capture.form.roll": 'Roll angle',

    "setting.smart.capture.form.time.require":"Please fill in the capture time",
    "setting.smart.recognition.form.time.require":"Please fill in the deployment time",

    "setting.smart.recognition.form.time.baseStringer":'Stranger library capacity',
    "setting.smart.recognition.form.time.baseUser":'Capacity of personnel base',
    "setting.smart.recognition.formItem.reboot.tips":'（If the capacity is modified, the device will restart automatically. If the capacity is reduced, the database will be emptied;The scope of clearing includes: person, stranger, device list, event, permission group, etc）',
    "setting.smart.recognition.form.time.thousand":'{value} thousand',

    "setting.smart.recognition.form.applyMode": 'Turn on recognition mode',
    "setting.smart.recognition.form.IsSuportStranger":"Strangers automatically enter the warehouse",
    "setting.smart.recognition.form.duplicateTime":"Identification record de duplication interval",
    "setting.smart.recognition.form.threshold":"Matching threshold",
    "setting.smart.recognition.form.regTimePart":"Deployment time period {value}",
    "setting.smart.recognition.form.regMinFace":"Minimum face pixel", 
    "setting.smart.recognition.form.regMinFace.invalid":"Please enter an integer from 20 to 300", 

    "setting.smart.attributes.form.applyMode": 'Turn on property detection',
    "setting.smart.attributes.form.gender": 'Gender',
    "setting.smart.attributes.form.age": 'Age',
    "setting.smart.attributes.form.glasses": 'Glasses',
    "setting.smart.attributes.form.mask": 'Mask',
    "setting.smart.attributes.form.hat": 'Hat',
    "setting.smart.attributes.form.Sunglasses": 'Sunglasses',

    // "setting.system.log.get.sure":'Are you sure to start collecting logs',
    // "setting.system.log.get.tips.content":'It will take a few minutes to collect the logs. Please check the fixed path when the collection is completed',

    "setting.system.log.get.sure":'Are you sure to start collecting logs',
    "setting.system.log.get.tips.content":'It will take a few minutes to collect the logs. Please check the fixed path when the collection is completed',
    "setting.system.log.get.collect":'Log collection',
    "setting.system.log.get.timeTips":'The collected compressed log files will be automatically saved to the browser download directory',
    "setting.system.log.get.user":'Export user log',
    "setting.system.log.get.system":'Export system log',
    "setting.system.tytd.title":'TTYD',
    "setting.system.cellular":'Cellular data',
    "setting.system.cellular.rareFlow":'Mobile data',
    "setting.system.cellular.rareFlow.tips":'(After startup, the generated traffic will be charged by the operator)',
    "setting.system.cellular.rareFlow.connectType":'Priority connection mode',
    "setting.system.cellular.rareFlow.connectType.flow":'Priority data traffic',
    "setting.system.cellular.rareFlow.connectType.broadband":'Priority wired broadband',
    "setting.system.cellular.rareFlow.connectType.flow.tips":'(Priority should be given to the use of data traffic. In case of data traffic failure, it is necessary to manually switch to wired broadband)',
    "setting.system.cellular.rareFlow.connectType.broadband.tips":'(Priority should be given to wired broadband. In case of cable broadband failure, it is necessary to manually switch to data traffic)',
    "setting.system.cellular.rareFlow.noSignal":'No signal',
    "setting.system.cellular.rareFlow.connected":'Connected',
    "setting.system.cellular.rareFlow.noCard":'Card not detected',
    "setting.system.cellular.rareFlow.signalCheck":'Signal searching...',
    "setting.system.cellular.connectType.check":'(Getting...)',
}

// function getImageOrintation(file) {
//     return new Promise(
//         (resolve, reject) => {
//             const Freader = new FileReader();
//             Freader.onerror = () => {
//                 reject(false)
//             }
//             Freader.onload = (e) => {
//                 // http://stackoverflow.com/questions/7584794/accessing-jpeg-exif-rotation-data-in-javascript-on-the-client-side
//                 const view = new DataView(e.target.result);
//                 // not jpeg
//                 try{
//                     if (view.getUint16(0, false) !== 0xffd8) {
//                         console.log('not regular jpg??')
//                         reject(-1)
//                     }
//                 }catch(e){
//                     reject(false);
//                     console.log('read file catch error:',e);
//                 }
//                 const length = view.byteLength;
//                 let offset = 2;
//                 while (offset < length) {
//                     const marker = view.getUint16(offset, false);
//                     offset += 2;
//                     if (marker === 0xffe1) {
//                         const tmp = view.getUint32((offset += 2), false);
//                         if (tmp !== 0x45786966) {
//                             resolve(-1);
//                         }
//                         const little = view.getUint16((offset += 6), false) === 0x4949;
//                         offset += view.getUint32(offset + 4, little);
//                         const tags = view.getUint16(offset, little);
//                         offset += 2;
//                         for (let i = 0; i < tags; i++) {
//                             if (view.getUint16(offset + i * 12, little) === 0x0112) {
//                                 resolve(view.getUint16(offset + i * 12 + 8, little));
//                             }
//                         }
//                     } else if ((marker & 0xff00) !== 0xff00) {
//                         break;
//                     } else {
//                         offset += view.getUint16(offset, false);
//                     }
//                 }
//                 resolve(-1);

//             }
//             Freader.readAsArrayBuffer(file.slice(0, 64*1024));
//         }
//     ).catch(
//         (err) => {
//             return err;
//         }
//     )
// }
function loadImageFromBinary(file) {
    const image = document.createElement('img');
    const ImageReader = new FileReader()
    return new Promise(
        (resolve, reject) => {
            image.onload = () => {
                resolve(image);
            }
            ImageReader.onload = () => {
                image.src = ImageReader.result;
            }
            ImageReader.onerror = () => {
                reject(null);
            }
            ImageReader.readAsDataURL(file);


        }
    )
}
async function fixImage(file, compressed = false, config) {
    // 暂时不实现压缩图片的方法
    if (compressed) {
        return '';
    }
    // let isMac =  /macintosh|mac os x/i.test(navigator.userAgent);
    // let Orintation = (await getImageOrintation(file));
    // console.log('图片Orintation:', Orintation,'isMac',isMac);
    const img = await loadImageFromBinary(file);
    return img.src;
    // if (Orintation) {
    //     const img = await loadImageFromBinary(file);
        // const canvas = document.createElement('canvas');
        // const context = canvas.getContext('2d');
        // const pic_width = img.width;
        // const pic_height = img.height;
        // return img.src;
        // if(!isMac){ // 不是mac 无需旋转
        //     return img.src;
        // }
        /**
         * Orintation 参数的含义（在原图基础上进行的变换操作，操作后图片表现为展现图）
         * 原图可以看作是转为base64后没有带角度参数的图
         * 展现图可以看作是图片查看器打开图片所看到的图片
         * 1 未变换
         * 2 水平反转
         * 3 顺时针180度
         * 4 垂直反转
         * 5 顺时针90度+水平反转
         * 6 顺时针90度
         * 7 顺时针90度+垂直反转
         * 8 逆时针90度
         */
        // if (Orintation >= 5 && Orintation <= 8) {
        //     // 这个范围的情况都是反转90度，所以原图的宽度是展现图的高度
        //     canvas.width = pic_height;
        //     canvas.height = pic_width;
        // } else {
        //     canvas.width = pic_width;
        //     canvas.height = pic_height;
        // }
        // if (Orintation < 2) {
        //     // 1或-1都是代表无旋转角度，直接返回原图
        //     return img.src;
        // } else {
        //     switch (Orintation) {
        //         //左右反转
        //         case 2:
        //             context.transform(-1, 0, 0, 1, pic_width, 0); break;
        //         //顺时针180度
        //         case 3:
        //             context.transform(-1, 0, 0, -1, pic_width, pic_height); break;
        //         //上下反转
        //         case 4:
        //             context.transform(1, 0, 0, -1, 0, pic_height); break;
        //         //顺时针90度后水平反转
        //         case 5:
        //             context.transform(0, 1, 1, 0, 0, 0); break;
        //         //逆时针90度
        //         case 6:
        //             context.transform(0, 1, -1, 0, pic_height, 0); break;
        //         //顺时针90度垂直反转
        //         case 7:
        //             context.transform(0, -1, -1, 0, pic_height, pic_width); break;

        //         //顺时针90度
        //         case 8:
        //             context.transform(0, -1, 1, 0, 0, pic_width); break;
        //         default:
        //         //没有角度信息或角度为1
        //         //context.transform(1, 0, 0, 1, 0, 0);

        //     }
        //     context.drawImage(img, 0, 0);
        //     return canvas.toDataURL('image/jpeg');
        // }


    // } else {
    //     return null;
    // }
}

export { fixImage }

const IPCStream = {
    config: [{
        key: 'IPCStream',
        text: 'Video camera(Stream)'
    },
    {
        key: 'IPCStreamAddList',
        text: 'Add Video camera(Stream)'
    }],
    // history: {
    //     key: 'History',
    //     text: 'History'
    // }
};

// const Info = {
//     key: 'Info',
//     icon: 'info-circle-o',
//     text: 'Information',
//     subMenu: [
//         {
//             key: 'Basic',
//             text: 'Basic information'
//         }
//     ]
// }
const Config = {
    key: 'Config',
    icon: 'sliders',
    text: 'Config',
    subMenu: []
}
const User = {
    key: 'User',
    icon: 'user',
    text: 'User',
    subMenu: [
        {
            key: 'UserList',
            text: 'User list'
        },
        {
            key: 'PermissionGroup',
            text: 'Permission Group'
        }
    ]
}
const EventHistory = {
    key: 'Event',
    icon: 'clock-circle',
    text: 'Event',
    subMenu: [
        {
            key: 'History',
            text: 'History'
        },
        {
            key: 'Image',
            text: 'Image'
        },
        {
            key: 'Face',
            text: 'Face'
        },   {
            key: 'Base',
            text: 'Base'
        },
    ]
}
const System = {
    key: 'System',
    icon: 'setting',
    text: 'System',
    subMenu: [
        {
            key: 'Basic',
            text: 'Basic information'
        },
        {
            key: 'NetWorkSetting',
            text: 'Network Setting'
        },
        {
            key: 'SmartSetting',
            text: 'smart Setting'
        },
        {
            key: 'WorkMode',
            text: 'Work Mode'
        },
        {
            key: 'Application',
            text: 'Application'
        },
        {
            key: 'EventServerSetting',
            text: 'Event Server Setting'
        },
         {
            key: 'SystemSetting',
            text: 'System Setting'
        },
        // {
        //     key: 'Update',
        //     text: 'Update'
        // },
    ]
}
const System1 = {
    key: 'System',
    icon: 'setting',
    text: 'System',
    subMenu: [
        {
            key: 'Basic',
            text: 'Basic information'
        },
        {
            key: 'NetWorkSetting',
            text: 'Network Setting'
        },
         {
            key: 'SystemSetting',
            text: 'System Setting'
        }
    ]
}
/**
 * create menu
 */
const createMenuData = (system_mode) => {
    let config = [];
    config = IPCStream.config;
    // history = [IPCStream.history];
    Config.subMenu = config || [];
    // EventHistory.subMenu = history || []
    if(system_mode==='1' || system_mode==='2'){
        return [Config,User,EventHistory, System]
    }else{
        return [System1]
    }
}
export default createMenuData
import React, { Component } from 'react'
// import Title from '../common/Title'
import { Layout, Select, message, Form, Button } from 'antd'
import axios from '../../../utils/AxiosWrapper'
import { SOPHON_CONFIG } from '../../../utils/Config';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../../Intel';
import { getConfig } from '../../../services/system'
// const breadcrumbSetting = [{
//     path: '/',
//     breadcrumbName: <FormattedMessage id='home' />
// }, {
//     path: '/System/Basic',
//     breadcrumbName: <FormattedMessage id='sidemenu.System' />
// }, {
//     breadcrumbName: <FormattedMessage id='sidemenu.System.ClientTheme' />
// }];

class ClientTheme extends Component {
    constructor(props) {
        super(props);
        this.state = {
            theme: null,
            loading: true
        }
    }
    componentDidMount() {
        getConfig('THEME').then(res => {
            this.setState({
                theme: res.THEME,
                loading: false
            })
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, value) => {
                if (!err) {
                    this.setTheme(value);
                }
            })
    }
    setTheme = (value) => {
        this.setState({
            loading: true
        })
        axios.put(`${SOPHON_CONFIG}`, {
            "config_dict": value
        }).then(
            () => {
                message.success(intl.formatMessage({ id: 'info.success' }));
                localStorage.setItem('theme', value.THEME);
                getConfig('THEME').then(res => {
                    this.setState({
                        theme: res.THEME,
                        loading: false
                    })
                })
            }
        )
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                {/* <Title items={breadcrumbSetting} /> */}
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 100 }}>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label={<FormattedMessage id='theme.type' />} labelCol={{ span:10 }} wrapperCol={{ span: 8 }}>
                            {getFieldDecorator('THEME', {
                                rules: [{ required: true, message: <FormattedMessage id='input.required' /> }],
                                initialValue: this.state.loading ? undefined : this.state.theme
                            })(
                                <Select  style={{width:240}}  loading={this.state.loading}>
                                    <Select.Option key="PARK"><FormattedMessage id="theme.park" /></Select.Option>
                                    <Select.Option key="SCHOOL"><FormattedMessage id="theme.school" /></Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                        </Form.Item>
                    </Form>
                </Layout>
            </div>
        )
    }
}
export default Form.create()(ClientTheme);


import React, { Component } from 'react'
import { Breadcrumb } from 'antd'
import { Link } from 'react-router-dom'

class Title extends Component {

    render() {
        const items = this.props.items;
        
        return (
            <div style={{ backgroundColor: '#FFFFFF', padding: '16px 32px', height: 60 }}>
                <Breadcrumb>
                    {items.map(
                        (obj, index) => {
                            return (<Breadcrumb.Item key={index}>
                                {index !== items.length - 1 ? (<Link to={obj.path}>{obj.breadcrumbName}</Link>) : obj.breadcrumbName}
                            </Breadcrumb.Item>)
                        }
                    )}
                </Breadcrumb>
                {/* <div style={{ marginTop: 16 }}>
                    <div style={{ fontSize: 20, fontWeight: 500, float: "left" }}>{items[items.length - 1].breadcrumbName}</div>
                    {this.props.switch ?
                        (<div style={{ float: "right" }}>
                            <Switch checked={this.props.checked} checkedChildren={<Icon type="check" />} />
                        </div>) : ''
                    }
                    <div style={{ clear: 'both' }}></div>
                </div> */}

            </div>
        )
    }
}

export default Title
import React, { Component } from 'react';
import { Layout, Form, Button, Switch, Checkbox, Modal, message } from 'antd'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../../Intel';
import { getConfigs, setConfigs } from '../../../services/system';
class EditForm extends Component {
    constructor() {
        super(...arguments)
        this.state = {
            initData: {}
        }
    }
    componentDidMount() {
        this.getInitData();
    }
    getInitData = () => {
        getConfigs().then(res => {
            let data = {
                ATTRIBUTE_DETECT: res.ATTRIBUTE_DETECT,
                AGE: res.AGE,
                GENDER: res.GENDER,
                GLASS: res.GLASS,
                MASK: res.MASK,
            }
            this.setState({
                initData: data
            })
        })
    }
    refreshPage = () => {
        sessionStorage.clear(); window.location.href = '/';
    }
    handleReset = () => {
        this.props.form.resetFields();
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if (this.state.initData.ATTRIBUTE_DETECT !== values.ATTRIBUTE_DETECT) {
                        Modal.confirm({
                            title: intl.formatMessage({ id: this.props.isDocker ? 'info.gate.restart' : 'info.restart' }),
                            content: intl.formatMessage({ id: this.props.isDocker ? 'info.gate.restart.content' : 'info.gate.restart.content' }),
                            onOk: () => {
                                this.setState({
                                    loading: true
                                })
                                setConfigs({ "config_dict": { "ATTRIBUTE_DETECT": values.ATTRIBUTE_DETECT } }).then(res => {
                                    this.refreshPage();
                                }).finally(() => {
                                    this.setState({
                                        loading: false
                                    })
                                })
                            }
                        })
                    } else {
                        message.warning(intl.formatMessage({ id: 'info.nochange.warning' }), 1);
                    }
                }
            }
        )
    }
    handleOpenCapture = (value) => {
        this.setState({
            isOpen: value
        })
        if (value) {
            let value = {};
            value['AGE'] = true;
            value['GENDER'] = true;
            value['GLASS'] = true;
            value['MASK'] = false;
            this.props.form.setFieldsValue(value)
        } else {
            let value = {};
            value['AGE'] = false;
            value['GENDER'] = false;
            value['GLASS'] = false;
            value['MASK'] = false;
            this.props.form.setFieldsValue(value)
        }
    }
    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.tab !== prevState.tab) {
            if (nextProps.tab === '3') {
                nextProps.form.resetFields();
            }
            return {
                tab: nextProps.tab
            }
        }
        //不需要更新状态，返回null
        return null
    }
    render() {
        let initData = this.state.initData;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form labelCol={{ span: 12 }} wrapperCol={{ span: 12 }} onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='setting.smart.attributes.form.applyMode' />}>
                    {getFieldDecorator('ATTRIBUTE_DETECT', {
                        initialValue: initData.ATTRIBUTE_DETECT,
                        valuePropName: 'checked',
                    })(
                        <Switch onChange={this.handleOpenCapture} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.attributes.form.age' />}>
                    {getFieldDecorator('AGE', {
                        initialValue: initData.AGE,
                        valuePropName: 'checked',
                    })(
                        <Checkbox disabled={true} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.attributes.form.gender' />}>
                    {getFieldDecorator('GENDER', {
                        initialValue: initData.GENDER,
                        valuePropName: 'checked',
                    })(
                        <Checkbox disabled={true} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.attributes.form.glasses' />}>
                    {getFieldDecorator('GLASS', {
                        initialValue: initData.GLASS,
                        valuePropName: 'checked',
                    })(
                        <Checkbox disabled={true} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.attributes.form.mask' />}>
                    {getFieldDecorator('MASK', {
                        initialValue: false,
                        valuePropName: 'checked',
                    })(
                        <Checkbox disabled={true} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.attributes.form.hat' />}>
                    <Checkbox disabled={true} />
                </Form.Item>
                <Form.Item label={<FormattedMessage id='setting.smart.attributes.form.Sunglasses' />}>
                    <Checkbox disabled={true} />
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                    <Button type="primary" loading={this.state.loading} htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                    <Button style={{ marginLeft: 8 }} onClick={this.handleReset}><FormattedMessage id='button.clear' /></Button>
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);
class FaceAttributes extends Component {
    render() {
        return (
            <div>
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 20 }}>
                    <WrapperedEditForm {...this.props} />
                </Layout>
            </div>
        )
    }
}

export default FaceAttributes
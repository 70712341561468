import React, { Component } from 'react'
import { Icon, Dropdown, Menu, Avatar } from 'antd'
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';


class Header extends Component {
    logout = () => {
        sessionStorage.clear();
        window.location.href = '/';
    }

    changeLang = ()=>{
        const lang = localStorage.getItem('lang')
        localStorage.setItem('lang',lang === 'zh'?'en':'zh');
        window.location.reload()
    }

    render() {
        const Dropdown_Menu = (
            <Menu style={{ textAlign: 'left' }}>
                <Menu.Item key={1}>
                    <Link to='/System/ChangePassword'>
                        <div style={{ height: 46, width: 141, lineHeight: '46px', color: 'RGBA(0, 0, 0, 0.85)' }}>
                            <Icon type='lock' />
                            <span style={{marginLeft:5}}><FormattedMessage id='header.password'/></span>
                        </div>
                    </Link>
                </Menu.Item>
                <Menu.Divider></Menu.Divider>
                <Menu.Item key={2}>
                    <div onClick={this.changeLang}>
                        <span className="cursor" style={{ height: 46, width: 141, lineHeight: '46px',color: 'RGBA(0, 0, 0, 0.85)' }} >
                            <Icon type='global' />
                            <span style={{marginLeft:5}}><FormattedMessage id='header.lang'/></span>
                        </span>
                    </div>
                </Menu.Item>
                <Menu.Divider></Menu.Divider>
                <Menu.Item key={3}>
                    <div onClick={this.logout}>
                        <span className="cursor" style={{ height: 46, width: 141, lineHeight: '46px',color: 'RGBA(0, 0, 0, 0.85)' }} >
                            <Icon type='export' />
                            <span style={{marginLeft:5}}><FormattedMessage id='header.logout'/></span>
                        </span>
                    </div>
                </Menu.Item>
            </Menu>
        )
        return (
            <div className='header' style={this.props.setup?{backgroundColor:'#262626'}:undefined}>
                <div style={{ lineHeight: '64px' }}>
                    <Icon style={{ fontSize: 20, marginLeft: 24,display:this.props.setup?'none':'' }}
                        type={this.props.collapsed ? 'menu-unfold' : 'menu-fold'}
                        onClick={this.props.toggleHandler}
                    />
                    <div className='user-panal'>
                        <Dropdown
                            overlay={Dropdown_Menu}>
                            <div style={{ height: '100%' }}>
                                <Avatar icon='user' size='small' />
                                <span style={{ marginLeft: 8,color:this.props.setup?'#FFFFFF':'' }}>{sessionStorage.getItem('session')}</span>
                            </div>
                        </Dropdown>
                    </div>
                </div>
            </div>
        )
    }
}

export default connect(()=>({}))(Header)
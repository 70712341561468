import api from '../utils/api';
const SOPHON_CONFIG = "/SophonConfig/api/Configs";
const SOPHON_SYSTEM = 'SophonSystem/api'
//获取某个配置
const getConfig = (config) => api({
        method: 'get',
        url: `${SOPHON_CONFIG}/${config}`
    }).then(res => res.data.data);

// 查询ip
const getIp = () => api({
    method: 'get',
    url: `${SOPHON_SYSTEM}/ip`
}).then(res => res.data.data);

const setIp = (data) => api({
    method: 'post', 
    data:data,
    url: `${SOPHON_SYSTEM}/ip`
}).then(res  => res.data)
// 设置ip

// 获取当前的主题
const getTheme = async() => {
    // let theme = localStorage.getItem('theme');
    // if (theme) {
    //     return theme;
    // } else {
    //     getConfig('THEME').then(res => {
    //         localStorage.setItem('theme', res.THEME);
    //         return res.THEME;
    //     })
    // }
    let theme = (await getConfig('THEME')).THEME;
    return theme;
}

// 获取所有的配置
const getConfigs = () => api({
    url:`${SOPHON_CONFIG}`,
    method: 'get'
}).then(res => res.data.data);

// 设置配置
const setConfigs = (data) => api({
    url:`${SOPHON_CONFIG}`,
    method: 'put',
    data
}).then(res => res.data);

// 设置某个配置

const setConfig = (config) => api({
    url:`${SOPHON_CONFIG}`,
    data:{ "config_dict": config },
    method: 'put',
})

//重启设置
const setRegularReboot = (data) => api({
    url:`${SOPHON_SYSTEM}/regularReboot`,
    data:data,
    method:'post'
}).then(res => res.data)
// 获取重启设置的当前参数
const getRegularReboot = () => api({
    url:`${SOPHON_SYSTEM}/regularReboot`,
    method:'get'
}).then(res => res.data.data)

// 获取时间模式
const getTimeSelectMode = () => api({
    url:`${SOPHON_SYSTEM}/timeSelectMode`,
    method: 'get'
}).then(res => res.data);

// 设置时间模式
const setTimeSelectMode = ({time_select_mode}) => api({
    url:`${SOPHON_SYSTEM}/timeSelectMode`,
    method: 'post',
    data:{time_select_mode}
}).then(res => res.data);

// 查询系统时间
const getTime = () => api({
    url:`${SOPHON_SYSTEM}/time`,
    method: 'get'
}).then(res => res.data);

// 设置系统时间
const setTime = ({time,timezone}) => api({
    url:`${SOPHON_SYSTEM}/time`,
    method: 'post',
    data:{time,timezone}
}).then(res => res.data);

// 查询ntp服务
const getNtpConfig = () => api({
    url:`${SOPHON_SYSTEM}/ntpConfig`,
    method: 'get'
}).then(res => res.data);

// 设置ntp服务
const setNtpConfig = ({ntp_config,timezone}) => api({
    url:`${SOPHON_SYSTEM}/ntpConfig`,
    method: 'post',
    data:{ntp_config,timezone}
}).then(res => res.data);

// 获取软件系统模式
const getSystemMode = () => api({
    url:`${SOPHON_SYSTEM}/systemMode`,
    method: 'get'
}).then(res => res.data);

// 获取SE5模块状态
const getLteModem = () =>api({
    url:`${SOPHON_SYSTEM}/lteModem`,
    method: 'get'
}).then(res => res.data);
const setLteModem = ({service}) =>api({
    url:`${SOPHON_SYSTEM}/lteModem`,
    data:{service},
    method: 'post'
}).then(res => res.data);
// 获取SE5 4G 模块网络选择情况，lte 优先，还是本地网口优先
const getLteModemSelection = () =>api({
    url:`${SOPHON_SYSTEM}/lteModemSelection`,
    method: 'get'
}).then(res => res.data);
const setLteModemSelection = ({selection}) =>api({
    url:`${SOPHON_SYSTEM}/lteModemSelection`,
    data:{selection},
    method: 'post'
}).then(res => res.data);

const getLteModemConnection = () =>api({
    url:`${SOPHON_SYSTEM}/lteModemConnection`,
    method: 'get'
}).then(res => res.data);

// const getLteModemSimStatus = () =>api({
//     url:`${SOPHON_SYSTEM}/lteModemSimStatus`,
//     method: 'get'
// }).then(res => res.data);

export {
    getTheme,
    getConfigs,
    getConfig,
    setConfigs,
    setConfig,
    getRegularReboot,
    setRegularReboot,
    getIp,
    setIp,
    getTimeSelectMode,
    setTimeSelectMode,
    getTime,
    setTime,
    getNtpConfig,
    setNtpConfig,
    getSystemMode,
    getLteModem,
    setLteModem,
    getLteModemSelection,
    setLteModemSelection,
    getLteModemConnection,
    // getLteModemSimStatus
}
import React, { Component } from 'react'
import { Select } from 'antd'
import axios from '../../utils/AxiosWrapper'
import { COMMON_PREFIX } from '../../utils/Config';
class GroupInput extends Component{
    constructor(props){
        super(props);
        this.mode = props.mode || 'tags';
        this.state = {
            groups:[]
        }
    }

    componentDidMount(){
        axios.get(`${COMMON_PREFIX}/groups`).then(
            (resp)=>{
                this.setState({
                    groups:resp.data.data
                })
            }
        )
    }

    componentDidUpdate(){
        // 将人员底库全部默认选择,当前项目人员底库为默认
        const defautl_group = this.state.groups.find(e=>e.info.name==='default_group')
        if( defautl_group && defautl_group._id!== this.props.value){
            this.handleChange([defautl_group._id])
        }
        
    }

    handleChange = (val)=>{
        if(this.mode === 'multiple'){
            this.triggerChange(val);
        }
        if(this.mode === 'tags'){
            this.triggerChange(val.pop())
        }
        
    }

    handleSelect = ()=>{
        if (this.mode && this.mode === 'tags')
            this.SelectRef.blur();
    }
    
    triggerChange = (changedValue) =>{
        const onChange = this.props.onChange;
        if(onChange){
            onChange(changedValue)
        }
    }
    
    render(){
        const { mode } = this.props;
        return (
            <Select
             ref={elem=>this.SelectRef=elem}
             mode={ mode || 'tags' }
             value={[this.props.value]} 
             onChange={this.handleChange}
             onSelect={this.handleSelect}
             disabled={this.props.disabled || true}
            >
                {this.state.groups.map(
                    (group) => <Select.Option key={group._id}>{group.info.name}</Select.Option>
                )}
            </Select>
        )
    }
    
    
}
//export default GroupInput
export default GroupInput
import React, { Component } from 'react';
import { Layout, Form, Button, Input,message } from 'antd'
import axios from '../../utils/AxiosWrapper'
import { SOPHON_SYSTEM, HOSTNAME_PATTERN } from '../../utils/Config'
// import Title from '../common/Title'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';

class EditForm extends Component {
    state = {
        initHostname: ''
    }
    componentDidMount() {
        axios.get(`${SOPHON_SYSTEM}/hostname`).then(
            (resp) => {
                this.setState({
                    initHostname: resp.data.data.hostname
                });
            }
        )
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    axios.post(`${SOPHON_SYSTEM}/hostname`, { hostname: values.hostname }).then(
                        (resp) => {
                            message.success(intl.formatMessage({ id: 'info.success' }), 1);
                        }
                    )
                }
            }
        )
    }
    render() {

        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='sidemenu.System.Hostname'/>} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                    {getFieldDecorator('hostname', {
                        rules: [
                            { pattern: HOSTNAME_PATTERN, message: <FormattedMessage id='hostname.invalid'/> },
                            { required: true, message: <FormattedMessage id='input.required'/> },
                            // { max: 30 },
                            { whitespace: true }
                        ],
                        initialValue: this.state.initHostname
                    })(
                        <Input style={{width:240}} maxLength={30}></Input>
                    )}
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>

                    <Button type="primary" htmlType="submit"><FormattedMessage id='button.apply'/></Button>
                    {/* <Button style={{ marginLeft: 8 }} onClick={() => { this.props.history.goBack() }}>Back</Button> */}
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);
// const breadcrumbSetting = [{
//     path: '/',
//     breadcrumbName: <FormattedMessage id='home'/>
// }, {
//     path: '/System/Basic',
//     breadcrumbName: <FormattedMessage id='sidemenu.System'/>
// }, {
//     breadcrumbName: <FormattedMessage id='sidemenu.System.Hostname'/>
// }];
class HostName extends Component {
    render() {
        return (
            <div>
                {/* <Title items={breadcrumbSetting} /> */}
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 100 }}>
                    <WrapperedEditForm {...this.props} />
                </Layout>
            </div>
        )
    }
}

export default HostName
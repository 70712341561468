export default {
    "home":"Home",
    "sidemenu.Info":"Information",
    "sidemenu.Info.Basic":"Basic information",

    "sidemenu.Config":"Device Configuration",
    "sidemenu.Config.IPCStream":"Device preview",
    "sidemenu.Config.IPCStreamAddList":"Device Management",

    "sidemenu.User":"User",
    "sidemenu.User.UserList":"User list",
    "sidemenu.User.PermissionGroup":"Permisson Group",
    "sidemenu.User.Edit":"Edit User",
    "sidemenu.User.UserGroup":"User Group",
    "sidemenu.User.UserGroup.Add":"Add User Group",
    "sidemenu.Event":"Event",
    "sidemenu.Event.History":"History",
    "sidemenu.Event.Image":"Search by Image",
    "sidemenu.Event.Face":"1:1 Comparison",
    "sidemenu.Event.Base":"Basement comparison",
    "sidemenu.System":"System",
    "sidemenu.System.NetWorkSetting":"NetWork Setting",
    "sidemenu.System.Basic":"Basic Information",
    "sidemenu.System.SystemSetting":"System Setting",
    "sidemenu.System.SmartSetting":"Smart setting",
    "sidemenu.System.DebugSetting":"System debugging",
    "sidemenu.System.WorkMode":"WorkMode",
    "sidemenu.System.Threshold":"Identification setting",
    "sidemenu.System.EventServerSetting":"Event Server Setting",
    "sidemenu.System.MonitorServerSetting":"Monitor Server Setting",
    "sidemenu.System.Update":"System upgrade",
    "sidemenu.System.Application":"Application settings",
    "sidemenu.System.TimeSetting":"Time setting",
    "sidemenu.System.ChangePassword":"Password change",
    "sidemenu.System.Hostname":"Host name change",
    "sidemenu.System.ClientTheme":"Theme configuration",
    "sidemenu.System.RebootSetting":"Restart settings",
    "sidemenu.System.PurposeSetting":"Purpose setting",
    "sidemenu.System.BoxUsage":"Usage settings",
}
import api from '../utils/api';
const matchApi = "/SophonMatch/api";

// 1:1人脸比对
const faceCompare = ({card_image_base64,face_image_base64}) => api({
    method: 'post',
    data:{card_image_base64,face_image_base64,fiqa_level:'no',card_fiqa_level:'no',"need_save":true},
    url: `${matchApi}/compare `
}).then(res => res.data)

// 底库比对
const pictureMatch = ({rgb_image_base64}) => api({
    method: 'post',
    data:{rgb_image_base64,"group_name":"default_group","support_stranger":false,"need_register_photo":true,"need_keep_info":true},
    url: `${matchApi}/PictureMatch`
}).then(res => res.data)

export {
    faceCompare,
    pictureMatch
}
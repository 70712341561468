export default {
    "config.ipc.play":"Play",
    "config.ipc.pause":"Pause",
    "config.ipc.suspend":"Paused",
    "config.ipc.fullScreen":"Full screen",
    "config.ipc.load":"Loading",
    "config.ipc.noVideo":"No video",
    "config.ipc.dataList":"Datasheets",
    "config.ipc.table.matchPhoto":"Match photo",
    "config.ipc.table.livePhoto":"Live photo",
    "config.ipc.table.similarity":"Similarity",
    "config.ipc.table.time":"Time",
    "config.ipc.table.room":"Room No",
    "config.ipc.table.userType":"User type",
    "config.detail.device.status":"Status",
    "config.detail.device.online":"Online",
    "config.detail.device.offline":"Offline",
    "config.video.preview.pageNext": "No more videos on next page",
    "config.video.preview.pagePre": "No more videos on previous page",

    "config.video.face.attr.age.child" : 'Child',
    "config.video.face.attr.age.juvenile" : 'Juvenile',
    "config.video.face.attr.age.youth" : 'Youth',
    "config.video.face.attr.age.middle-aged" : 'Middle-aged',
    "config.video.face.attr.age.aged" : 'Aged',
    "config.video.face.attr.gender.male" : 'Male',
    "config.video.face.attr.gender.female" : 'Female',
    "config.video.face.attr.glasses.yes" : 'Yes',
    "config.video.face.attr.glasses.no" : 'No',

    "config.add.form.device_name": "Name",
    "config.add.form.device_key": "Url",
    "config.add.form.group": "Group",
    "config.add.form.serial_number": "Serial number",
    "config.add.form.protocol": "Protocol",
    "config.add.form.permission": "Permission",
    "config.add.form.device_attr": "Module enable",
    "config.add.form.fast_mode": "Recognition mode",
    "config.add.form.netRelay": "Relay configuration",
    "config.add.form.moreConfig": "Advanced configuration",
    "config.add.form.netRelay.ip": "Ip",
    "config.add.form.netRelay.door_port": "Port",
    "config.add.form.netRelay.delay_time": "Action reset delay",

    "config.detail.groupID":"Group ID: {groupID}",
    "config.detail.title":"{title} ",
    "config.detail.ip":"IP: {IP}",
    "config.detail.type":"type: {type}",
    "config.detail.detailButton":"Details",
    "config.detail.deleteButton":"Delete",
    "config.addButton":"Add",
    "config.addButtonManully":"Add manully",
    "config.add.refresh":"Refresh",
    "config.add.form.url.error.patter":'The format of the input is incorrect',
    "config.detail.deleteTips":"Sure to delete this device？",
    "config.detail.deleteContent":"You will not receive face image from this design.",
    "config.detail.deleteError":"Delete failed. message: {message}",
    "config.add.confirm":"Sure to add the device",
    "config.add.table.title.gate":"Scanned Gate Ip",
    "config.add.form.gate.name":"Name",
    "config.add.form.gate.groupID":"Device ID",
    "config.add.form.gate.ip":"IP",
    "config.add.form.gate.ipAdded":"This IP has been added",
    "config.add.form.gate.type":"Type",
    "config.add.form.Apply":"Apply",
    "config.add.form.back":"Back",
    "config.add.form.capture.title":"Scanned CaptureCamera IP",
    "config.add.captureCamera.tips":"Confirm to add CaptureCamera into white list",
    "config.add.captureCamera.content":"You need connect this AI box from the campture camera side",
    "config.detail.ipc.netGate":"Net gate",
    "config.detail.ipc.netPort":"Net port",
    "config.detail.ipc.mode":"Mode",
    "config.detail.ipc.netPort.value1":"Port1",
    "config.detail.ipc.netPort.value2":"Port2",
    "config.detail.ipc.sleeptime":"Sleeptime",
    "config.detail.ipc.rtsp":"Rtsp source",
    "config.detail.ipc.singalFace":"Singal Face",
    "config.detail.ipc.mutipleFace":"Mutiple Face",
    "config.add.form.fast_mode.Speed":"Speed first",
    "config.add.form.fast_mode.Accuracy":"Accuracy first",
    "config.add.form.fast_mode.mode":"Mode",
    "config.detail.ipc.actions":"Actions",
    "config.detail.ipc.actions.detect":"Do detect",
    "config.detail.ipc.actions.feature":"Do feature",
    "config.detail.ipc.actions.match":"Do match",
    "config.detail.ipc.actions.track":"Do track",
    "config.detail.ipc.actions.attribute":"Do attribute",
    "config.detail.ipc.stream.displayBox":"Display box",
    "config.detail.ipc.stream.box.unknown":"Unknown",
    "config.detail.ipc.stream.box.gender":"Gender",
    "config.detail.ipc.stream.box.glasses":"Glasses",
    "config.detail.ipc.stream.box.mask":"Mask",
    "config.detail.ipc.stream.box.hat":"Hat",
    "config.detail.ipc.stream.box.age":"Age",
    "config.detail.ipc.stream.box.expression":"Expression",
    "config.detail.ipc.stream.box.camera":"{name}",
    "config.detail.ipc.stream.box.IN":"IN",
    "config.detail.ipc.stream.box.OUT":"OUT",
    "config.detail.ipc.stream.box.Recognized":"Recognized",
    "config.add.form.gate.type.manualenter.error":"Manually entered gate type must be a number",
    "config.detail.ipc.stream.name":"Camera name",

    "config.add.form.rtmp.title": "RTMP streaming",
    "config.add.form.rtmp.device_key": "Url",
    "config.add.form.rtmp.title.tips": "(On trial)",
    "config.add.form.rtmp.start": "Start-up",

    "config.add.form.gb28181.addressRule": "Address rule",
    "config.add.form.gb28181.rule.udp1": "Udp real time stream address",
    "config.add.form.gb28181.rule.udp2": "Udp playback Stream Address",
    "config.add.form.gb28181.rule.tcp1": "Tcp real time stream address",
    "config.add.form.gb28181.rule.tcp2": "Tcp playback Stream Address",

    "config.add.form.gb28181.rule.deviceid": "20 bit coding of front equipment",
    "config.add.form.gb28181.rule.devicetype": "Video storage type",
    "config.add.form.gb28181.rule.localid": "Local 20 bit encoding, optional",
    "config.add.form.gb28181.rule.localip": "Local IP, unnecessary configuration. If it is a docker deployment, please set it to the internal IP address of the docker",
    "config.add.form.gb28181.rule.localsipport": "Sip port number",
    "config.add.form.gb28181.rule.localmediaport": "Rtsp port number",
    "config.add.form.gb28181.rule.begtime": "Video start time",
    "config.add.form.gb28181.rule.endtime": "Video end time"  
}
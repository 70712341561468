import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { createStore,applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import reducers from './redux/reducers/reducer'
import thunk from 'redux-thunk'
import {setOEM,setSystemMode} from './redux/actions/action';
import Intel from './Intel'
import { loadStatic } from './utils/OEMPTAH';

let store = createStore(reducers,applyMiddleware(thunk))
// store.dispatch(setClientIpState())
// store.dispatch(setClientDeviceType(true))
// store.dispatch(setClientWorkMode(true))

store.dispatch(setSystemMode())
store.dispatch(setOEM())
loadStatic('ICONLOGO').then((resp)=>{
    const icon = document.querySelector('link[rel*="icon"]');
    if(icon){
        icon.href = resp;
    }
})

ReactDOM.render(
    <Provider store={store}>
    <Intel>
        <App />
    </Intel>
    </Provider>
    , document.getElementById('root'));
//registerServiceWorker();
import React, { Component } from 'react';
import { Layout, Button, Modal } from 'antd';
// import axios from '../../utils/AxiosWrapper'
import { intl } from '../../Intel';
import { FormattedMessage } from 'react-intl';
// import { saveAs } from 'file-saver';

class DebugSetting extends Component {
    state = {
        visible: false,
        loading: false
    }
    confirm = (type) => {
        Modal.confirm({
            title: intl.formatMessage({ id: 'setting.system.log.get.sure' }),
            content: intl.formatMessage({ id: 'setting.system.log.get.tips.content' }),
            okText: intl.formatMessage({ id: 'button.apply' }),
            cancelText: intl.formatMessage({ id: 'button.cancel' }),
            confirmLoading: this.state.loadings,
            loading: this.state.loading,
            onOk: () => {
                this.setState({
                    loading: true
                })
                if (type === 'system') {
                    window.open('/SophonSystem/api/logs?get_logs_type=0');
                    // window.open('http://172.16.29.11:11480/SophonSystem/api/logs?get_logs_type=0');
                    // return axios.get('/SophonSystem/api/logs?get_logs_type=0',{timeout: 1000*60*60}).then(res => {
                    //     var fileName = res.headers["content-disposition"].split("=");
                    //     fileName = fileName[fileName.length - 1];
                    //     fileName = fileName.replace(/"/g, "");
                    //     let blob = new Blob([res.data],{
                    //         type:"application/x-compressed;charset=UTF-8"});
                    //   saveAs(blob, fileName );
                    //     this.setState({
                    //         loading: false
                    //     })
                    // }).catch(() => {
                    //     this.setState({
                    //         loading: false
                    //     })
                    // })
                } else {
                    //下载人员日志
                    window.open('/SophonSystem/api/logs?get_logs_type=1');
                    // return axios.get('/SophonSystem/api/logs?get_logs_type=1').then(res => {
                    //     var blob = new Blob([res.data], { type: "application/zip;charset=UTF-8" });
                    //     var fileName = res.headers["content-disposition"].split("=");
                    //     fileName = fileName[fileName.length - 1];
                    //     fileName = fileName.replace(/"/g, "");
                    //     saveAs(blob, fileName);
                    //     this.setState({
                    //         loading: false
                    //     })
                    // }).catch(() => {
                    //     this.setState({
                    //         loading: false
                    //     })
                    // })



                    // window.open('/SophonSystem/api/logs?get_logs_type=1');
                    // this.setState({
                    //     loading:false  
                    //   })
                    // return axios.get('/SophonSystem/api/logs?get_logs_type=1').then(res => {
                    //     var blob = new Blob([res.data], { type: "application/zip;charset=UTF-8" });
                    //     // FileReader主要用于将文件内容读入内存
                    //     var reader = new FileReader();
                    //     reader.readAsDataURL(blob);
                    //     // onload当读取操作成功完成时调用
                    //     let _self = this;
                    //     reader.onload = function (e) {
                    //         var a = document.createElement('a');
                    //         // 获取文件名fileName
                    //         var fileName = res.headers["content-disposition"].split("=");
                    //         fileName = fileName[fileName.length - 1];
                    //         fileName = fileName.replace(/"/g, "");
                    //         a.download = fileName;
                    //         _self.setState({
                    //             loading: false
                    //         })
                    //         a.href = e.target.result;
                    //         document.body.appendChild(a);
                    //         a.click();
                    //         document.body.removeChild(a);
                    //     }
                    // }).catch(() => {
                    //     this.setState({
                    //         loading: false
                    //     })
                    // })

                }
            }
        });
    }
    render() {
        const host = window.location.hostname;
        return (
            <div>
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px' }}>
                    <div style={{ margin: '0 auto', width: '80vw' }}>
                        <h3> <FormattedMessage id='setting.system.log.get.collect' /><span className="tips-text">(<FormattedMessage id='setting.system.log.get.tips.content' />)</span></h3>
                        <div>
                            {/* <a href ="http://huangzhenming.xyz:64801/SophonSystem/api/logs?get_logs_type=1">下载</a> */}
                            <Button style={{ marginRight: 30 }} onClick={() => this.confirm('system')} icon="download" size='large' type="primary"><FormattedMessage id='setting.system.log.get.system' /></Button>
                            <Button size='large' icon="download" onClick={() => this.confirm('user')} type="primary"><FormattedMessage id='setting.system.log.get.user' /></Button>
                        </div>
                        <h3 style={{ marginTop: 30 }}><FormattedMessage id='setting.system.tytd.title' /></h3>
                        <iframe title="ttyd" style={{ width: "80vw", height: '65vh' }} height="600px" src={`http://${host}:7681/`}></iframe>
                    </div>
                </Layout>
            </div>
        )
    }
}

export default DebugSetting
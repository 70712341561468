import React, { Component } from 'react';
import { Layout, Form, Button, TimePicker,Input, Select, message, Modal, Checkbox, Switch } from 'antd'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../../Intel';
import { getConfigs, setConfigs, getConfig } from '../../../services/system';
import moment from 'moment'
const format = 'HH:mm:ss';
const Option = Select.Option;


class EditForm extends Component {
    constructor() {
        super(...arguments)
        this.state = {
            isOpen: false,
            initIsopen: false,
            initData: {
                DUPLICATE_REMOVAL_TIMEOUT: 0
            },
            isDisabled: false
        }
    }

    componentDidMount() {
        this.getDataInit();
        getConfig('WORK_MODE').then(res => {
            if (res.WORK_MODE === 'mq') {
                this.setState({
                    isDisabled: true
                })
            }
        })
    }
    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.tab !== prevState.tab) {
            if (nextProps.tab === 'recognize') {
                nextProps.form.resetFields();
            }
            return {
                isOpen: prevState.initIsopen,
                tab: nextProps.tab
            }
        }
        //不需要更新状态，返回null
        return null
    }
    handleReset = () => {
        this.props.form.resetFields();
    }
    getDataInit = () => {
        getConfigs().then(res => {
            let data = {
                APPLY_MODE: res.APPLY_MODE,
                SUPPORT_STRANGER_GROUP: res.SUPPORT_STRANGER_GROUP,
                REG_MIN_FACE: res.REG_MIN_FACE,
                REG_TIME_PART1: res.REG_TIME_PART1,
                REG_TIME_PART2: res.REG_TIME_PART2,
                THRESHOLD: res.THRESHOLD,
                DUPLICATE_REMOVAL_TIMEOUT: res.DUPLICATE_REMOVAL_TIMEOUT,
                DEFAULT_GROUP_SIZE: res.DEFAULT_GROUP_SIZE,
                STRANGER_GROUP_SIZE: res.STRANGER_GROUP_SIZE
            }
            if (res.APPLY_MODE === 'recognize') {
                data.APPLY_MODE = true;
                this.setState({
                    isOpen: true,
                    initIsopen: true
                })
            } else {
                this.setState({
                    isOpen: false,
                    initIsopen: false
                })
                data.APPLY_MODE = false;
            }
            data.REG_TIME_PART1_SWITCH = res.REG_TIME_PART1_SWITCH;
            data.REG_TIME_PART2_SWITCH = res.REG_TIME_PART2_SWITCH;
            data.REG_TIME_PART1_startTime = res.REG_TIME_PART1.split(',')[0];
            data.REG_TIME_PART1_endTime = res.REG_TIME_PART1.split(',')[1];
            data.REG_TIME_PART2_startTime = res.REG_TIME_PART2.split(',')[0];
            data.REG_TIME_PART2_endTime = res.REG_TIME_PART2.split(',')[1];
            this.setState({
                initData: data
            })
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if (!values.REG_TIME_PART1_startTime || !values.REG_TIME_PART2_startTime || !values.REG_TIME_PART1_endTime || !values.REG_TIME_PART2_endTime) {
                        message.error(intl.formatMessage({ id: 'setting.smart.recognition.form.time.require' }), 1.5);
                        return false;
                    }
                    if (new Date(values.REG_TIME_PART1_startTime) - new Date(values.REG_TIME_PART1_endTime) > 0 || new Date(values.REG_TIME_PART2_startTime) - new Date(values.REG_TIME_PART2_endTime) > 0) {
                        message.error(intl.formatMessage({ id: 'user.add.startTime.error' }), 1.5);
                        return false;
                    }
                    let needChange = {};
                    let REG_TIME_PART1 = `${moment(values.REG_TIME_PART1_startTime).format(format)},${moment(values.REG_TIME_PART1_endTime).format(format)}`;
                    values.REG_TIME_PART1 = REG_TIME_PART1;
                    let REG_TIME_PART2 = `${moment(values.REG_TIME_PART2_startTime).format(format)},${moment(values.REG_TIME_PART2_endTime).format(format)}`
                    values.REG_TIME_PART2 = REG_TIME_PART2;
                    let initData = this.state.initData;
                    let isChangeAPPLY_MODE = values.APPLY_MODE !== initData.APPLY_MODE;
                    if (values.APPLY_MODE) {
                        delete values.APPLY_MODE;
                        values.APPLY_MODE = 'recognize';
                    } else {
                        delete values.APPLY_MODE;
                        values.APPLY_MODE = 'grab';
                    }
                    if(values.DUPLICATE_REMOVAL_TIMEOUT){
                        values.DUPLICATE_REMOVAL_TIMEOUT =  parseInt(values.DUPLICATE_REMOVAL_TIMEOUT);
                    }
                    if(values.THRESHOLD){
                        values.THRESHOLD = parseFloat(values.THRESHOLD);
                    }
                    if(values.REG_MIN_FACE){
                        values.REG_MIN_FACE = parseInt(values.REG_MIN_FACE);
                    }
                    let restartField = ["REG_TIME_PART1", "REG_TIME_PART2", "REG_MIN_FACE", "REG_TIME_PART1_SWITCH", "REG_TIME_PART2_SWITCH", "DEFAULT_GROUP_SIZE", "STRANGER_GROUP_SIZE"];
                    let commonField = ["DUPLICATE_REMOVAL_TIMEOUT", "THRESHOLD", "SUPPORT_STRANGER_GROUP"];
                    let changeRestartField = restartField.filter(item => {
                        if (initData[item] !== values[item]) {
                            needChange[item] = values[item];
                            return true;
                        } else {
                            return false;
                        }
                    });
                    let changeCommonField = commonField.filter(item => {
                        if (initData[item] !== values[item]) {
                            needChange[item] = values[item];
                            return true;
                        } else {
                            return false;
                        }
                    });
                    if (changeRestartField.length || isChangeAPPLY_MODE) {//重启字段被修改
                        if (isChangeAPPLY_MODE) {
                            needChange.APPLY_MODE = values.APPLY_MODE;
                        }
                        Modal.confirm({
                            title: intl.formatMessage({ id: this.props.isDocker ? 'info.gate.restart' : 'info.restart' }),
                            content: intl.formatMessage({ id: this.props.isDocker ? 'info.gate.restart.content' : 'info.gate.restart.content' }),
                            onOk: () => {
                                this.setState({
                                    loading: true
                                })
                                setConfigs({ "config_dict": needChange }).then(res => {
                                    this.refreshPage();
                                }).finally(() => {
                                    this.setState({
                                        loading: false
                                    })
                                })
                            }
                        })
                    } else if (changeCommonField.length) {
                        this.setState({
                            loading: true
                        })
                        setConfigs({ "config_dict": needChange }).then(res => {
                            message.success(intl.formatMessage({ id: 'info.success' }), 1);
                            this.getDataInit();
                        }).finally(() => {
                            this.setState({
                                loading: false
                            })
                        })
                    } else {
                        message.warning(intl.formatMessage({ id: 'info.nochange.warning' }), 1);
                    }
                }
            }
        )
    }

    refreshPage = () => {
        sessionStorage.clear(); window.location.href = '/';
    }
    handleOpenCapture = (value) => {
        this.setState({
            isOpen: value
        })
        if (value) {
            this.props.activeFaceValue('recognize');
        } else {
            this.props.form.resetFields();
            this.props.activeFaceValue('grab');
        }
    }
    render() {
        let disabled = !this.state.isOpen;
        let initData = this.state.initData;
        const { getFieldDecorator } = this.props.form;
        return (
            <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='setting.smart.recognition.form.applyMode' />}>
                    {getFieldDecorator('APPLY_MODE', {
                        initialValue: initData.APPLY_MODE,
                        valuePropName: 'checked',
                    })(
                        <Switch onChange={this.handleOpenCapture} />
                    )}
                </Form.Item>

                {/* 人脸底库存容量 */}
                <Form.Item help={<FormattedMessage id='setting.smart.recognition.formItem.reboot.tips' />} style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.recognition.form.time.baseUser' />}>
                    {getFieldDecorator('DEFAULT_GROUP_SIZE', {
                        initialValue: initData.DEFAULT_GROUP_SIZE || 10000,
                    })(
                        <Select disabled={disabled} style={{ width: 160 }}>
                            <Option value={10000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '1' }} /></Option>
                            <Option value={100000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '10' }} /></Option>
                            <Option value={200000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '20' }} /></Option>
                            <Option value={300000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '30' }} /></Option>
                            <Option value={400000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '40' }} /></Option>
                            <Option value={500000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '50' }} /></Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item help={<FormattedMessage id='setting.smart.recognition.formItem.reboot.tips' />} style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.recognition.form.time.baseStringer' />}>
                    {getFieldDecorator('STRANGER_GROUP_SIZE', {
                        initialValue: initData.STRANGER_GROUP_SIZE || 30000,
                    })(
                        <Select disabled={disabled} style={{ width: 160 }}>
                            <Option value={30000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '3' }} /></Option>
                            <Option value={50000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '5' }} /></Option>
                            <Option value={100000}><FormattedMessage id="setting.smart.recognition.form.time.thousand" values={{ value: '10' }} /></Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.recognition.form.IsSuportStranger' />}>
                    {getFieldDecorator('SUPPORT_STRANGER_GROUP', {
                        initialValue: initData.SUPPORT_STRANGER_GROUP ? true : false,
                        valuePropName: 'checked',
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="select.required" />,
                            },
                        ],
                    })(
                        <Checkbox disabled={this.state.isDisabled ? true : disabled} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.recognition.form.regMinFace' />}>
                    {getFieldDecorator('REG_MIN_FACE', {
                        initialValue: initData.REG_MIN_FACE,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                            { pattern: /^(\b[2-9]\d\b|\b[1-2]\d\d\b|\b300\b)$/, message: <FormattedMessage id='setting.smart.recognition.form.regMinFace.invalid' /> },
                        ],
                    })(
                        <Input disabled={disabled}  style={{ width: 160 }}/>
                    )}
                    <span> (20 ~ 300)</span>

                </Form.Item>
                <Form.Item label={<FormattedMessage values={{ value: '1' }} id='setting.smart.recognition.form.regTimePart' />} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('REG_TIME_PART1_SWITCH', {
                        initialValue: initData.REG_TIME_PART1_SWITCH ? true : false,
                        valuePropName: 'checked',
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                        ],
                    })(
                        <Checkbox disabled={disabled} style={{ marginRight: 10 }}></Checkbox>
                    )}
                    {getFieldDecorator('REG_TIME_PART1_startTime', {
                        initialValue: moment(initData.REG_TIME_PART1_startTime, format)
                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}
                    {'~'}
                    {getFieldDecorator('REG_TIME_PART1_endTime', {
                        initialValue: moment(initData.REG_TIME_PART1_endTime, format)
                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}

                </Form.Item>
                <Form.Item label={<FormattedMessage values={{ value: '2' }} id='setting.smart.recognition.form.regTimePart' />} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('REG_TIME_PART2_SWITCH', {
                        initialValue: initData.REG_TIME_PART2_SWITCH ? true : false,
                        valuePropName: 'checked',
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                        ],
                    })(
                        <Checkbox disabled={disabled} style={{ marginRight: 10 }}></Checkbox>
                    )}
                    {getFieldDecorator('REG_TIME_PART2_startTime', {
                        initialValue: moment(initData.REG_TIME_PART2_startTime, format)
                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}
                    {'~'}
                    {getFieldDecorator('REG_TIME_PART2_endTime', {
                        initialValue: moment(initData.REG_TIME_PART2_endTime, format)
                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.recognition.form.threshold' />}>
                    {getFieldDecorator('THRESHOLD', {
                        initialValue: initData.THRESHOLD,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                            { pattern: /^([0-9]\d?|100)$/, message: <FormattedMessage id='settings.threshold.invalid' /> },
                        ],
                    })(
                        <Input disabled={disabled} style={{ width: 160 }}/>
                    )}
                    <span> (0 ~ 100)</span>

                </Form.Item>
                <Form.Item label={<FormattedMessage id='setting.smart.recognition.form.duplicateTime' />}>
                    {getFieldDecorator('DUPLICATE_REMOVAL_TIMEOUT', {
                        initialValue: parseInt(initData.DUPLICATE_REMOVAL_TIMEOUT, 10),
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                            { pattern: /^([1-5]\d?|60|[1-9])$/, message: <FormattedMessage id='settings.duplicateTime.invalid' /> },
                        ],
                    })(
                        <Input disabled={disabled}  style={{ width: 160 }}/>
                    )}
                    <span> (1 ~ 60)</span>

                </Form.Item>
                <Form.Item disabled={disabled} style={{ textAlign: 'center' }} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                    <Button type="primary" loading={this.state.loading} htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                    <Button style={{ marginLeft: 8 }} onClick={this.handleReset}><FormattedMessage id='button.clear' /></Button>
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);
class FaceRecognition extends Component {
    render() {
        return (
            <div>
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 20 }}>
                    <WrapperedEditForm {...this.props} />
                </Layout>
            </div>
        )
    }
}

export default FaceRecognition

function createWorker(f) {
    var blob = new Blob(['(' + f.toString() + ')()']);
    var url = window.URL.createObjectURL(blob);
    var worker = new Worker(url);
    return worker;
}
function workerFun(e) {
    var wsMethod = (function () {
        var ws = null;
        let isWebClose = false;
        function connect(wsurl) {
            isWebClose = false;
            ws = new WebSocket(wsurl);
        }
        function onOpen() {
            postMessage({ code: "open" });
        }
        function onClose() {
            if(!isWebClose){
                postMessage({ code: "reInit" }); //重连 
            }
        }
        function onMessaage(event) {
            // debugger;
            if(event.data instanceof Blob){
                let reader = new FileReader();
                reader.readAsText(event.data, 'utf-8');
                reader.onload = () => {
                    postMessage({ code: "message", data: JSON.parse(reader.result) });
                }
            }else{
                postMessage({ code: "message", data: JSON.parse(event.data)});
            }
        }
        function onError() {
            ws = null;
            postMessage({ code: "error" });
        }
        function sendMessage(msg) {
            if (ws == null) return;
            ws.send(msg);
        }
        function closeWs() {
            isWebClose = true;
            ws.close();
            postMessage({ code:'close' });
        }
        function initMessageEvent(url) {
            ws.onopen = onOpen;
            ws.onclose = onClose;
            ws.onmessage = onMessaage;
            ws.onerror = onError;
        }
        return {
            initWsConnect: function (url) {
                connect(url);
                initMessageEvent(url);
            },
            sendMessage: sendMessage,
            closeWs: closeWs
        }
    })();
    this.addEventListener('message', function (event) {
        if (event.data.code) {
            var code = event.data.code.toLowerCase();
            switch (code) {
                case "init":
                    wsMethod.initWsConnect(event.data.data);
                    break;
                case "close":
                    wsMethod.closeWs();
                    break;
                case "sendmsg":
                    wsMethod.sendMessage(JSON.stringify(event.data.data));
                    break;
                default:
                    break;
            }
        }
    }, false);
}
// function videoWsConfig(){
//     var wsMethod = (function () {
//         var ws = null;
//         function connect(wsurl) {
//             ws = new WebSocket(wsurl);
//         }
//         function onOpen() {
//             postMessage({ code: "open" });
//         }
//         function onClose() {
//             postMessage({ code: "close" });
//         }
//         function onMessaage(event) {
//             postMessage({ code: "message", data: JSON.parse(event.data)});
//         }
//         function onError() {
//             ws = null;
//             postMessage({ code: "error" });
//         }
//         function sendMessage(msg) {
//             if (ws == null) return;
//             ws.send(msg);
//         }
//         function closeWs() {
           
//             ws.close();
//             postMessage({ code:'close' })
//         }
//         function initMessageEvent() {
//             ws.onopen = onOpen;
//             ws.onclose = onClose;
//             ws.onmessage = onMessaage;
//             ws.onerror = onError;
//         }
//         return {
//             initWsConnect: function (url) {
//                 connect(url);
//                 initMessageEvent();
//             },
//             sendMessage: sendMessage,
//             closeWs: closeWs
//         }
//     })();
//     this.addEventListener('message', function (event) {
//         if (event.data.code) {
//             var code = event.data.code.toLowerCase();
//             switch (code) {
//                 case "init":
//                     wsMethod.initWsConnect(event.data.data);
//                     break;
//                 case "close":
//                     wsMethod.closeWs();
//                     break;
//                 case "sendmsg":
//                     wsMethod.sendMessage(JSON.stringify(event.data.data));
//                     break;
//                 default:
//                     break;
//             }
//         }
//     }, false);
// }

export { createWorker,workerFun}
/*
action type
*/
//ip status
export const GET_BOX_IP = 'GET_BOX_IP';
export const SET_BOX_IP = 'SET_BOX_IP';
//device type status
export const GET_CAMERA_TYPE = 'GET_CAMERA_TYPE';
export const SET_CAMERA_TYPE = 'SET_CAMERA_TYPE';
//mode status
export const GET_MODE = 'GET_MODE';
export const SET_MODE = 'SET_MODE';
//language
export const GET_LANG_STATE = 'GET_LANG_STATE';
export const SET_LANG_STATE = 'SET_LANG_STATE';
export const CHANGE_LANG_STATE = 'CHANGE_LANG_STATE';
//ota type
export const GET_OTA_TYPE = 'GET_OTA_TYPE';
export const SET_OTA_TYPE = 'SET_OTA_TYPE';
// systemMode
export const SET_SYSTEM_MODE = 'SET_SYSTEM_MODE';
// init
export const SET_INIT= 'SET_INIT';
import api from '../utils/api';
const SOPHON_SYSTEM = '/SophonSystem/api'

// 查询主机名
const getHostName = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/hostname`
}).then(res => res.data)

// 查询cpu使用
const getCpu = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/cpu`
}).then(res => res.data)

// 查询npu使用
const getNpu = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/npu`
}).then(res => res.data)

// 查询主机温度
const getTemperature = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/temperature`
}).then(res => res.data)

// 查询风扇转速
const getFan = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/fan`
}).then(res => res.data)

// 查询系统时间
const getTime = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/time`
}).then(res => res.data)

// 查询系统版本号
const getSystemVersion = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/systemVersion`
}).then(res => res.data)

// 查询主机SN
const getDeviceSN = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/deviceSN`
}).then(res => res.data)

// 查询内存情况
const getMemoryInfo = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/memoryInfo`
}).then(res => res.data)

// 查询产品型号和硬件版本
const getHardwareInfo = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/hardwareInfo`
}).then(res => res.data)

// 查询主机ip地址信息
const getIp = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/ip`
}).then(res => res.data)

// 查询系统运行时间
const getUptime = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/uptime`
}).then(res => res.data)

// 查询存储情况
const getDiskInfo = () => api({
    method:'get',
    url: `${SOPHON_SYSTEM}/diskInfo`
}).then(res => res.data)

// 查询Tpu
const getTpu = ()=>api({
    url:`${SOPHON_SYSTEM}/tpu`,
    method: 'get'
}).then(res => res.data);

export {
    getHostName,
    getCpu,
    getNpu,
    getTemperature,
    getFan,
    getTime,
    getSystemVersion,
    getDeviceSN,
    getMemoryInfo,
    getHardwareInfo,
    getIp,
    getUptime,
    getDiskInfo,
    getTpu
}
import React, { Component } from 'react'
import { Form, Input, Button, Icon, Modal } from 'antd'
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { intl } from '../Intel';
import { loadStatic } from '../utils/OEMPTAH';
import { getConfig } from '../services/system';
import { userLogin } from '../services/login';

class LoginForm extends Component {
    constructor() {
        super(...arguments)
        this.state = {
            dev_sno: '',
            workType: null
        }
        this.userName = '';
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.setState({
            submiting: true
        })
        this.props.form.validateFields((err, values) => {
            if (!err) {
                userLogin(values).then(res => {
                    this.setState({
                        submiting: false
                    })
                    this.userName = values.username;
                    if (this.props.systemMode === '1' || this.props.systemMode === '2') {
                        this.getworkType();
                    } else {
                        this.loginSuccess();
                    }
                }).catch(() => {
                    this.setState({
                        submiting: false
                    })
                })
            } else {
                console.error(err);
            }
        });

    }
    checkMqLoginStatus = () => {
        getConfig('')
    }
    loginSuccess = () => {
        window.location = '/';
        sessionStorage.setItem('session', this.userName);
    }
    getworkType = () => {
        let self = this;
        getConfig('WORK_MODE').then(res => {
            if (res.WORK_MODE === 'mq') {
                getConfig(`LOGIN_STATUS`).then(res => {
                    let data = { code: res.LOGIN_STATUS.code, message: '' };
                    if (data.code !== 0) {
                        data.message = localStorage.getItem('lang') === 'en' ? res.LOGIN_STATUS.message : res.LOGIN_STATUS.chinese_message;
                        Modal.error({
                            title: data.message,
                            onOk: self.loginSuccess
                        });
                    } else {
                        self.loginSuccess();
                    }
                })
            } else {
                this.loginSuccess();
            }
        })
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div style={{ width: 370, margin: '0 auto', backgroundColor: 'rgb(255, 255, 255)', padding: '20px 50px', borderRadius: 5 }}>
                <div style={{ textAlign: 'center', fontSize: '2rem', marginBottom: 20 }}><span><FormattedMessage id='login.title' /></span></div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item>
                        {getFieldDecorator('username', {
                            rules: [{ required: true, message: <FormattedMessage id="input.required" /> }],
                        })(
                            <Input prefix={<Icon type="user" style={{ color: 'rgba(0,0,0,.25)' }} />} placeholder={intl.formatMessage({ id: 'login.username.placeholder' })} />
                        )}
                    </Form.Item>
                    <Form.Item>
                        {getFieldDecorator('password', {
                            rules: [{ required: true, message: <FormattedMessage id="input.required" /> }],
                        })(
                            <Input prefix={<Icon type="lock" style={{ color: 'rgba(0,0,0,.25)' }} />} type='password' placeholder={intl.formatMessage({ id: 'login.password.placeholder' })} />
                        )}
                    </Form.Item>
                    <Form.Item>
                        <Button type="primary" loading={this.state.submiting} htmlType="submit" style={{ width: '100%', backgroundColor: '#1890ff' }}>
                            <FormattedMessage id="login.submit" />
                        </Button>
                    </Form.Item>
                </Form>
                <div className='footer-link'>
                    <div><FormattedMessage id="login.footer.help" /></div>
                    <div><FormattedMessage id="login.footer.privacy" /></div>
                    <div><FormattedMessage id="login.footer.terms" /></div>
                </div>
            </div>
        )
    }
}
const WrapperedLoginForm = Form.create()(LoginForm);
class LoginPage extends Component {
    background = null;
    componentDidMount() {
        loadStatic('LOGINBACKGROUND').then(
            (data) => this.background.style = `background:url(${data})`
        );
    }
    render() {
        const { title, organization } = this.props;
        return (
            <div ref={ el => this.background = el} className='login-page'>
                {/* <div style={{ position: 'fixed', top: 35, left: 35, width: 100 }}>
                    <img ref='logo' alt="logo" style={{ maxWidth: '100%' }}></img>
                </div> */}
                <div className='login-container'>
                    <div style={{ marginTop: 16, marginBottom: 32 }}>
                        <span style={{ fontSize: '2rem', color: 'RGBA(255, 255, 255, 1)' }}>
                            {/* {title} */}
                            <img src='dgos-light.png' alt="logo" style={{ width: '10%' }}></img>
                            
                        </span>
                    </div>
                    <WrapperedLoginForm systemMode={this.props.systemMode} />
                </div>
                <div style={{ textAlign: 'center', color: '#FFFFFF', position: 'fixed', bottom: 0, width: '100%' }}>
                    <div style={{ marginBottom: 24 }}>{organization || ''}</div>
                </div>
            </div>
        )
    }
}
export default connect(
    (state) => {
        return {
            title: state.OEM.title,
            systemMode: state.systemMode,
            organization: state.OEM.organization
        }
    }
)(LoginPage)
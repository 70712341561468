import React, { Component } from 'react'
import { Tabs, Layout } from 'antd'
import { FormattedMessage } from 'react-intl';
import Title from '../common/Title'
import FaceAttributes from './smart/FaceAttributes';
import FaceCapture from './smart/FaceCapture';
import FaceRecognition from './smart/FaceRecognition';
import { getConfigs } from '../../services/system';
import { connect } from 'react-redux';
const { TabPane } = Tabs;
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.SmartSetting' />
}];

class SmartSetting extends Component {
    constructor() {
        super(...arguments)
        this.state = {
            APPLY_MODE: ''
        }
    }
    componentDidMount() {
        getConfigs().then(res => {
            if (res.APPLY_MODE === 'recognize') {
                this.setState({
                    old_APPLY_MODE: 'recognize',
                    APPLY_MODE: 'recognize'
                })
            } else if (res.APPLY_MODE === 'grab') {
                this.setState({
                    old_APPLY_MODE: 'grab',
                    APPLY_MODE: 'grab'
                })
            }
        })
    }
    activeFaceValue = (value) => {
        this.setState({
            APPLY_MODE: value
        })
    }
    handleChange = (key) => {
        this.setState({
            tab: key
        })
    }
    render() {
        let APPLY_MODE = this.state.APPLY_MODE;
        let old_APPLY_MODE = this.state.old_APPLY_MODE;
        const isDocker = this.props.systemMode === '2';
        if (!old_APPLY_MODE) {
            return false;
        }
        return (
            <React.Fragment>
                <Title items={breadcrumbSetting} />
                <Layout style={{ margin: '24px', backgroundColor: '#FFFFFF', minHeight: '420px' }}>
                    <Tabs defaultActiveKey={APPLY_MODE} onChange={this.handleChange}>
                        <TabPane disabled={old_APPLY_MODE === 'recognize' ? false : APPLY_MODE === 'grab'} tab={<FormattedMessage id='setting.smart.recognition.tab.title' />} key="recognize">
                            <FaceRecognition tab={this.state.tab} isDocker = {isDocker} activeFaceValue={this.activeFaceValue} />
                        </TabPane>
                        <TabPane disabled={old_APPLY_MODE === 'grab' ? false : APPLY_MODE === 'recognize'} tab={<FormattedMessage id='setting.smart.capture.tab.title' />} key="grab">
                            <FaceCapture tab={this.state.tab} isDocker = {isDocker} activeFaceValue={this.activeFaceValue} />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id='setting.smart.attributes.tab.title' />} key='3'>
                            <FaceAttributes isDocker = {isDocker} tab={this.state.tab} />
                        </TabPane>
                    </Tabs>
                </Layout>
            </React.Fragment>
        )
    }
}

// export default SmartSetting
export default connect(
    (state) => {
        return {
            systemMode: state.systemMode
        }
    }
)(SmartSetting)
import { message } from 'antd'
import { SET_BOX_IP, SET_CAMERA_TYPE,SET_INIT, SET_SYSTEM_MODE,SET_MODE } from './actionTypes';
import axios from '../../utils/AxiosWrapper';
import { SOPHON_SYSTEM } from '../../utils/Config';
import { intl } from '../../Intel';
import { loadStatic } from '../../utils/OEMPTAH';
import { getConfig, getSystemMode } from '../../services/system';
/*
action constructor
*/
export function SetIP(IP) {
    return { type: SET_BOX_IP, data: IP }
}
export function SetCamraType(type) {
    return { type: SET_CAMERA_TYPE, data: type }
}
export function SetWorkMode(type) {
    return { type: SET_MODE, mode: type }
}
export function saveSystemMode(mode) {
    return { type:SET_SYSTEM_MODE,systemMode: mode }
}
export function saveInit(type) {
    return { type: SET_INIT, init: type }
}

//thunk action
function getNetWorkState() {
    return axios.get(`${SOPHON_SYSTEM}/ip`)
}
function getWorkModeState() {
    return getConfig('WORK_MODE');
}
function getInitState() {
    return getConfig('INIT');
}
// function getDeviceType() {
//     return axios.get(`${SOPHON_CONF_PREFIX}/inputDeviceType`)
// }
export function setClientIpState(silent = false) {
    return function (dispatch) {
        return getNetWorkState().then(
            resp => dispatch(SetIP(resp.data.data.ip)),
            err => !silent ? message.error(intl.formatMessage({ id: 'connect.error' })) : console.error('Cannot connect the box')
        )
    }
}
export function setSystemMode() {
    return function(dispatch) {
        return getSystemMode().then((res) => {
            if(res.data.systemMode === '1' || res.data.systemMode === '2'){
                dispatch(setClientWorkMode(true))
                dispatch(setInit())
            }else{
                dispatch(saveInit('1'))
            }
            return dispatch(saveSystemMode(res.data.systemMode))
        }).catch(() => {
            message.error(intl.formatMessage({id:'connect.error'}));
        })
    }
}
export function setClientWorkMode(silent = false) {
    return function (dispatch) {
        return getWorkModeState().then(
            resp => dispatch(SetWorkMode(resp.WORK_MODE)),
            err => !silent ? message.error(intl.formatMessage({ id: 'connect.error.workMode' })) : console.error('Cannot get WorkMode')
        )
    }
}
export function setInit() {
    return function (dispatch) {
        return getInitState().then(
            resp => dispatch(saveInit(resp.INIT))
        )
    }
}
// export function setClientDeviceType(silent=false) {
//     return function (dispatch) {
//         return getDeviceType().then(
//             resp => dispatch(SetCamraType(resp.data.state)),
//             err => !silent?message.error(intl.formatMessage({id:'connect.error.deviceType'})):console.log('Cannot get Device type')
//         )
//     }
// }
export function setOEM() {
    return function (dispatch) {
        return loadStatic('CONFIG', 'json').then(
            resp => {
                document.title = 'AI DGOS Platform';
                // document.title = resp.title;
                return dispatch({
                    type: 'UPDATE_OEM_CONFIG',
                    config: resp
                })
            },
            err => console.log(err)
        )
    }
}

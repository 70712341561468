import axios from "axios";
import https from "https";
import $ from 'jquery'
import qs from 'qs'

const agent = new https.Agent({
  rejectUnauthorized: false
});
const ClearDataBlank = function (data) {
  //处理数组和字典
  if (typeof data === 'object' && data !== null) {
    if (data instanceof Array) {
      data.forEach(
        (e) => {
          if (typeof e === 'string') {
            $.trim(e)
          }

        }
      );
      return data
    } else {
      for (let i in data) {
        if (typeof data[i] === 'string') {
          
            data[i] = $.trim(data[i]);
          
        } else {
          data[i] = ClearDataBlank(data[i])
        }
      }
      return data;
    }

  }
  //将undefine和null转成
  if(data === null || typeof data ==='undefined'){
    return data
  }
  if (typeof data === 'string') {
    return $.trim(data);
  }
  return data
}
const instance = axios.create({
  // timeout: 60000,
  httpsAgent: agent,
  transformRequest: [function (data, headers) {
    headers.put['Content-Type'] = headers.post['Content-Type'] = headers.delete['Content-Type'] = 'application/json';
    let final_data = ClearDataBlank(data);
    return JSON.stringify(final_data);
  }],
  paramsSerializer: function (params) {
    let final_data = ClearDataBlank(params);
    return qs.stringify(final_data,{arrayFormat:'brackets'});
  }
});
const fileUpload = axios.create(
  {
    timeout: 1000*60*60,
    headers: { 'Content-Type': 'multipart/form-data' }
  }
);
export {fileUpload}
export default instance;
import React from "react";
import moment from "moment-timezone";
import { Row, Col, Card, List, Progress, message, Descriptions, Popover } from "antd";
import { FormattedMessage } from 'react-intl';
import { USER_TYPE, USER_TYPE1 } from "../../../utils/Config";
import Title from '../../common/Title'
import SearchForm from './SearchForm'
import { getTheme } from '../../../services/system';
import { pictureSearch } from '../../../services/picturesearch'
moment.locale("zh-tw");
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/Event/Image',
    breadcrumbName: <FormattedMessage id='sidemenu.Event' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.Event.Image' />
}];

class SearchByImage extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            // total: 0,
            currentPage: 1,
            pageSize: 12,
            data: [],
            loading: false,
            selectedEvent: {}
        }
    }
    fetch = options => {
        let firstTime = null;
        if (!options.last_end_timestamp) {
            firstTime = options.reverse ? options.endtime : options.starttime
        } else {
            firstTime = options.last_end_timestamp
        }
        this.setState({
            loading: true
        })
        pictureSearch({ per_page: this.state.pageSize, ...options }).then(res => {
            if (res.retcode === 0) {
                message.error(res.error_message);
                this.setState({
                    loading: false
                })
                return false;
            } else {
                if (!res.have_more) {
                    this.setState({
                        options: null
                    })
                } else {
                    options.last_end_timestamp = res.endtimestamp;
                    this.setState({
                        options: options,
                        firstTime: firstTime
                    })
                }
                this.setState({
                    loading: false,
                    data: res.event_list,
                    total: res.event_list.length,
                    selectedEvent: res.event_list.length ? res.event_list[0] : {}
                })
            }

        }).catch(err => {
            this.setState({
                loading: false
            })
        })
    };
    componentDidMount() {
        getTheme().then(res => {
            this.setState({
                theme: res
            })
        })
    }
    renderUserType = (value) => {
        let NEW_USER_TYPE = null;
        if (this.state.theme === 'PARK') {
            NEW_USER_TYPE = USER_TYPE;
        } else {
            NEW_USER_TYPE = USER_TYPE1;
        }
        for (let item of NEW_USER_TYPE) {
            if (item.typeId === value) {
                return <FormattedMessage id={`user.add.type.${item.description}`} />
            }
        }
    }
    findNextMore = () => {
        this.fetch(this.state.options);
    }
    formatTime = value => moment(value).format('YYYY-MM-DD HH:mm:ss');
    render() {
        let resultTile = this.state.options ? <span><FormattedMessage id='event.searchImage.content.title' /><span style={{ marginLeft: 10, fontSize: 12, fontWeight: 300 }}><FormattedMessage id='event.searchImage.content.result.tips' />{this.formatTime(this.state.firstTime * 1000) + '~' + this.formatTime(this.state.options.last_end_timestamp * 1000)},</span><span className="cursor" onClick={this.findNextMore}><FormattedMessage id='event.searchImage.content.result.nextSearch' /></span></span>
            : (this.state.loading ? <span><FormattedMessage id='event.searchImage.content.title' /><span style={{ marginLeft: 10, fontSize: 12, fontWeight: 300 }}><FormattedMessage id='event.searchImage.content.tips' /></span></span> : <FormattedMessage id='event.searchImage.content.title' />);
        let selectedEvent = this.state.selectedEvent;
        return (
            <div className="search-by-image">
                <Title items={breadcrumbSetting} />
                <div style={{ overflowX: 'scroll' }}>
                    <div style={{ minWidth: 1100, margin: 24, background: 'ffffff' }}>
                        <Row gutter={2}>
                            <Col span={5} >
                                <Card size='small' title={<FormattedMessage id='event.searchImage.form.title' />} className="card-content" bordered={false}>
                                    <SearchForm loading={this.state.loading} query={(value) => this.fetch(value)} />
                                </Card>
                            </Col>
                            <Col span={9}>
                                <Card size='small' title={resultTile} className="card-content" bordered={false}>
                                    <List
                                        split={false}
                                        bordered={false}
                                        loading={this.state.loading}
                                        grid={{ gutter: 24, column: 3, xxl: 4 }}
                                        dataSource={this.state.data}
                                        pagination={{
                                            pageSize: this.state.pageSize,
                                            size: 'small',
                                            defaultCurrent: this.state.currentPage,
                                            showQuickJumper: true
                                        }}
                                        renderItem={item => (
                                            <List.Item>
                                                <div onClick={
                                                    () => {
                                                        this.setState({
                                                            selectedEvent: item
                                                        })
                                                    }
                                                }
                                                    className="content">
                                                    <div className="img-box" style={{ backgroundImage: `url(/SophonFace/api/event_images/${item.recognize_face_pic_id}?datetime=${item.timestamp})` }}>
                                                        <div className="score"><Progress width={30}
                                                            type="circle"
                                                            status='normal'
                                                            strokeWidth={7}
                                                            percent={parseInt(item.compare_score)} /></div>
                                                    </div>
                                                    <p>{item.device_name}</p>

                                                </div>
                                            </List.Item>
                                        )} />
                                </Card>
                            </Col>
                            <Col span={10}>
                                <Card size='small' title={<FormattedMessage id='event.searchImage.detail.title' />} className="card-content" bordered={false}>
                                    <div style={{
                                        height: "40vh", textAlign: 'center', backgroundColor: "rgb(238, 240, 243)",
                                        backgroundImage: selectedEvent.spot_pic_id ? `url(/SophonFace/api/event_images/${selectedEvent.spot_pic_id}?datetime=${selectedEvent.timestamp})` : '',
                                        backgroundSize: "contain",
                                        backgroundPosition: "center",
                                        backgroundRepeat: "no-repeat"
                                    }}>
                                    </div>
                                    <div style={{ marginTop: "20px" }}>
                                        <Descriptions size="small" column={2} bordered>
                                            <Descriptions.Item label={<FormattedMessage id='user.list.form.name' />}>{selectedEvent.identity_name}</Descriptions.Item>
                                            <Descriptions.Item label={<FormattedMessage id='event.filter.userType' />}>
                                                {this.renderUserType(selectedEvent.person_type)}
                                            </Descriptions.Item>
                                            <Descriptions.Item label={<FormattedMessage id='event.history.epName' />}>{selectedEvent.ep_info ? selectedEvent.ep_info.ep_name : ''}</Descriptions.Item>
                                            <Descriptions.Item label={<FormattedMessage id='event.bar.score.title' />}>{selectedEvent.compare_score ? parseInt(selectedEvent.compare_score) : ''}</Descriptions.Item>

                                            <Descriptions.Item span={2} label={<FormattedMessage id='event.description.entry' />}>{<Popover content={(new Date(selectedEvent.timestamp * 1000)).toLocaleString()}>
                                                {selectedEvent.timestamp ? new Date(selectedEvent.timestamp * 1000).toLocaleString() : ''}
                                            </Popover>}</Descriptions.Item>
                                            <Descriptions.Item span={2} label={<FormattedMessage id='event.description.go' />}>{<Popover content={(new Date(selectedEvent.out_timestamp * 1000)).toLocaleString()}>
                                                {selectedEvent.out_timestamp ? (new Date(selectedEvent.out_timestamp * 1000)).toLocaleString() : ''}
                                            </Popover>}</Descriptions.Item>
                                        </Descriptions>
                                    </div>
                                </Card>
                            </Col>
                        </Row>
                    </div>
                </div>

            </div>
        )
    }
}
export default SearchByImage;


export default {
    "user.list.form.name":"姓名",
    "user.list.form.userID":"用户ID",
    "user.list.form.id":"ID",
    "user.list.form.visitCardNumber":"门禁卡号",
    "user.list.form.profile":"底库照片",
    "user.list.form.group":"用户组",
    "user.list.bar.bulkDownload":"批量导入工具下载",
    "user.list.form.userType":"用户类型",
    "user.list.form.action":"操作",
    "user.list.form.description":"描述",
    "user.list.bar.batchAdd":"批量添加",
    "user.list.bar.downloadToPC":"导出到PC端",
    "user.list.bar.downloadToTF":"导出到TF卡",
    "user.list.bar.download":"导出",
    "user.list.bar.bulkAdd.title":"批量添加",
    "user.list.bar.bulkAdd.tab1":"批量添加",
    "user.list.bar.bulkAdd.tab2":"使用zip文件进行注册",
    "user.list.bar.bulkAdd.tab3":"导入",
    "user.list.bar.bulkAdd.template":"下载模板",
    "user.list.bar.bulkAdd.groups":"用户组",
    "user.list.bar.bulkAdd.images":"选择图片",
    "user.list.bar.bulkAdd.imagesTips":"选择文件夹",
    "user.list.bar.bulkAdd.imagesMsg":"已选择:{number}个文件",
    "user.list.bar.bulkAdd.excel":"选择excel文件",
    "user.list.bar.bulkAdd.excelTips":"选择excel文件",
    "user.list.bar.bulkAdd.excelMsg":"excel文件",
    "user.list.bar.bulkAdd.zip.tips":"使用zip文件注册用户",
    "user.list.bar.bulkAdd.import.tips":"导入从其他Box导出的zip文件",
    "user.list.alert.msg1":"条记录被选中",
    "user.list.alert.msg2":"共: {number} 条用户记录",
    "user.group.add.exsit":"组名已经存在",
    "user.list.delete.tips":"确定删除用户吗？",
    "user.add.takePhotoTitle":"拍摄照片",
    "user.add.selectPhotoTitle":"选择照片",
    "user.add.camera.error":"获取摄像头失败，请确保本地有摄像头硬件设备或使用较高版本的现代浏览器(如：Firefox 21，chrome 49以上)",
    "user.add.camera.canvas":"您的浏览器不支持canvas元素，请确保使用较高版本的现代浏览器(如：Firefox 21，chrome 49以上)",
    "user.add.takePhoto":"拍摄",
    "user.add.remake":"重新拍摄",
    "user.add.upload":"上传",
    "user.add.reupload":"重新上传",
    "user.add.type.Staff":"员工",
    "user.add.type.Student":"学生",
    "user.add.type.Visitor":"访客",
    "user.add.type.Stranger":"陌生人",
    "user.add.type.Blacklist":"黑名单",
    "user.add.type.Vip":"VIP",
    "user.group.list.name":"组名",
    "user.group.list.groupID":"组ID",
    "user.group.list.description":"描述",
    "user.group.list.action":"操作",
    "user.group.alert.msg1":"用户组 被选中",
    "user.group.alert.msg2":"共: {number}条用户组记录",
    "user.add.selectPhoto.image.large":"图片分辨率应该小于4096 * 4096",
    "user.add.startTime.error":"结束时间应该晚于开始时间",
    "user.add.selectPhoto.image.error":"图片读取错误",
    "user.add.userID.existed":"UserID已经存在",
    "user.add.image.null":"需要拍摄或者上传一张图片",
    "user.group.delete.title":"确定删除该用户组吗?",
    "user.group.delete.error":"该用户组当前还有关联用户，请移除关联用户再尝试",
    "user.group.delete.error.stranger":"陌生用户组不可删除",
    "user.group.delete.error.error":"删除用户组失败",
    "user.add.import.noFile":"请选择文件",
    "user.add.import.image":"仅支持少于1000张图片的导入",
    "user.add.import.tips":"导入完成，更多详情请查看result.zip文件",
    "user.export.all.tips":"导出所有用户?",
    "user.export.selected.tips":"导出选择的用户?",
    "user.add.error.badrequest":"请求无效",
    "user.add.error.params":"无效的请求:参数错误",
    "user.add.error.image.noFace":"照片中没有识别到人脸,请重新上传",
    "user.add.error.image.error":"图片错误:{msg},请重新上传图片",
    "user.add.error.image.large":"图片超出大小:{msg}",
    "user.add.error.image.multipleFace":"识别到多人脸:{msg}",
    "user.add.error.params.long":"请求参数过长",
    "user.add.input.blank":"请不要只输入空格",
    "user.history.lastDatetime":"最后到访时间",
    "user.history.PassRecord":"历史通行记录",
    'user.preview.register.image':"图片详情",

    "user.add.form.mobile.pattern":"请输入有效的手机号",
    "user.add.form.idNumber.pattern":"请输入有效的身份证号",
    "user.add.form.email.pattern":"请输入有效的邮箱",
    "user.add.info.user":"个人信息",

    'user.add.form.uploadMethod' : '上传方式',
    'user.add.form.shootmethod' : '拍摄方式',
    'user.add.form.moreConfig' : '更多配置',
    'user.add.form.mobile' : '手机号',
    'user.add.form.email' : '邮箱',
    'user.add.form.idNumber' : '证件号码',
    'user.add.form.gender' : '性别',
    'user.add.form.gender.female' : '女',
    'user.add.form.gender.male' : '男',
    'user.add.form.take.problem' : '拍摄方式遇见问题？',
    "user.add.form.take.problem.title": '原因：http默认不支持拍照上传，可配置如下开通',
    'user.add.form.photo' : '识别照片:',
    'user.add.form.jobNumber' : '工号',
    'user.add.form.studentId' : '学号',
    'user.add.form.title' : '职位',
    'user.add.form.department' : '部门',
    'user.add.form.entryTime' : '入职时间',
    'user.add.form.address' : '地址',
    'user.add.form.building_no' : '楼宇',
    'user.add.form.floor' : '楼层',
    'user.add.form.room' : '房间号',
    'user.add.form.startTime' : '来访时间',
    'user.add.form.endTime' : '离开时间',
    'user.add.form.purpose' : '来访目的',
    'user.add.form.interviewee' : '受访人',
    'user.add.form.comeFrom' : '受访单位',
    'user.add.form.message' : '欢迎语',
    'user.add.form.visitor.tips' : '不是访客无需填写以下信息'
}
import React, { Component } from 'react'
import { Modal, Form, Select, Input, Button, Layout } from 'antd'
import { URL_PATTERN } from '../../utils/Config';
import Title from '../common/Title'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { connect } from 'react-redux';
import { getConfig, setConfig, setConfigs } from '../../services/system';

class EditForm extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            type: '',
            loginUrl: '',
            devSno: '',
            loading: false,
            workMode: '',
            loginRes: { code: 0, message: '' }
        }
    }

    componentDidMount() {
        getConfig('WORK_MODE').then(res => {
            if (res.WORK_MODE === 'mq') {
                getConfig(`LOGIN_URL`).then(res => {
                    this.setState({
                        loginUrl: res.LOGIN_URL
                    })
                })
                getConfig(`DEV_SNO`).then(res => {
                    this.setState({
                        devSno: res.DEV_SNO
                    })
                })
                getConfig(`LOGIN_STATUS`).then(res => {
                    let data = { code: res.LOGIN_STATUS.code, message: '' };
                    if (data.code !== 0) {
                        data.message = localStorage.getItem('lang') === 'en' ? res.LOGIN_STATUS.message : res.LOGIN_STATUS.chinese_message;
                        this.setState({
                            loginRes: data
                        })
                    }
                })
            }
            this.setState({
                type: res.WORK_MODE,
                workMode: res.WORK_MODE
            })
        })
    }
    setValidateStatusOk = () => {
        if (this.state.loginRes.code) {
            let newLoginRes = { login_status: 0, message: '' };
            this.setState({
                loginRes: newLoginRes
            })
        }
    }
    handleSubmit = (e) => {
        const isDocker = this.props.systemMode === '2';
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if (values.mode === 'mq') { //工作模式是mq
                        let data = {};
                        if (this.state.workMode !== 'mq') {
                            data.WORK_MODE = values.mode;
                        }
                        if (this.state.loginUrl !== values.loginUrl) {
                            data.LOGIN_URL = values.loginUrl;
                        }
                        if (this.state.devSno !== values.devSno) {
                            data.DEV_SNO = values.devSno;
                        }
                        if (JSON.stringify(data) !== '{}') {
                            Modal.confirm({
                                title: intl.formatMessage({ id: isDocker ? 'info.gate.restart' : 'info.restart' }),
                                content: intl.formatMessage({ id: data.WORK_MODE ? 'settings.workmode.change.content' : 'settings.workmode.mq.content' }),
                                okType: 'danger',
                                onOk: () => {
                                    this.setState({
                                        loading: true
                                    })
                                    setConfigs({ "config_dict": data }).then(res => {
                                        this.setState({
                                            loading: false
                                        })
                                        this.logout();
                                    }).catch(() => {
                                        this.setState({
                                            loading: false
                                        })
                                    })
                                }
                            })

                        }
                    } else {
                        if (values.mode !== this.state.workMode) {
                            Modal.confirm({
                                title: intl.formatMessage({ id: isDocker ? 'info.gate.restart' : 'info.restart' }),
                                content: intl.formatMessage({ id: 'settings.workmode.change.content' }),
                                okType: 'danger',
                                onOk: () => {
                                    this.setState({
                                        loading: true
                                    })
                                    setConfig({ "WORK_MODE": "local" }).then(() => {
                                        this.setState({
                                            loading: false
                                        })
                                        this.logout();
                                    }).catch(() => {
                                        this.setState({
                                            loading: false
                                        })
                                    })
                                }
                            })

                        }
                    }
                }
            })
    }
    handleSelectChange = (value) => {
        this.setState(
            { type: value }
        )
    }
    logout = () => {
        sessionStorage.clear();
        window.location.href = '/';
    }
    render() {
        const loginRes = this.state.loginRes;
        const { getFieldDecorator } = this.props.form;
        const configDom = this.state.type === 'mq' ?
            <div>
                <Form.Item label={<FormattedMessage id='settings.workmode.appia.url' />} help={<FormattedMessage id='settings.workmode.mq.login_url.help' />} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                    {getFieldDecorator('loginUrl', {
                        rules: [
                            { required: true, message: <FormattedMessage id='input.required' /> },
                            { pattern: URL_PATTERN, message: <FormattedMessage id='url.invalid' /> }
                        ],
                        initialValue: this.state.loginUrl
                    })(
                        <Input style={{ width: 320 }}></Input>
                    )}
                </Form.Item>
                <Form.Item
                    // validateStatus={loginRes.code ? "error" : ''}
                    label={<FormattedMessage id='settings.workmode.appia.devsno' />}
                    labelCol={{ span: 10 }}
                    wrapperCol={{ span: 8 }}
                    help={loginRes.code ? <span className="red">{loginRes.message}</span> : <FormattedMessage id='settings.workmode.mq.get' />}
                >
                    {getFieldDecorator('devSno', {
                        rules: [
                            { required: true, message: <FormattedMessage id='input.required' /> }
                        ],
                        initialValue: this.state.devSno
                    })(
                        <Input style={{ width: 320 }} onChange={this.setValidateStatusOk}></Input>
                    )}
                </Form.Item>
            </div> : null;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='sidemenu.System.WorkMode' />} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                    {getFieldDecorator('mode', {
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> }],
                        initialValue: this.state.type
                    })(
                        <Select style={{ width: 320 }} onChange={this.handleSelectChange}>
                            <Select.Option key='local'><FormattedMessage id='settings.workmode.local' /></Select.Option>
                            <Select.Option key='mq'><FormattedMessage id='settings.workmode.mq' /></Select.Option>
                        </Select>
                    )}
                </Form.Item>
                {configDom}
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit" loading={this.state.loading} disabled={this.state.type === ''}><FormattedMessage id='button.apply' /></Button>
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.WorkMode' />
}];
class WorkModeSetting extends Component {
    render() {
        return (
            <div>
                <Title items={breadcrumbSetting} />
                <Layout style={{ margin: '24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 100 }}>
                    <WrapperedEditForm {...this.props} />
                </Layout>
            </div>
        )
    }
}
// export default WorkModeSetting
export default connect(
    (state) => {
        return {
            systemMode: state.systemMode
        }
    }
)(WorkModeSetting)
import React, { Component } from 'react'
import { Form, Input,message, Button, Tabs, Select, InputNumber } from 'antd';
import { SOPHON_FOG_SYS } from '../../utils/Config';
import Title from '../common/Title';
import axios from '../../utils/AxiosWrapper'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';


// class RealtimeEventServerURLFrom extends Component {
//     state = {
//         event_server_url:[],
//         content_type:'',
//         disabled:true
//     }
//     componentDidMount(){
//         axios.get(`${SOPHON_FOG_SYS}/EventServerUrl`).then(
//             (resp)=>{
//                 this.setState({
//                     event_server_url:resp.data.data.event_server_url,
//                     disabled:false,
//                     content_type:resp.data.data['content-type']
//                 })
//             }
//         ).catch(
//             (e)=>{
//                 message.error(intl.formatMessage({id:'network.error'}));
//             }
//         )
//     }
//     handleSubmit = (e)=>{
//         e.preventDefault()
//         this.props.form.validateFields(
//             (error,values)=>{
//                 if(!error){
//                     let urls = values.event_server_url||'';
//                     let url_array = urls.split(/,|，/);
//                     let cleaned_urls = [];
//                     //去除两边空格
//                     url_array.forEach(
//                         (e)=>{
//                             let cleaned_url = e.replace(/^\s+|\s+$/g,'');
//                             if(cleaned_url){
//                                 cleaned_urls.push(cleaned_url)
//                             }
                            
//                         }
//                     )
//                     let post_data = {}
//                     post_data.event_server_url = cleaned_urls;
//                     post_data['content-type'] = values['content-type'];
//                     console.log('post data:',post_data)
//                     axios.post(`${SOPHON_FOG_SYS}/EventServerUrl`,post_data).then(
//                         (resp)=>{
//                             message.success(intl.formatMessage({id:'info.success'}),1)
//                         }
//                     ).catch(()=>{message.error(intl.formatMessage({id:'network.error'}));})
                    
//                 }
//             }
//         )
//     }
//     render() {
//         const { getFieldDecorator } = this.props.form;
//         return (
//             <div>
//                 <Form onSubmit={this.handleSubmit}>
//                     <Form.Item label={<FormattedMessage id='settings.event.url'/>} labelCol={{span:3}} wrapperCol={{span:15}}>
//                         {getFieldDecorator('event_server_url',{
//                             initialValue:this.state.event_server_url.join(','),
//                             //rules:[{required:true,message:'Please enter server URL'}]
//                         })(
//                             <Input.TextArea rows={5} placeholder={intl.formatMessage({id:'settings.event.url.placeholder'})}></Input.TextArea>
//                         )}
//                     </Form.Item>
//                     <Form.Item label={<FormattedMessage id='settings.event.contentType'/>} labelCol={{span:3}} wrapperCol={{span:5}}>
//                         {getFieldDecorator('content-type',{
//                             initialValue:this.state.content_type,
//                             rules:[{pattern:true,message:<FormattedMessage id='input.required'/>}]
//                         })(
//                             <Select>
//                                 <Select.Option key='json'>json</Select.Option>
//                                 <Select.Option key='form-data'>form-data</Select.Option>
//                             </Select>
//                         )}
//                     </Form.Item>
//                     <Form.Item wrapperCol={{ offset: 9 }}>
//                         <Button type='primary' htmlType='submit' disabled={this.state.disabled}><FormattedMessage id='button.apply'/></Button>
//                     </Form.Item>
//                 </Form>
//             </div>
//         )
//     }
// }
// const WrapperedRealtimeEventForm = Form.create()(RealtimeEventServerURLFrom)

class HistoryEventServerURLFrom extends Component {
    state = {
        history_upload_event_url:[],
        content_type:'',
        reported_peroid:20,
        disabled:true
    }
    componentDidMount(){
        axios.get(`${SOPHON_FOG_SYS}/HistoryEventUrl`).then(
            (resp)=>{
                this.setState({
                    history_upload_event_url:resp.data.data.history_upload_event_url,
                    reported_peroid:resp.data.data.reported_peroid,
                    disabled:false,
                    content_type:resp.data.data['content-type']
                })
            }
        ).catch(
            (e)=>{
                message.error(intl.formatMessage({id:'network.error'}));
            }
        )
    }
    handleSubmit = (e)=>{
        e.preventDefault()
        this.props.form.validateFields(
            (error,values)=>{
                if(!error){
                    console.log('hitory upload url post data:',values)
                    let urls = values['history_upload_event_url']||'';
                    let url_array = urls.split(/,|，/);
                    let cleaned_urls = [];
                    //去除两边空格
                    url_array.forEach(
                        (e)=>{
                            let cleaned_url = e.replace(/^\s+|\s+$/g,'');
                            if(cleaned_url){
                                cleaned_urls.push(cleaned_url)
                            }
                            
                        }
                    )
                    let post_data = {}
                    post_data['history_upload_event_url'] = cleaned_urls;
                    post_data['content-type'] = values['content-type'];
                    post_data['reported_peroid'] = values['reported_peroid'];
                    axios.post(`${SOPHON_FOG_SYS}/HistoryEventUrl`,post_data).then(
                        (resp)=>{
                            // message.success('edit success!')
                            message.success(intl.formatMessage({id:'info.success'}),1)
                        }
                    ).catch(()=>{message.error(intl.formatMessage({id:'network.error'}));})
                    
                }
            }
        )
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                <Form onSubmit={this.handleSubmit}>
                    <Form.Item label={<FormattedMessage id='settings.event.url'/>} labelCol={{span:10}} wrapperCol={{span:9}}>
                        {getFieldDecorator('history_upload_event_url',{
                            initialValue:this.state.history_upload_event_url.join(','),
                            //rules:[{required:true,message:'Please enter server URL'}]
                        })(
                            <Input.TextArea rows={5} placeholder={intl.formatMessage({id:'settings.event.url.placeholder'})}></Input.TextArea>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='settings.event.contentType'/>} labelCol={{span:10}} wrapperCol={{span:8}}>
                        {getFieldDecorator('content-type',{
                            initialValue:this.state.content_type,
                            rules:[{required:true,message:<FormattedMessage id='input.required'/>}]
                        })(
                            <Select style={{width:110}}>
                                <Select.Option key='json'>json</Select.Option>
                                <Select.Option key='form-data'>form-data</Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='settings.event.History.peroid'/>} labelCol={{span:10}} wrapperCol={{span:8}}>
                        {getFieldDecorator('reported_peroid',{
                            initialValue:this.state.reported_peroid,
                            rules:[
                                {required:true,message:<FormattedMessage id='input.required'/>},
                                {pattern:/^[1-9]$|^\d{2,}$/,message:<FormattedMessage id='settings.event.peroid.format' values={{peroid:'1'}}/>}
                            ]
                        })(
                            <InputNumber style={{width:110}} placeholder={intl.formatMessage({id:'settings.event.peroid'},{peroid:'1'})}></InputNumber>
                        )}
                        <span style={{marginLeft:5}}><FormattedMessage id='settings.event.History.seconds'/></span>
                    </Form.Item>
                    <Form.Item style={{textAlign:'center'}}>
                        <Button type='primary' htmlType='submit' disabled={this.state.disabled}><FormattedMessage id='button.apply'/></Button>
                    </Form.Item>
                </Form>
            </div>
        )
    }
}
const WrapperedHistoryUploadForm = Form.create()(HistoryEventServerURLFrom);

const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home'/>
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System'/>
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.EventServerSetting'/>
}];
class EventServerSetting extends Component{
    render(){
        return(
            <div>
                <Title items={breadcrumbSetting} />
                <div style={{ margin: 24, backgroundColor: '#FFFFFF',minHeight:420 }}>
                    {/* <div style={{ margin: '0 auto', width: '80%' }}> */}
                    <Tabs>
                        {/* <Tabs.TabPane tab={<FormattedMessage id='settings.event.realTime.tag'/>} key='1'>
                        <WrapperedRealtimeEventForm {...this.props} />
                        </Tabs.TabPane> */}
                        <Tabs.TabPane tab={<FormattedMessage id='settings.event.History.tag'/>} key='2'>
                        <WrapperedHistoryUploadForm {...this.props}/>
                        </Tabs.TabPane>
                    </Tabs> 
                    {/* </div> */}
                </div>
            </div>
        )
    }
}

export default EventServerSetting
import { Form, Button, Icon, message, Upload, Select, DatePicker, InputNumber } from 'antd';
import React from "react";
import { FormattedMessage } from 'react-intl';
import StreamSelect from "../History/EpSelect"
import { getDevices } from '../../../services/devices'
import './search-form.css'
import moment from 'moment'
import { intl } from '../../../Intel';
const FormItem = Form.Item;
const FormItemLayout = {
    labelCol: { span: 5 },
    wrapperCol: { span: 19 },
}
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}

function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error(intl.formatMessage({ id: 'event.searchImage.form.upload.type' }), 1.5)
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error(intl.formatMessage({ id: 'event.searchImage.form.upload.size' }), 1.5)
    }
    return isJpgOrPng && isLt5M;
}
class SearchForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            videoData: []
        }
    }
    handleSubmit = e => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                delete values.fid;
                if (!this.state.imageUrl) {
                    message.warning(intl.formatMessage({id:'event.searchImage.form.upload.warning'}),1.5);
                    return false;
                }
                values.starttime = +new Date(values.starttime) / 1000;
                values.endtime = +new Date(values.endtime) / 1000;
                if(values.starttime > values.endtime){
                    message.warning(intl.formatMessage({id:'event.searchImage.form.time.warning'}),1.5);
                    return false;
                }
                if(!values.threshold&&values.threshold!==0){
                    delete values.threshold;
                }
                if(values.device_id_list&&!values.device_id_list.length){
                    delete values.device_id_list;
                }
                if(values.reverse==='no-order'){
                    values.reverse = true;
                }else{
                    values.reverse = false; 
                }
                values.face_image_base64 = this.state.imageUrl.split(';base64,')[1];
               
                this.props.query(values);
            }
        });
    };
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
    }
    componentDidMount(){
        getDevices().then((res) => {
            const video_list = res.devices_list;
            let videos = [];
            for (let i = 0; i < video_list.length; ++i) {
                videos.push({
                    key: video_list[i].device_id,
                    name: video_list[i].device_name,
                })
            }
            this.setState({ videoData: videos })
        })
        this.defaultStartTime = moment(moment().subtract(7, 'day'), 'YYYY/MM/DD HH:mm');
        this.defaultEndTime = moment(new Date(), 'YYYY/MM/DD HH:mm');
    }
    handleCustomRequest = (a) => {
        getBase64(a.file, imageUrl =>
            this.setState({
                imageUrl,
                loading: false,
            }),
        )
    }
    clearImage = (e) => {
        if(this.props.loading){
            return false;
        }else{
            e.stopPropagation();
            this.setState({imageUrl: null});
        }
      };
    render() {
        let clearButton;
        const { getFieldDecorator } = this.props.form;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text"><FormattedMessage id='user.add.upload' /></div>
            </div>
        );
        const { imageUrl } = this.state;
        if(imageUrl){
            clearButton = (
                <div className="btn-clear-wrapper" onClick={this.clearImage}>
                  <Icon type="close" />
                </div>
              );
        }
        return (
            <Form onSubmit={this.handleSubmit}>
                 <FormItem>
                    {getFieldDecorator("fid", {})(
                        <Upload
                            name="avatar"
                            disabled={this.props.loading}
                            listType="picture-card"
                            className="avatar-uploader"
                            accept="image/jpeg,image/jpg,image/png"
                            showUploadList={false}
                            beforeUpload={beforeUpload}
                            customRequest={this.handleCustomRequest}
                            onChange={this.handleChange}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                            {clearButton}
                        </Upload>
                    )}
                </FormItem>
                <Form.Item label={<FormattedMessage id='event.searchImage.form.startTime' />}>
                    {getFieldDecorator('starttime', {
                        rules: [{ type: 'object', required: true, message: <FormattedMessage id='select.required'/> }],
                        initialValue:  this.defaultStartTime
                    })(
                        <DatePicker  disabled={this.props.loading}  style={{ width: "100%" }} showTime format="YYYY-MM-DD HH:mm:ss" />,
                    )}
                </Form.Item>
               
                <Form.Item label={<FormattedMessage id='event.searchImage.form.endTime' />}>
                    {getFieldDecorator('endtime', {
                        rules: [{ type: 'object', required: true, message: <FormattedMessage id='select.required'/> }],

                        initialValue: this.defaultEndTime
                    })(
                        <DatePicker  disabled={this.props.loading} style={{ width: "100%" }} showTime format="YYYY-MM-DD HH:mm:ss" />,
                    )}
                </Form.Item>
                <FormItem label={<FormattedMessage id='event.history.epName' />} {...FormItemLayout}>
                    {getFieldDecorator("device_id_list")(
                        <StreamSelect  disabled={this.props.loading} width={'100%'} data={this.state.videoData} />
                    )}
                </FormItem>
                <Form.Item label={<FormattedMessage id='event.bar.score.title' />} {...FormItemLayout}>
                    {getFieldDecorator('threshold',{
                        initialValue: 82.0
                    })(<InputNumber  disabled={this.props.loading} style={{ width: "100%" }}  min={0} max={100} step={0.1} />)}
                </Form.Item>
                <Form.Item label={<FormattedMessage id='event.searchImage.searchOrder' />} {...FormItemLayout}>
                    {getFieldDecorator('reverse', {
                         initialValue:'no-order'
                    })(
                        <Select  disabled={this.props.loading}>
                            <Select.Option value='order'><FormattedMessage id='event.searchImage.searchOrder.order' /></Select.Option>
                            <Select.Option value='no-order'><FormattedMessage id='event.searchImage.searchOrder.reverse' /></Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item wrapperCol={{ span: 12, offset: 5 }}>
                    <Button type="primary" disabled={this.props.loading} htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                </Form.Item>
            </Form>
        );
    }
}

export default Form.create()(SearchForm);

const TYPE_MAPPING = [
    //{ typeId: 0, description: "展拓 (横屏, GPIO)" },//
    //{ typeId: 1, description: "大匠 (竖屏, GPIO)" },
    //{ typeId: 2, description: "自研(竖屏 USB)" },
    //{ typeId: 3, description: "百顺 (竖屏, RS232)" },
    { typeId: 4, description: "ZKT (7A)" },
    //{ typeId: 6, description: "竖屏(竖屏, Usb Relay)" },
    { typeId: 7, description: "ZKT (G4)" },
    { typeId: 10, description: "Honoo" },
    //{ typeId: 7, description: "中控 白 (竖屏, ZTK)" },
    //{ typeId: 7, description: "天波 黑(竖屏, TPS)" },
    //{ typeId:8, description: "自研 (竖屏 USB)"},
    { typeId: 9, description: "Sophon (SF3D-M1)" },
    { typeId: 5, description: "Sophon (BM-M1)" },
    { typeId: 11, description: "Sophon (BM-M2)"},
    { typeId: 12, description: "Sophon (BM-M3)"},
    { typeId: 13, description: "Sophon (BM-M4)"},
    { typeId: 14, description: "Sophon (BM-M5)"}
];
const FACE_CAP_CAM_TYPE_MAPPING = [
    { typeId: 1, description: '大华' },
    { typeId: 2, description: '伟视清' },
    { typeId: 3, description: '未知' }
]
const DEVICE_TYPE = [
    { typeId: 0, description: 'Turnstile camera',formatID:'gate' },
    { typeId: 1, description: 'Capture camera',formatID:'captureCamera' },
    // { typeId: 2, description: 'Video camera(Gate)',formatID:'ipcGate' },
    { typeId: 3, description: 'Video camera(Stream)',formatID:'ipcStream' }
]
const USER_TYPE = [
    { typeId: 1, description: 'Staff' },
    { typeId: 2, description: 'Visitor' },
    // { typeId: 3, description: 'Blacklist' },
    { typeId: 4, description: 'Stranger',disabled:true },
    { typeId: 5, description: 'Vip', }
]
const USER_TYPE1 = [
    { typeId: 1, description: 'Student' },
    { typeId: 2, description: 'Visitor' },
    // { typeId: 3, description: 'Blacklist' },
    { typeId: 4, description: 'Stranger',disabled:true },
    { typeId: 5, description: 'Vip', }
]
const IP_PATTERN = /^(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])$/
const PORT_PATTERN = /^([0-9]|[1-9]\d{1,3}|[1-5]\d{4}|6[0-4]\d{3}|65[0-4]\d{2}|655[0-2]\d|6553[0-5])$/
const IP_PATTERN_BLUR = (function(){
    // 一个ip中的数字应该取值的范围
    const ip_elm = "(?:25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])";
    const reg = `^${ip_elm}$|^${ip_elm}\\.$|^(?:${ip_elm}\\.){1,3}(?:${ip_elm})?$`;
    return new RegExp(reg);
})()
// 获取一个8位的uuid 
const uuid = () => {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4()+s4();
  }
const LOCAL_IP = process.env.NODE_ENV === 'development' ? '192.168.1.180' : window.location.hostname;////
const HOSTNAME_PATTERN = /^[a-zA-Z0-9]+[-]?[0-9a-zA-Z]*$/
// const URL_PATTERN = /^http[s]?:\/\//
const URL_PATTERN = '^(http|https|ftp)://([a-zA-Z0-9.-]+(:[a-zA-Z0-9.&%$-]+)*@)*((25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0).(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])|localhost|([a-zA-Z0-9-]+.)*[a-zA-Z0-9-]+.(com|edu|gov|int|mil|net|org|biz|arpa|info|name|pro|aero|coop|museum|[a-zA-Z]{2}))(//:[0-9]+)*(/($|[a-zA-Z0-9//./,?\'\\+&%$#=~_-]+))*$'
const RTSP_PATTERN = "((rtsp)://)(([a-zA-Z0-9._-]+.[a-zA-Z]{2,6})|([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}))(:[0-9]{1,4})*(/[a-zA-Z0-9&%_./-~-]*)?";
const RTMP_PATTERN = "((rtmp)://)(([a-zA-Z0-9._-]+.[a-zA-Z]{2,6})|([0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}.[0-9]{1,3}))(:[0-9]{1,4})*(/[a-zA-Z0-9&%_./-~-]*)?";
const VIDEO_PATTERN = /(.*?\.)+(swf|avi|flv|mpg|mov|wav|3gp|mkv|rmvb|mp3|mp4|ts)/;
const PHOTO_PATTERN = /^\/(\w+\/?)+$/;
const USB_PATTERN= /^\/(\w+\/?)+$/;
const GB28181_PATTERN = "gb28181://";
const COMMON_PREFIX = "/SophonFace/api";
const SOPHON_CONF_PREFIX = "/SophonGate/api";
const SOPHON_CONFIG = "/SophonConfig/api/Configs"
const SOPHON_GATE_PREFIX = "/SophonGate/api";
const SOPHON_FACE_CAP_CAM = "/SophonFaceCapCam/api";
const SOPHON_IPC = "/SophonIPC/api";
const SOPHON_SYSTEM = "/SophonSystem/api";
const SOPHON_WEL = "/SophonWelTV/api";
const SOPHON_UTIL = "/SophonUtil/api";
const SOPHON_FACE = "/SophonFace/api";
const SOPHON_EASY_FACE = "/SophonEasyFace/api";
const SOPHON_FOG_SYS = "/SophonFogSys/api"
const STATIC_PREFIX = "/static/oem"
const SOPHON_MQ = "SophonMQ/api";
const version_rule = /^V\d{1}R{1}C{2}$/
// const LOCAL_IP = async ()=>{
//     return (await axios.get(`${SOPHON_SYSTEM}/ip`)).data.data||'';
// }
export { URL_PATTERN }
export { TYPE_MAPPING, LOCAL_IP, DEVICE_TYPE,USER_TYPE1, FACE_CAP_CAM_TYPE_MAPPING,USER_TYPE }
export { version_rule }
export { IP_PATTERN, PORT_PATTERN,GB28181_PATTERN,RTSP_PATTERN,RTMP_PATTERN,VIDEO_PATTERN,PHOTO_PATTERN,USB_PATTERN,IP_PATTERN_BLUR, HOSTNAME_PATTERN }
export { COMMON_PREFIX, SOPHON_CONF_PREFIX, SOPHON_GATE_PREFIX, SOPHON_FACE_CAP_CAM, SOPHON_IPC, SOPHON_SYSTEM, SOPHON_WEL, SOPHON_UTIL, SOPHON_FACE, SOPHON_EASY_FACE, SOPHON_FOG_SYS,SOPHON_MQ,SOPHON_CONFIG }
export { STATIC_PREFIX }
export {uuid}

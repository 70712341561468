import React, { Component } from 'react'
import { DatePicker, Form, Button, Popover, message, Input, Switch, Radio, Row, Col, Upload, Select, Spin } from 'antd'
import axios from '../../utils/AxiosWrapper'
import { COMMON_PREFIX, USER_TYPE, USER_TYPE1, SOPHON_FACE, SOPHON_FOG_SYS } from '../../utils/Config'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { fixImage } from '../../utils/ImageUtils';
import photoconfig from '../../imgaes/photoconfig.png';
import moment from 'moment'
const conSpan = 8;
function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error(intl.formatMessage({ id: 'event.searchImage.form.upload.type' }), 1.5)
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error(intl.formatMessage({ id: 'event.searchImage.form.upload.size' }), 1.5)
    }
    return isJpgOrPng && isLt5M;
}
const prefix = COMMON_PREFIX;
const content = localStorage.getItem('lang') === 'en' ? <div>
    <section>
        <h3>Mac computer solutions are as follows:</h3>
        <p>
            Enter chrome://flags/ in the address bar, search unsafely enabled and fill in the domain name to be trusted.
        </p>
        <img width={500} alt="tips" src={photoconfig} />
        <p> Click the Relaunch Now button at the bottom to take effect</p>
    </section>
    <section>
        <h3> The window computer solution is as follows:</h3>
        <div> chrome shortcut right-click--attributes--target, add:</div>
        <div>--unsafely-treat-insecure-origin-as-secure="http://host||ip" (--`'' before the input)</div>
    </section>
</div> : <div>
        <section>
            <h3>mac电脑解决方案如下：</h3>
            <p>
                地址栏输入chrome://flags/, 搜索unsafely
                enabled 并填入要授信的域名。
    </p>
            <img width={500} alt="tips" src={photoconfig} />
            <p> 点击最下方的Relaunch Now按钮，生效</p>
        </section>
        <section>
            <h3> window 电脑解决方案如下：</h3>
            <div> chrome快捷方式右键--属性--目标,输入框中追加:</div>
            <div>--unsafely-treat-insecure-origin-as-secure="http://host||ip" (--```前加空格)</div>
        </section>
    </div>

class TakePhoto extends Component {
    state = {
        stream: undefined,
    }
    addVideo = null;
    addCanvas = null;
    addImg = null;
    componentDidMount() {
        const _this = this;
        if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
            navigator.mediaDevices.getUserMedia({
                video: { width: { exact: 320 }, height: { exact: 240 } },
                audio: false
            }).then(function (stream) {
                let video = _this.addVideo;
                if ("srcObject" in video) {
                    video.srcObject = stream;
                } else {
                    video.src = window.URL.createObjectURL(stream);
                }

                video.onloadedmetadata = function (e) {
                    video.play();

                };
                _this.setState({ stream: stream });

            }).catch(function (e) {
                message.error(intl.formatMessage({ id: 'user.add.camera.error' }), 1);

            });

        } else {
            alert('no devices')
        }
        let image = this.addImg;
        image.onload = function () {
            _this.addCanvas.getContext('2d').drawImage(image, 0, 0, 320, 240);
        }

    }
    closeVideo = () => {
        try {
            const track = this.state.stream.getTracks()[0];
            track.stop();
        } catch (err) {
            console.log(err);
        }
    }
    componentWillUnmount() {
        //关闭摄像头视频流
        this.closeVideo();
    }
    getSnapShotForUser = () => {
        let video = this.addVideo;
        if (video.srcObject == null || video.src == null) {
            message.error(intl.formatMessage({ id: 'user.add.camera.error' }), 1);
            return
        }
        if (this.props.hasImage) {
            this.props.getImage(undefined);
            return
        }
        let canvas = this.addCanvas;
        let img = this.addImg;
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 0, 0, 320, 240);
        const image_base64 = canvas.toDataURL();
        img.src = image_base64;
        this.props.getImage(image_base64);
    }
    render() {
        return (
            <div>
                <div>
                    <div style={{ float: 'left', width: 320, height: 240, backgroundColor: '#FFFFFF', textAlign: 'center' }}>
                        <video ref={el => this.addVideo = el} autoPlay={true} width='320' height='240' style={{ display: this.props.hasImage ? 'none' : '' }}>
                            <FormattedMessage id='user.add.camera.error' />
                        </video>

                        <canvas ref={el => this.addCanvas = el}  style={{ display: !this.props.hasImage ? 'none' : '' }} width={320} height={240}>
                            <FormattedMessage id='user.add.camera.canvas' />
                        </canvas>

                    </div>
                    <div style={{ float: 'left', marginTop: 192, marginLeft: 20, width: 64, height: 48, backgroundColor: '#FFFFFF', textAlign: "center" }}>
                        <img alt='' style={{ width: '100%' }} src={this.props.initialImage} ref={el => this.addImg = el}></img>
                    </div>
                    <div style={{ clear: 'both' }}></div>
                </div>
                <div style={{ height: 55, lineHeight: '55px', textAlign: 'center' }}>
                    <Button icon='camera-o' disabled={!this.state.stream || this.props.isDisabled} type='dashed' onClick={this.getSnapShotForUser}>{this.props.hasImage ? (<FormattedMessage id='user.add.remake' />) : (<FormattedMessage id='user.add.takePhoto' />)}</Button>
                </div>
            </div>
        )
    }
}

class SelectPhoto extends Component {
    state = {
        hasPhoto: false,
        loadingImage: false
    }
    addImgProfile = null;
    addImg = null;
    fixImage = (e) => {
            const image = e.target;
            if (image.width / image.height > 320 / 240) {
                if (this.addImgProfile) {
                    this.addImgProfile.style.width = '100%'
                }
            } else {
                if (this.addImgProfile) {
                    this.addImgProfile.style.height = '100%'
                }
            }
        }
    handleFileSelect = (obj) => {
        this.setState({ loadingImage: true })
        let file = obj.file;
        if (file.type.toLowerCase().indexOf('image') === -1) {
            message.error(intl.formatMessage({ id: 'user.add.selectPhoto.image.error' }), 1);
            return;
        }
        fixImage(file).then(
            (base64ImageStr) => {
                this.setState({ loadingImage: false })
                if (base64ImageStr) {
                    //判断图片长宽分辨率
                    const image = new Image();
                    image.src = base64ImageStr;
                    image.onload = () => {
                        let _width = image.width;
                        let _height = image.height;
                        if (_width > 4096 || _height > 4096) {
                            message.error(intl.formatMessage({ id: 'user.add.selectPhoto.image.large' }), 1);
                            return
                        }
                        let rate = _width / _height;
                        //宽高比大于默认的320/240 限制页面图片宽度为320
                        if (rate > (320 / 240)) {
                            _height = 320 / rate;
                            _width = 320;
                        } else {
                            _width = 240 * rate;
                            _height = 240;
                        }
                        this.addImgProfile.style = `width:${_width}px;height:${_height}px`;
                        this.addImg.src = this.addImgProfile.src = base64ImageStr;
                        this.props.getImage(base64ImageStr);
                    }
                } else {
                    message.error(intl.formatMessage({ id: 'user.add.selectPhoto.image.error' }), 1);
                    return;
                }

            }
        );

    }
    render() {
        return (
            <div>
                <Spin spinning={this.state.loadingImage}>
                    <div>
                        <div  className="flex flex-center flex-align-center" style={{ float: 'left', width: 320, height: 240, backgroundColor: '#FFFFFF', textAlign: 'center' }}>
                            <img alt='' onLoad={this.fixImage} src={this.props.initialImage} style={{ maxWidth: '100%', maxHeight: '100%' }} ref= {el => this.addImgProfile = el}></img>
                        </div>
                        <div className="flex flex-center flex-align-center" style={{ float: 'left', marginTop: 192, marginLeft: 20, width: 64, height: 48, backgroundColor: '#FFFFFF', textAlign: 'center' }}>
                            <img alt='' src={this.props.initialImage} style={{ maxWidth: '100%', maxHeight: '100%' }} ref={el =>this.addImg = el}></img>
                        </div>
                        <div style={{ clear: 'both' }}></div>
                    </div>
                </Spin>
                <div style={{ height: 55, lineHeight: '55px', textAlign: 'center' }}>
                    <Upload customRequest={this.handleFileSelect}  beforeUpload={beforeUpload}  showUploadList={false}>
                        <Button icon='upload' disabled={this.props.isDisabled} type='dashed'>{this.props.hasImage ? (<FormattedMessage id='user.add.reupload' />) : (<FormattedMessage id='user.add.upload' />)}</Button>
                    </Upload>

                </div>
            </div>
        )
    }
}

class UserAddFrom extends Component {
    state = {
        hasMore: (this.props.entry && this.props.entry.info && this.props.entry.info.ext_info && this.props.entry.info.ext_info.hasMore) || false,
        initialImage: (this.props.entry && this.props.entry.info) ? `${prefix}/images/${this.props.entry.info.image_id}?t=${Math.random() * 100}` : '',
        image: (this.props.entry && this.props.entry.info) ? `${prefix}/images/${this.props.entry.info.image_id}?t=${Math.random() * 100}` : '',
        photoMothed: 'select',
        groups: [],
        permissions: [],
        uploading: false,
        // image: undefined
    }
    updateUser = async (info,group_name) => {
        let group_ids = [this.state.groups.filter(item => item.info.groupname === group_name)[0]._id]
        let image_id = this.props.entry.info.image_id || '';
        let index = this.state.initialImage.indexOf('?');
        let faces = (await axios.get(`${prefix}/identities/${this.props.entry._id}`)).data.data.face_ids;
        if (this.props.entry._id !== info['identity_id']) { //更改了userId
            const isExisted = (await axios.get(`${prefix}/identities?info.user_id=${info['identity_id']}&exact=${true}`)).data.data.length > 0;
            if (isExisted) {
                message.warning(intl.formatMessage({ id: 'user.add.userID.existed' }), 1);
                return false;
            }
        }
        if (this.state.image.substring(0, index) !== this.state.initialImage.substring(0, index)) {

            const response_faces = (await axios.put(`${prefix}/faces/${faces[0]}`, { image_base64_str: this.state.image, identity_id: this.props.entry._id, group_ids }).catch(e => {
                if (e.response.data && e.response.data.error) {
                    const response_msg = e.response.data.error;
                    if (localStorage.getItem('lang') === 'en') {
                        message.error(`${response_msg.message}`, 1);
                    } else {
                        message.error(`${response_msg.chinese_message}`, 1);
                    }

                } else {
                    message.error(intl.formatMessage({ id: 'add.error' }), 1);
                }
            }
            ));
            if (response_faces) {
                image_id = response_faces.data.data.image_id;
            } else {
                return false;// 图片质量不合格
            }
        }
        info.image_id = image_id || '';
        info.face_ids = faces;
        axios.put(`${COMMON_PREFIX}/identities/${this.props.entry._id}`, { "info": info, "groups_id": group_ids }).then(res => {
            this.props.onSuccess();
        }).catch(
            (e) => {
                if (e.response.data && e.response.data.error) {
                    const response_msg = e.response.data.error;
                    if (localStorage.getItem('lang') === 'en') {
                        message.error(`${response_msg.message}`, 1);
                    } else {
                        message.error(`${response_msg.chinese_message}`, 1);
                    }
                } else {
                    message.error(intl.formatMessage({ id: 'edit.error' }), 1);
                }
            });
    }
    registUser = async (userData) => {
        //直接使用SophonFog api 的接口
        const response = (await axios.post(`${SOPHON_FOG_SYS}/users`, userData).catch(
            (e) => {
                if (e.response.data && e.response.data.error) {
                    const response_msg = e.response.data.error;
                    if (localStorage.getItem('lang') === 'en') {
                        message.error(`${response_msg.message}`, 1);
                    } else {
                        message.error(`${response_msg.chinese_message}`, 1);
                    }

                } else {
                    message.error(intl.formatMessage({ id: 'add.error' }), 1);
                }

            }
        ));
        if (response) {
            // message.success(intl.formatMessage({ id: 'info.added' }), 1);
            this.props.onSuccess();
            // this.props.history.push('/User/UserList');
        }

    }
    submitImage = (e) => {
        e.preventDefault();
        const { image } = this.state;
        this.props.form.validateFields((err, values) => {
            if (!err && image) {
                if (+values.endTime < +values.startTime) {
                    message.error(intl.formatMessage({ id: 'user.add.startTime.error' }), 1);
                    return false;
                }
                this.setState({ uploading: true });
                const userData = {
                    reg_photo: image,
                    identity_id: values.UserID,
                    group_name: values.Group,
                    info: {
                        // user_id: values.UserID,
                        name: values.Name,
                        type: parseInt(values.type),
                        permission_ids: values.permission_ids,
                        ext_info: {
                            description: values.description || '',
                            visit_card_number: values.visit_card_number || '',
                            hasMore: this.state.hasMore,
                            //非必填
                            mobile: values.mobile,
                            email: values.email,
                            idNumber: values.idNumber,
                            gender: values.gender,
                            birthday: values.birthday,
                            jobNumber: values.jobNumber,
                            department: values.department,
                            entryTime: values.entryTime,
                            title: values.title,
                            address: values.address,
                            floor: values.floor,
                            building_no: values.building_no,
                            room: values.room,
                            remark: values.remark,
                            startTime: values.startTime,
                            endTime: values.endTime,
                            purpose: values.purpose,
                            interviewee: values.interviewee,    //受访人
                            comeFrom: values.comeFrom,     //受访单位
                            message: values.message  //欢迎语
                        }
                    }
                }
                if (this.props.entry && this.props.entry._id) {
                    //编辑
                    this.updateUser(userData.info,userData.group_name);
                } else { //新增
                    this.registUser(userData);
                }
                this.setState({ uploading: false })
            } else {
                if (!err && !this.state.image) {
                    message.warning(intl.formatMessage({ id: 'user.add.image.null' }));
                }
            }
        });
    }
    getImage = (image) => {
        this.setState({ image: image })
    }
    handleShowMore = (checked) => {
        this.setState({
            hasMore: checked
        })
    }
    onChangePhotoMothed = (e) => {
        this.setState({
            photoMothed: e.target.value
        })
    }
    componentDidMount() {
        axios.get(`${prefix}/groups`).then(
            (resp) => {
                this.setState({
                    groups: resp.data.data
                })
                if (this.props.entry._id) {
                    axios.get(`${prefix}/identities/${this.props.entry._id}?with_group_id=true`).then((res) => {
                        let group_ids = res.data.data.group_ids[0];
                        let groupName = null;
                        resp.data.data.forEach((item) => {
                            if (item._id === group_ids) {
                                groupName = item.info.groupname;
                            }
                        })

                        this.setState({
                            groupName,
                            group_ids
                        })

                    }) // 获取 groupName
                }
            }
        )
        axios.get(`${SOPHON_FACE}/permissions`).then(
            (resp) => {
                this.setState({
                    permissions: resp.data.data
                })
            }
        )
    }
    clearForm = () => {
        this.props.form.resetFields();
        this.setState({ image: undefined })
    }
    handleUserType = (type) => {
        if(type.toString()!=='4'){
            this.props.form.setFieldsValue({ 'Group': 'default_group' })
        }
    }
    render() {
        const entry = this.props.entry.info || {};
        const _id = this.props.entry._id;
        const disabled = this.props.entry.disable ? this.props.entry.disable : false;
        const { getFieldDecorator } = this.props.form;
        let ext_info = entry.ext_info ? entry.ext_info : {};
        const moreItem = (
            <div className="more-item">
                <Row>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.mobile' /></span>
                        <Form.Item>
                            {getFieldDecorator('mobile', {
                                initialValue: ext_info.mobile ? ext_info.mobile : '',
                                rules: [
                                    { pattern: /^1[34578]\d{9}$/, message: <FormattedMessage id='user.add.form.mobile.pattern' /> }
                                ],

                            })(<Input maxLength={11} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.email' /></span>
                        <Form.Item>
                            {getFieldDecorator('email', {
                                rules: [{
                                    type: 'email', message: <FormattedMessage id='user.add.form.email.pattern' />,
                                }],
                                initialValue: ext_info.email ? ext_info.email : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.idNumber' /> </span>
                        <Form.Item>
                            {getFieldDecorator('idNumber', {
                                initialValue: ext_info.idNumber ? ext_info.idNumber : '',
                                rules: [
                                    { pattern: /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/, message: <FormattedMessage id='user.add.form.idNumber.pattern' /> }
                                ],
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.gender' /></span>
                        <Form.Item>
                            {getFieldDecorator('gender', {
                                initialValue: ext_info.gender ? ext_info.gender : ''
                            })(<Radio.Group disabled={disabled}>
                                <Radio value='female'><FormattedMessage id='user.add.form.gender.female' /></Radio>
                                <Radio value='male'><FormattedMessage id='user.add.form.gender.male' /></Radio>
                            </Radio.Group>)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.list.form.visitCardNumber' /></span>
                        <Form.Item>
                            {getFieldDecorator('visit_card_number', {
                                initialValue: ext_info.visit_card_number ? ext_info.visit_card_number : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id={this.props.theme === 'PARK' ? 'user.add.form.jobNumber' : 'user.add.form.studentId'} /></span>
                        <Form.Item>
                            {getFieldDecorator('jobNumber', {
                                initialValue: ext_info.jobNumber ? ext_info.jobNumber : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.title' /></span>
                        <Form.Item>
                            {getFieldDecorator('title', {
                                initialValue: ext_info.title ? ext_info.title : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.department' /></span>
                        <Form.Item>
                            {getFieldDecorator('department', {
                                initialValue: ext_info.department ? ext_info.department : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.entryTime' /></span>
                        <Form.Item>
                            {getFieldDecorator('entryTime', {
                                initialValue: ext_info.entryTime ? moment(ext_info.entryTime, 'YYYY/MM/DD') : undefined
                            })(<DatePicker disabled={disabled} style={{ width: "100%" }} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.address' /></span>
                        <Form.Item>
                            {getFieldDecorator('address', {
                                initialValue: ext_info.address ? ext_info.address : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.building_no' /></span>
                        <Form.Item>
                            {getFieldDecorator('building_no', {
                                initialValue: ext_info.building_no ? ext_info.building_no : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>

                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.floor' /></span>
                        <Form.Item>
                            {getFieldDecorator('floor', {
                                initialValue: ext_info.floor ? ext_info.floor : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.room' /></span>
                        <Form.Item>
                            {getFieldDecorator('room', {
                                initialValue: ext_info.room ? ext_info.room : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan * 2}>
                        <span><FormattedMessage id='user.list.form.description' /></span>
                        <Form.Item
                            wrapperCol={{ span: 23 }}>
                            {getFieldDecorator('description', {
                                initialValue: ext_info.description ? ext_info.description : ''
                            })(<Input.TextArea rows={2} disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <span style={{ fontSize: 12 }}>(<FormattedMessage id='user.add.form.visitor.tips' />)</span>
                <Row>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.startTime' /></span>
                        <Form.Item>
                            {getFieldDecorator('startTime', {
                                initialValue: ext_info.startTime ? moment(new Date(ext_info.startTime), 'YYYY/MM/DD HH:mm') : undefined
                            })(<DatePicker disabled={disabled} style={{ width: "100%" }} disabledDate={current => { return current < +new Date(moment().format('YYYY/MM/DD HH:mm')) }} showTime />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.endTime' /></span>
                        <Form.Item>
                            {getFieldDecorator('endTime', {
                                initialValue: ext_info.endTime ? moment(new Date(ext_info.endTime), 'YYYY/MM/DD HH:mm') : undefined
                            })(<DatePicker disabled={disabled} style={{ width: "100%" }} disabledDate={current => { return current < +new Date(moment().format('YYYY/MM/DD HH:mm')) }} showTime />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.purpose' /></span>
                        <Form.Item>
                            {getFieldDecorator('purpose', {
                                initialValue: ext_info.purpose ? ext_info.purpose : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.interviewee' /></span>
                        <Form.Item>
                            {getFieldDecorator('interviewee', {
                                initialValue: ext_info.interviewee ? ext_info.interviewee : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.comeFrom' /></span>
                        <Form.Item>
                            {getFieldDecorator('comeFrom', {
                                initialValue: ext_info.comeFrom ? ext_info.comeFrom : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span><FormattedMessage id='user.add.form.message' /></span>
                        <Form.Item>
                            {getFieldDecorator('message', {
                                initialValue: ext_info.message ? ext_info.message : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                </Row>
            </div>
        )
        let NEW_USER_TYPE = null;
        if (this.props.theme === 'PARK') {
            NEW_USER_TYPE = USER_TYPE;
        } else {
            NEW_USER_TYPE = USER_TYPE1;
        }
        return (
            <Form onSubmit={this.submitImage}
                wrapperCol={{ span: 22 }} className="employeeForm">
                <div style={{ borderBottom: "1px solid #ddd", paddingBottom: 8 }}>
                    <span className="title-h4"><FormattedMessage id='user.add.form.photo' /></span><Radio.Group disabled={disabled} onChange={this.onChangePhotoMothed} size="small" value={this.state.photoMothed}>
                        <Radio.Button value="select"><FormattedMessage id='user.add.form.uploadMethod' /></Radio.Button>
                        <Radio.Button value="take"><FormattedMessage id='user.add.form.shootmethod' /></Radio.Button>
                        {
                            !disabled ? <Popover content={content} title={<FormattedMessage id='user.add.form.take.problem.title' />}>
                                <span className="cursor">  (<FormattedMessage id='user.add.form.take.problem' />)</span>
                            </Popover> : ''
                        }

                    </Radio.Group></div>
                <div style={{ backgroundColor: 'RGBA(247, 247, 249, 1)' }}>
                    <div style={{
                        width: 500, padding: '20px', margin: '0 auto', marginBottom: 10, height: 320
                    }}>
                        {
                            this.state.photoMothed === 'take' ? (<TakePhoto isDisabled={disabled} getImage={this.getImage} hasImage={!!this.state.image} initialImage={this.state.image} />)
                                : (<SelectPhoto getImage={this.getImage} isDisabled={disabled} initialImage={this.state.image} hasImage={!!this.state.image} />)
                        }
                    </div>
                </div>
                <p className="title-h4" style={{ borderBottom: "1px solid #ddd", paddingBottom: 8 }}><FormattedMessage id='user.add.info.user' /></p>
                <Row>
                    <Col span={conSpan}>
                        <span className="required"><FormattedMessage id='user.list.form.name' /></span>
                        <Form.Item>
                            {getFieldDecorator('Name', {
                                rules: [{
                                    required: true, message: <FormattedMessage id='input.required' />
                                }, {
                                    pattern: /[\S]/, message: <FormattedMessage id='user.add.input.blank' />
                                }],
                                initialValue: entry.name ? entry.name : ''
                            })(<Input disabled={disabled} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span className="required"><FormattedMessage id='user.list.form.userID' /></span>
                        <Form.Item>
                            {getFieldDecorator('UserID', {
                                rules: [
                                    {
                                        required: true, message: <FormattedMessage id='input.required' />
                                    },
                                    {
                                        pattern: /[\S]/, message: <FormattedMessage id='user.add.input.blank' />
                                    }],
                                initialValue: _id ? _id : '',
                            })(<Input disabled={disabled || (_id ? true : false)} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span className="required"><FormattedMessage id='user.list.form.userType' /></span>
                        <Form.Item>
                            {getFieldDecorator('type', {
                                rules: [
                                    {
                                        required: true, message: <FormattedMessage id='input.required' />
                                    }],
                                initialValue: entry.type ? entry.type.toString() : '',
                            })(
                                <Select onChange={this.handleUserType} disabled={disabled}>
                                    {NEW_USER_TYPE.map((e) => <Select.Option key={e.typeId} disabled={e.disabled}><FormattedMessage id={`user.add.type.${e.description}`} /></Select.Option>)} 
                                </Select>
                            )}
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={conSpan}>
                        <span className="required"><FormattedMessage id='user.list.form.group' /></span>
                        <Form.Item>
                            {getFieldDecorator('Group', {
                                rules: [{
                                    required: true, message: <FormattedMessage id='input.required' />
                                }],
                                initialValue: this.state.groupName ? this.state.groupName : this.state.groups[0] && this.state.groups[0].info.name
                            })(<Input disabled={true} />)}
                        </Form.Item>
                    </Col>
                    <Col span={conSpan}>
                        <span className="required"><FormattedMessage id='sidemenu.User.PermissionGroup' /></span>
                        <Form.Item>
                            {getFieldDecorator('permission_ids', {
                                rules: [{
                                    required: true, message: <FormattedMessage id='input.required' />
                                }],
                                initialValue: entry.permission_ids ? entry.permission_ids : []
                            })(<Select
                                disabled={disabled}
                                mode='multiple'>
                                {this.state.permissions.map(
                                    (e) => {
                                        if (e._id) {
                                            return (<Select.Option key={e._id}>{e.info.name}</Select.Option>)
                                        } else {
                                            return false;
                                        }
                                    }
                                )}
                            </Select>)}
                        </Form.Item>
                    </Col>
                </Row>
                <p className="title-h4"><FormattedMessage id='user.add.form.moreConfig' />: <Switch disabled={disabled} defaultChecked={ext_info.hasMore} onChange={this.handleShowMore}></Switch></p>
                {this.state.hasMore ? moreItem : ''}
                {!disabled ? <Form.Item style={{ textAlign: 'center', paddingTop: 20 }}>
                    <Button type='primary' htmlType='submit' loading={this.state.uploading}><FormattedMessage id='button.save' /></Button>
                    <div style={{ width: 20, display: 'inline-block' }}></div>
                    {!_id ? <Button onClick={this.clearForm}><FormattedMessage id='button.clear' /></Button> : null}

                </Form.Item> : null}

            </Form>
        )
    }
}

export default Form.create()(UserAddFrom)
import React, { Component } from 'react';
import { Radio, Switch, message } from 'antd';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { getLteModem, setLteModem, getLteModemSelection, setLteModemSelection, getLteModemConnection } from '../../services/system';
class CellularNetwork extends Component {
    state = {
        hasSignal: undefined,
        startUp: undefined,
        selection: undefined,
        getSignaling: false
    }
    pingCount = 0
    componentDidMount() {
        this.getCurrentStatus();
    }
    getCurrentStatus = () => {
        getLteModem().then(res => {
            if (res.data.service === 'active') { //开启4G功能
                this.setState({
                    startUp: true
                })
                this.checkStatus();
                setTimeout(() => {
                    getLteModemSelection().then(res => {
                        this.setState({
                            selection: res.data.selection
                        })
                    })
                }, 5000)
            } else {
                this.setState({
                    startUp: false,
                    hasSignal: undefined,
                    selection: undefined
                })
            }
        })
    }
    // checkStatus = () => {
    //     this.setState({
    //         getSignaling : true
    //     }) 
    //     setTimeout(() => {
    //         let hasSignal = undefined;

    //         getLteModemConnection().then(res => {
    //             // res.data.connected = true;
    //             // res.data.simstatus = true; //测试数据
    //             if (res.data.connected) {
    //                 hasSignal = "connected";
    //             } else {
    //                 if (res.data.simstatus) {
    //                     hasSignal = 'noSignal';

    //                 } else {
    //                     hasSignal = 'noCard'
    //                 }
    //             }
    //             this.setState({
    //                 hasSignal: hasSignal,
    //                 getSignaling : false
    //             })
    //         })
    //     }, 8000);
    // }
    checkStatus = () => {
        this.setState({
            getSignaling: true //获取信号中，等待5秒
        })
        this.pingCount = 0;
        let pingfun = (parma) => { //循环ping的函数
          this.timer =   setTimeout(() => {
                this.setState({
                    hasSignal: undefined
                })
                let hasSignal = undefined;
                getLteModemConnection().then(res => {
                    if (res.data.connected) {
                        hasSignal = "connected"; //已连接无需ping
                    } else {
                        if (res.data.simstatus) {
                            hasSignal = 'noSignal'; //再次ping
                        } else {
                            hasSignal = 'noCard'; //没插卡无需ping
                        }
                    }
                    this.setState({
                        hasSignal: hasSignal,
                        getSignaling: false
                    }, () => {
                        if (hasSignal === 'connected') {
                            this.pingCount = 0;//状态确定
                        }else{ 
                            if(this.pingCount < 4){
                                this.pingCount++;
                                // console.log("继续ping",this.pingCount);
                                pingfun(this.pingCount);
                            }
                        }
                    })
                   ;
                })
            }, 1000)
        }
        setTimeout(() => {
            pingfun();
        }, 4000);
    }
    handleSwitch = (value) => { 
        this.pingCount = 5; //立即停止现在ping
        let service = null;
        if (value) {
            service = 'active'
        } else {
            service = 'inactive'
        }
        this.setState({
            getSignaling: true
        })
        setLteModem({ service: service }).then(res => {
            this.setState({
                startUp: value,
                getSignaling: false
            })
            message.success(intl.formatMessage({ id: 'info.success' }), 1);
            this.getCurrentStatus();
        }).catch(() => {
            message.error(intl.formatMessage({ id: 'info.error' }), 1)
        })
    }
    handleType = (e) => {
        this.pingCount = 5; //立即停止现在ping
        let data = e.target.value;
        this.setState({
            hasSignal: undefined
        })
        this.setState({
            selection: data
        })
        setLteModemSelection({ selection: data }).then(res => {
            this.setState({
                selection: data
            })
            message.success(intl.formatMessage({ id: 'info.success' }), 1);
            this.checkStatus();
        }).catch(() => {
            message.error(intl.formatMessage({ id: 'info.error' }), 1)
        })
    }
    render() {
        if (this.state.startUp === undefined) {
            return false;
        }
        let selectType = null;
        let hasSignalDom = null;
        if (this.state.startUp === true) {
            selectType = <div>
                <h3 style={{ marginTop: 30, borderBottom: '1px solid #ddd' }}><FormattedMessage id='setting.system.cellular.rareFlow.connectType' />
                    <span style={{ fontSize: 14, color: 'rgba(0,0,0,0.4)' }}>{!this.state.selection ? <FormattedMessage id='setting.system.cellular.connectType.check' /> : ''}</span>
                </h3>

                <Radio.Group onChange={this.handleType} value={this.state.selection}>
                    <Radio disabled={this.state.getSignaling} style={{ display: 'block', height: '30px', lineHeight: '30px', fontWeight: 700 }} value={'ltefirst'}><FormattedMessage id='setting.system.cellular.rareFlow.connectType.flow' /></Radio>
                    <span className="tips-text"><FormattedMessage id='setting.system.cellular.rareFlow.connectType.flow.tips' /></span>
                    <Radio disabled={this.state.getSignaling} style={{ display: 'block', marginTop: 10, height: '30px', lineHeight: '30px', fontWeight: 700 }} value={'wanfirst'}><FormattedMessage id='setting.system.cellular.rareFlow.connectType.broadband' /></Radio>
                    <span className="tips-text"><FormattedMessage id='setting.system.cellular.rareFlow.connectType.broadband.tips' /></span>
                </Radio.Group>
            </div>
            hasSignalDom = <div style={{ textAlign: 'right', height: 20 }} >
                <span style={this.state.hasSignal === undefined ? {} : (this.state.hasSignal === 'connected' ? { color: "rgb(88,190,107)" } : { color: 'red' })} >
                    {this.state.hasSignal ? <FormattedMessage id={'setting.system.cellular.rareFlow.' + this.state.hasSignal} /> : ''}
                    {this.state.hasSignal === undefined ? <FormattedMessage id='setting.system.cellular.rareFlow.signalCheck' /> : (this.state.hasSignal === 'connected' ? <span className="icon iconfont icon-xinhao"></span> : <span className="icon iconfont icon-wuxinhao"></span>)}
                </span>

            </div>
        }
        return (
            <div style={{ width: 420, margin: '0 auto' }}>
                {hasSignalDom}
                <div>
                    <strong>
                        <FormattedMessage id='setting.system.cellular.rareFlow' />
                        <Switch disabled={this.state.getSignaling} defaultChecked={this.state.startUp} onChange={this.handleSwitch} />
                    </strong>
                    <section className="tips-text">
                        <FormattedMessage id='setting.system.cellular.rareFlow.tips' />
                    </section>
                </div>
                {selectType}
            </div>
        )
    }
}

export default CellularNetwork
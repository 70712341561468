export default {
    "config.ipc.play": "播放",
    "config.ipc.pause": "暂停",
    "config.ipc.suspend": "暂停中",
    "config.ipc.load": "加载中",
    "config.ipc.noVideo": "暂无视频",
    "config.ipc.fullScreen": "全屏",
    "config.ipc.dataList": "数据列表",
    "config.ipc.table.matchPhoto": "匹配照片",
    "config.ipc.table.livePhoto": "现场照片",
    "config.ipc.table.time": "时间",
    "config.ipc.table.room": "房间号",
    "config.ipc.table.userType": "类型",
    "config.detail.device.status": "状态",
    "config.detail.device.online": "在线",
    "config.detail.device.offline": "离线",

    "config.detail.groupID": "用户组ID: {groupID}",
    "config.detail.title": "{title} ",
    "config.detail.ip": "IP: {IP}",
    "config.detail.type": "设备类型: {type}",
    "config.detail.detailButton": "详情",
    "config.detail.deleteButton": "删除",
    "config.addButton": "添加",
    "config.addButtonManully": "手动添加",
    "config.add.refresh": "刷新",
    "config.detail.deleteTips": "确认删除这台设备吗？",
    "config.detail.deleteContent": "您将不会收到该设备的人脸信息.",
    "config.detail.deleteError": "设备删除失败",
    "config.add.confirm": "确定添加选中的设备吗?",
    "config.add.table.title.gate": "扫描到的闸机IP",
    "config.add.form.gate.name": "名称",
    "config.add.form.gate.groupID": "人员底库",
    "config.add.form.fast_mode.Speed": "速度优先",
    "config.add.form.fast_mode.Accuracy": "准确度优先",
    "config.add.form.fast_mode.mode": "模式",
    "config.add.form.gate.ip": "设备IP",
    "config.video.preview.pageNext": "下一页没有更多视频",
    "config.video.preview.pagePre": "上一页没有更多视频",

    "config.video.face.attr.age.child" : '儿童',
    "config.video.face.attr.age.juvenile" : '少年',
    "config.video.face.attr.age.youth" : '青年',
    "config.video.face.attr.age.middle-aged" : '中年',
    "config.video.face.attr.age.aged" : '老年',
    "config.video.face.attr.gender.male" : '男',
    "config.video.face.attr.gender.female" : '女',
    "config.video.face.attr.glasses.yes" : '是',
    "config.video.face.attr.glasses.no" : '否',

    "config.add.form.device_name": "名称",
    "config.add.form.device_key": "地址",
    "config.add.form.group": "人员分组",
    "config.add.form.serial_number": "序号",
    "config.add.form.protocol": "协议",
    "config.add.form.permission": "权限组",
    "config.add.form.device_attr": "模块使能",
    "config.add.form.fast_mode": "识别模式",
    "config.add.form.netRelay": "继电器配置",
    "config.add.form.moreConfig": "高级配置",
    "config.add.form.netRelay.ip": "地址",
    "config.add.form.netRelay.door_port": "端口",
    "config.add.form.netRelay.delay_time": "动作复位延迟",
    "config.add.form.url.error.patter":'输入的格式不正确',
    "config.add.form.gate.ipAdded": "此IP已经添加过",
    "config.add.form.gate.type": "设备类型",
    "config.add.form.Apply": "确定",
    "config.add.form.back": "返回",
    "config.add.form.capture.title": "扫描到的抓拍机IP",
    "config.add.captureCamera.tips": "确定将该抓拍机添加到白名单？",
    "config.add.captureCamera.content": "您需要在抓拍机设备端尝试主动连接这台设备",
    "config.detail.ipc.netGate": "网络继电器",
    "config.detail.ipc.netPort": "网络继电器端口",
    "config.detail.ipc.mode": "模式",
    "config.detail.ipc.netPort.value1": "通道1",
    "config.detail.ipc.netPort.value2": "通道2",
    "config.detail.ipc.sleeptime": "识别间隔",
    "config.detail.ipc.rtsp": "rtsp流",
    "config.detail.ipc.singalFace": "单人脸",
    "config.detail.ipc.mutipleFace": "多人脸",
    "config.detail.ipc.actions": "作用",
    "config.detail.ipc.actions.detect": "人脸检测",
    "config.detail.ipc.actions.feature": "特征提取",
    "config.detail.ipc.actions.match": "人脸匹配",
    "config.detail.ipc.actions.track": "人脸跟踪",
    "config.detail.ipc.actions.attribute": "属性检测",
    "config.detail.ipc.stream.displayBox": "显示人脸跟踪框",
    "config.detail.ipc.stream.box.unknown": "未知",
    "config.detail.ipc.stream.box.gender": "性别",
    "config.detail.ipc.stream.box.glasses": "眼镜",
    "config.detail.ipc.stream.box.mask": "口罩",
    "config.detail.ipc.stream.box.hat": "帽子",
    "config.detail.ipc.stream.box.age": "年龄",
    "config.detail.ipc.stream.box.expression": "表情",
    "config.detail.ipc.stream.box.camera": "设备名称:{name}",
    "config.detail.ipc.stream.box.IN": "进",
    "config.detail.ipc.stream.box.OUT": "出",
    "config.detail.ipc.stream.box.Recognized": "识别",
    "config.add.form.gate.type.manualenter.error": "手动输入的闸机类型必须为数字",
    "config.detail.ipc.stream.name": "摄像机名称",
    
    "config.add.form.rtmp.device_key": "地址",
    "config.add.form.rtmp.title": "RTMP推流",
    "config.add.form.rtmp.title.tips": "(试用功能)",
    "config.add.form.rtmp.start": "启动",

    "config.add.form.gb28181.addressRule": "地址规则",
    "config.add.form.gb28181.rule.udp1": "udp实时流地址",
    "config.add.form.gb28181.rule.udp2": "udp回放流地址",
    "config.add.form.gb28181.rule.tcp1": "tcp实时流地址",
    "config.add.form.gb28181.rule.tcp2": "tcp回放流地址",

    "config.add.form.gb28181.rule.deviceid": "前段设备20位编码",
    "config.add.form.gb28181.rule.devicetype": "录像存储累类型",
    "config.add.form.gb28181.rule.localid": "本地二十位编码，可选项",
    "config.add.form.gb28181.rule.localip": "本地IP，非必要配置。如果是docker部署，请设置为docker内部IP地址",
    "config.add.form.gb28181.rule.localsipport": "sip端口号",
    "config.add.form.gb28181.rule.localmediaport": "rtsp端口号",
    "config.add.form.gb28181.rule.begtime": "录像起始时间",
    "config.add.form.gb28181.rule.endtime": "录像结束时间"  
}
import { IP_PATTERN } from '../utils/Config';
/**
 * state example:
 * {
 *    NetWork:{ip:xxx,...},
 *    Device:{mode:xxx,device_type:xxx,ota_type:xxx},
 *    Lang:{lang:xxx}
 * }
 */
const initalState = {
    NetWork: {
        ip: IP_PATTERN.test(window.location.hostname) ? window.location.hostname : '',
    },
    Lang: {
        lang:localStorage.getItem('lang')||navigator.language.split('-')[0]||'zh'
    },
    OEM: {

    },
    mode: 'local',
    systemMode : null,
    init:null,
}

export default initalState

import React, { Component } from 'react';
import { } from 'antd'
import { FormattedMessage } from 'react-intl';
import '../face-compare.css';
import FaceUpload from './FaceUpload';
import { faceCompare } from '../../../services/match'

class Face extends Component {
    constructor(props) {
        super(props);
        this.faceImg1 = '';
        this.faceImg2 = '';
        this.state = {
            loading: false,
            result: '',
            score:0
        }
    }
    handleFace1 = (faceImg) => {
        this.faceImg1 = faceImg;
        if (this.faceImg2 && this.faceImg1) {
            faceCompare({ "card_image_base64": this.faceImg1, "face_image_base64": this.faceImg2 }).then(res => {
                this.handleClearImg();
                if(res.message){
                    let result  = localStorage.getItem('lang') === 'en' ? res.message : res.chinese_message;
                    this.setState({
                        score : res.score.toFixed(2),
                        result
                    })
                }
            }).catch(() => {
                this.handleClearImg();
            })
        }
    }
    handleFace2 = (faceImg) => {
        this.faceImg2 = faceImg;
        if (this.faceImg2 && this.faceImg1) {
            faceCompare({ "card_image_base64": this.faceImg1, "face_image_base64": this.faceImg2 }).then(res => {
                if(res.message){
                    let result  = localStorage.getItem('lang') === 'en' ? res.message : res.chinese_message;
                    this.setState({
                        score : res.score.toFixed(2),
                        result
                    })
                }
            }).catch(() => {
                this.handleClearImg();
            })
        }
    }
    handleClearImg = (postion) => {
        if(postion === 'right'){
            this.faceImg2 = ''; 
        }else if(postion === 'left'){  
            this.faceImg1 ='';
        }
        this.setState({
            result : '' ,
            score:0
        })
    }
    render() {
        return (
            <div className="compare-face" style={{ margin: "24px", padding: "24px", minHeight: '800px', "backgroundColor": "rgb(255, 255, 255)" }}>
                <h2 className='title'><FormattedMessage id='event.compare.face.title' /></h2>
                <div className="content flext-justify-center">
                    <div className="left flext-justify-btw">
                        <div className="left-face">
                            <FaceUpload getImg={this.handleFace1} clearImg= {() =>this.handleClearImg('left')}/>
                        </div>
                        <div className="right-face">
                            <FaceUpload getImg={this.handleFace2} clearImg= {() => this.handleClearImg('right')}/>
                        </div>
                    </div>
                    <div className="right">
                        <div className="title">
                            <FormattedMessage id='event.compare.result.title' />
                        </div>
                        <div className="result">
                            <p>
                               {this.state.score && this.state.score!==0 ? <FormattedMessage id='event.bar.score' values={{ value: this.state.score }} /> : ''} 
                            </p>
                            <p>
                                {this.state.result}
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Face
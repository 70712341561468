import api from '../utils/api';
const SOPHON_DEVICE = 'SophonDevice/api/Devices'
//新增设备
const addDevice = (data) => api({
    method: 'post',
    url: `${SOPHON_DEVICE}`,
    data
})

// 编辑设备
const editDevice = (data) => api({
    method: 'put',
    url: `${SOPHON_DEVICE}`,
    data
})
// 删除设备
const remoteDevice = (isClear_all, id) => api({
    method: 'delete',
    url: `${SOPHON_DEVICE}`,
    data: { data: { clear_all: isClear_all, devices_list: [{ device_id: id }] } }
})

// 获取所有的设备
const getDevices = (need_preview_list) => api({
    method: 'get',
    url: need_preview_list ? `${SOPHON_DEVICE}?need_preview_list=${true}` : `${SOPHON_DEVICE}`
}).then(res => res.data.data);

// 设置播放的设备
const setPreviewVideo = ({ preview_list, current_preview_list, preview_mode, last_set_name }) => api({
    method: 'put',
    url: `${SOPHON_DEVICE}`,
    data: {
        preview_list: {
            preview_list,
            current_preview_list,
            preview_mode,
            last_set_name
        }
    }
})

export {
    addDevice,
    editDevice,
    getDevices,
    remoteDevice,
    setPreviewVideo
}
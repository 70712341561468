import React, { Component } from 'react';
import { Form,Alert} from 'antd'
import { FormattedMessage } from 'react-intl';
import EditForm from './EditForm';
const WrapperedEditForm = Form.create()(EditForm);
class SetupLan extends Component {
	handleSubmit = (values,isReboot) => {
		this.props.history.push(
			{
				pathname: '/SetupWan'
			}
		)
	}
	render() {
		return (
			<div>
				<div style={{ margin: '18px 0' }}>
					<div><Alert message={<FormattedMessage id='setup.lan.alert' />} showIcon /></div>
				</div>
				<div style={{ backgroundColor: '#FFFFFF', padding: '48px 120px 33px', marginBottom: 242 }}>
					<WrapperedEditForm onSubmit ={this.handleSubmit} entry= 'eth1' {...this.props} />
				</div>
			</div>
		)
	}
}

export default SetupLan
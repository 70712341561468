import { STATIC_PREFIX } from "./Config";
import login_background from '../imgaes/login_background.png';
import logo_1 from '../imgaes/logo_1.png';
import logo_2 from '../imgaes/logo_2.png';
import icon from '../imgaes/icon.png';
import eth0 from '../imgaes/eth0.png';
import eth1 from '../imgaes/eth1.png';
import Axios from "axios";

const CONFIG_PATH  = `${STATIC_PREFIX}/config.json`;
const LOGINBACKGROUND_PATH = `${STATIC_PREFIX}/login_background.png`;
const LOGINLOGO_PATH = `${STATIC_PREFIX}/login_logo.png`;
const LOGO1_PATH = `${STATIC_PREFIX}/logo_1.png`;
const LOGO2_PATH = `${STATIC_PREFIX}/logo_2.png`;
const ICON_PATH = `${STATIC_PREFIX}/icon.png`;
const ETH_PATH0 = `${STATIC_PREFIX}/eth0.png`;
const ETH_PATH1 = `${STATIC_PREFIX}/eth1.png`;

const CONFIG = {
    default:{
        title:'Sensibit AI Platform'
    },
    path:CONFIG_PATH
}
const LOGINBACKGROUND = {
    default:login_background,
    path:LOGINBACKGROUND_PATH
}

const LOGINLOGO = {
    default:'',
    path:LOGINLOGO_PATH
}

const LOGO1 = {
    default:logo_1,
    path:LOGO1_PATH
}
const LOGO2 = {
    default:logo_2,
    path:LOGO2_PATH
}
const ICONLOGO = {
    default:icon,
    path:ICON_PATH
}
const ETH0 = {
    default:eth0,
    path:ETH_PATH0
}
const ETH1 = {
    default:eth1,
    path:ETH_PATH1
}
const MAP = {
    CONFIG,
    LOGINBACKGROUND,
    LOGINLOGO,
    LOGO1,
    LOGO2,
    ICONLOGO,
    ETH0,
    ETH1,
}

const loadStatic = (static_name,type='blob')=>{
    if(MAP[static_name]){
        if(sessionStorage.getItem(static_name)){
            if(type === 'json'){
                return new Promise((resolve)=>resolve(JSON.parse(sessionStorage.getItem(static_name))))
            }
            return new Promise((resolve)=>resolve(sessionStorage.getItem(static_name)))
        }
        
        return new Promise(
            (resolve)=>{
                Axios.get(MAP[static_name].path,{ timeout:10000,responseType:type }).then(
                    (resp)=>{
                        if(resp.headers['content-type']==='image/png'){
                            const image = new Blob([resp.data],{type:'image/png'});
                            const fr = new FileReader();
                            fr.onload = ()=>{
                                const result = fr.result;
                                resolve(result);
                                sessionStorage.setItem(static_name,result);
                            }
                            fr.readAsDataURL(image);
                        }else{
                            sessionStorage.setItem(static_name,JSON.stringify(resp.data))
                            resolve(resp.data)
                        }
                        
                    }
                ).catch((e)=>{
                    if(e.response){
                        resolve(MAP[static_name].default);
                    }
                })
            }
        )
    }
    return null
}
export {
 loadStatic  
}
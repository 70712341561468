import React, { Component } from 'react';
import { Upload, message, Icon } from 'antd';
import { intl } from '../../../Intel';
import { FormattedMessage } from "react-intl";
const deleteBase64Prefix = (base64) => {
    return base64.replace(/^data:image\/\w+;base64,/, '');
}
function beforeUpload(file) {
    const isJpgOrPng = file.type === 'image/jpg' || file.type === 'image/jpeg' || file.type === 'image/png';
    if (!isJpgOrPng) {
        message.error(intl.formatMessage({ id: 'event.searchImage.form.upload.type' }), 1.5)
    }
    const isLt5M = file.size / 1024 / 1024 < 5;
    if (!isLt5M) {
        message.error(intl.formatMessage({ id: 'event.searchImage.form.upload.size' }), 1.5)
    }
    return isJpgOrPng && isLt5M;
}
function getBase64(img, callback) {
    const reader = new FileReader();
    reader.addEventListener('load', () => callback(reader.result));
    reader.readAsDataURL(img);
}
class FaceUplaod extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            imageUrl: '',
        }
    }
    handleCustomRequest = (a) => {
        getBase64(a.file, imageUrl => {
            if (imageUrl) {
                const image = new Image();
                image.src = imageUrl;
                image.onload = () => {
                    let _width = image.width;
                    let _height = image.height;
                    if (_width > 4096 || _height > 4096) {
                        message.error(intl.formatMessage({ id: 'user.add.selectPhoto.image.large' }), 1);
                        this.setState({
                            loading: false
                        })
                        return false
                    }
                    this.setState({
                        imageUrl,
                        loading: false,
                    },() => {
                        imageUrl = deleteBase64Prefix(imageUrl);
                        this.props.getImg(imageUrl);
                    })
                }
            } else {
                message.error(intl.formatMessage({ id: 'user.add.selectPhoto.image.error' }), 1);
                return false;
            }
        })
    }
    handleChange = info => {
        if (info.file.status === 'uploading') {
            this.setState({ loading: true });
            return;
        }
    }
    clearImage = (e) => {
        if (this.props.loading) {
            return false;
        } else {
            e.stopPropagation();
            this.props.clearImg();
            this.setState({ imageUrl: null });
        }
    };
    render() {
        let clearButton = null;
        let { imageUrl } = this.state;
        const uploadButton = (
            <div>
                <Icon type={this.state.loading ? 'loading' : 'plus'} />
                <div className="ant-upload-text"><FormattedMessage id='user.add.upload' /></div>
            </div>
        );
        if (imageUrl) {
            clearButton = (
                <div className="btn-clear-wrapper" onClick={this.clearImage}>
                    <Icon type="close" />
                </div>
            );
        }
        // style={{ maxWidth: '100%', maxHeight: '100%' }}
        return (
            <Upload
                name="avatar"
                disabled={this.state.loading}
                listType="picture-card"
                className="avatar-uploader"
                accept="image/jpeg,image/jpg,image/png"
                showUploadList={false}
                beforeUpload={beforeUpload}
                customRequest={this.handleCustomRequest}
                onChange={this.handleChange}
            >
                {imageUrl ? <img src={imageUrl} alt="avatar" style={{ maxWidth: '280px', maxHeight: '280px' }} /> : uploadButton}
                {clearButton}
            </Upload>
        )
    }
}

export default FaceUplaod
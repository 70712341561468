export default {
    "login.title":"登录",
    "login.submit":"登录",
    "login.footer.help":"帮助",
    "login.footer.privacy":"隐私",
    "login.footer.terms":"条款",
    "login.username.placeholder":"管理员/用户名",
    "login.password.placeholder":"密码",
    "login.error":"用户名或密码错误",
    "login.mq.deviceKey.error":"请进入本系统的工作模式重新配置",
    "login.mq.deviceKey.error.reason":"管理平台登录请求失败原因:"
}
import React, { Component } from 'react'
import { Tabs, Layout } from 'antd'
import { FormattedMessage } from 'react-intl';
import Title from '../../common/Title'
import ClientTheme from './ClientTheme'
import UsageSetting from './UsageSetting';

const { TabPane } = Tabs;
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.Application' />
}];
class Application extends Component {
    render() {
        return (
            <React.Fragment>
                <Title items={breadcrumbSetting} />
                <Layout style={{ margin: '24px', backgroundColor: '#FFFFFF', minHeight: '420px' }}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={<FormattedMessage id="sidemenu.System.ClientTheme" />} key="2">
                            <ClientTheme />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id="sidemenu.System.BoxUsage" />} key="3">
                            <UsageSetting />
                        </TabPane>
                    </Tabs>
                </Layout>
            </React.Fragment>
        )
    }
}

export default Application
export default {
    "home":"首页",
    "sidemenu.Info":"设备信息",
    "sidemenu.Info.Basic":"基础信息",
    "sidemenu.Config":"设备配置",
    "sidemenu.Config.IPCStream":"设备预览",
    "sidemenu.Config.IPCStreamAddList":"设备管理",
    "sidemenu.User":"用户",
    "sidemenu.User.UserList":"用户管理",
    "sidemenu.User.PermissionGroup":"权限组",
    "sidemenu.User.Add":"添加用户",
    "sidemenu.User.Edit":"编辑用户",
    "sidemenu.User.UserGroup":"用户组管理",
    "sidemenu.User.UserGroup.Add":"添加用户组",
    "sidemenu.Event":"事件",
    "sidemenu.Event.History":"历史记录",
    "sidemenu.Event.Image":"以图搜图",
    "sidemenu.Event.Face":"1:1比对",
    "sidemenu.Event.Base":"底库比对",
    "sidemenu.System":"系统",
    "sidemenu.System.NetWorkSetting":"网络设置",
    "sidemenu.System.Basic":"基本信息",
    "sidemenu.System.SystemSetting":"系统设置",
    "sidemenu.System.SmartSetting":"智能设置",
    "sidemenu.System.DebugSetting":"系统调试",
    "sidemenu.System.WorkMode":"工作模式",
    "sidemenu.System.Threshold":"识别设置",
    "sidemenu.System.EventServerSetting":"事件上传设置",
    "sidemenu.System.MonitorServerSetting":"状态管理服务器地址",
    "sidemenu.System.Update":"系统升级",
    "sidemenu.System.Application":"应用设置",

    "sidemenu.System.TimeSetting":"时间设置",
    "sidemenu.System.ChangePassword":"密码更改",
    "sidemenu.System.Hostname":"主机名更改",
    "sidemenu.System.ClientTheme":"主题配置",
    "sidemenu.System.RebootSetting":"重启设置",
    "sidemenu.System.PurposeSetting":"用途设置",
    "sidemenu.System.BoxUsage":"用途设置",
}
import React, { Component } from 'react';
import {message} from 'antd';
import Main from './components/Main';
import LoginPage from './components/LoginPage';
import { HashRouter as Router,Route } from 'react-router-dom';
import './App.css';
import './index.css';
//全局config配置
message.config({
  top: 106,
  duration: 10
});


class App extends Component {
  render() {
    const login = !!sessionStorage.getItem('session');
    return (
      
      
      
      <div style={{ height: '100%' }}>
        <Router>
          {
            login ?
              (<Route path='/' component={Main}></Route>)
              : (<LoginPage />)
          }
        </Router>
      </div>
      
    );
  }
}

export default App;
import React, { Component } from 'react'
import { Layout, Select, message, Form, Button } from 'antd'
import axios from '../../../utils/AxiosWrapper'
import { SOPHON_CONFIG } from '../../../utils/Config';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../../Intel';
import { getConfig } from '../../../services/system';

class UsageSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            usage: null,
            loading: true
        }
    }
    componentDidMount() {
        getConfig('BOX_USAGE').then(res => {
            this.setState({
                usage: res.BOX_USAGE,
                loading: false
            })
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, value) => {
                if (!err) {
                    this.setBoxUsage(value);
                }
            })
    }
    setBoxUsage = (value) => {
        this.setState({
            loading: true
        })
        axios.put(`${SOPHON_CONFIG}`, {
            "config_dict": value
        }).then(
            () => {
                message.success(intl.formatMessage({ id: 'info.success' }));
                getConfig('BOX_USAGE').then(res => {
                    this.setState({
                        usage: res.BOX_USAGE,
                        loading: false
                    })
                })
            }
        )
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <div>
                {/* <Title items={breadcrumbSetting} /> */}
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 100 }}>
                    <Form onSubmit={this.handleSubmit}>
                        <Form.Item label={<FormattedMessage id='settings.usage.type' />} labelCol={{ span:10 }} wrapperCol={{ span: 8 }}>
                            {getFieldDecorator('BOX_USAGE', {
                                rules: [{ required: true, message: <FormattedMessage id='input.required' /> }],
                                initialValue: this.state.loading ? undefined : this.state.usage
                            })(
                                <Select  style={{width:240}}  loading={this.state.loading}>
                                    <Select.Option key="normal"><FormattedMessage id="settings.usage.normal" /></Select.Option>
                                    <Select.Option key="fiqa"><FormattedMessage id="settings.usage.fiqa" /></Select.Option>
                                    <Select.Option key="feature"><FormattedMessage id="settings.usage.feature" /></Select.Option>
                                </Select>
                            )}
                        </Form.Item>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button type="primary" htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                        </Form.Item>
                    </Form>
                </Layout>
            </div>
        )
    }
}
export default Form.create()(UsageSetting);


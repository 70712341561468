import React, { Component } from 'react';
import { Layout, Form, Button, Modal, Input, Select, Tabs } from 'antd'
import Title from '../common/Title';
import { IP_PATTERN } from '../../utils/Config'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { getIp, setIp } from '../../services/system';
import CellularNetwork from './CellularNetwork';
import { connect } from 'react-redux';
const { TabPane } = Tabs;
class EditForm extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            eth0: undefined,
            eth1: undefined,
            speedYes: true
        }
    }
    componentDidMount() {
        getIp().then(res => {
            const data = this.props.currentVlue === "lan" ? res[1] : res[0];
            this.setState({
                eth0: res[0],
                oldEth0: res[0],
                eth1: res[1],
                speed: data.bandwidth,
                speedYes: data.bandwidth_varity
            })
        })
    }
    refreshPage = () => {
        sessionStorage.clear(); window.location.href = '/';
    }
    handleSubmit = (e) => {
        let self = this;
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    Modal.confirm({
                        title: intl.formatMessage({ id: 'info.restart' }),
                        content: intl.formatMessage({ id: 'info.restart.content' }),
                        onOk() {
                            if (values.status === 'dynamic') {
                                setIp({
                                    new_ip: '',
                                    device: "eth0",
                                    isreboot: true,
                                    status: 'dynamic'
                                }).then(() => {
                                    self.refreshPage();
                                })
                            } else {
                                setIp({
                                    new_ip: values.IP,
                                    device: "eth0",
                                    netmask: values.SubnetMask,
                                    gateway: values.Gateway,
                                    dns: values.dns,
                                    isreboot: true,
                                    status: 'static'
                                }).then(() => {
                                    sessionStorage.clear();
                                    window.location.href = 'http://' + values.IP;
                                })
                            }
                        }
                    })
                }
            }
        )
    }
    handleSelectChange = (value) => {
        //  只有eth0 可以设置
        this.props.form.resetFields();
        if (value === this.state.oldEth0.status) {
            //和初始化返回类型一样   
            this.setState({
                eth0: this.state.oldEth0
            })
        } else {
            // 没有默认值,清空
            this.setState({
                eth0: { status: value }
            })
        }
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const isLan = this.props.currentVlue === "lan" ? true : false;
        const isDocker = this.props.systemMode === '2';
        let data = isLan ? this.state.eth1 : this.state.eth0;
        let isDisable = ((data && data.status === 'dynamic') || isLan || isDocker) ? true : false;
        let required = ((data && data.status === 'dynamic') || isLan || isDocker) ? false : true;
        let imgDom;
        let networkSpeedDom = !this.state.speedYes ? <p style={{ textAlign: 'center', color: 'red' }}><FormattedMessage id='settings.network.speed.tips' values={{ value: this.state.speed }} /></p> : null
        if (!data && isLan && data !== undefined) {
            imgDom = <p style={{ textAlign: 'center', color: 'red' }}><FormattedMessage id='settings.network.se3.tips' /></p>
        }
        return (
            <div>
                <Form onSubmit={(e) => this.handleSubmit(e)}>
                    <Form.Item label={<FormattedMessage id='settings.network.connectionType' />} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                        {getFieldDecorator('status', {
                            initialValue: data ? data.status : ''
                        })(
                            <Select onChange={this.handleSelectChange} disabled={isLan || isDocker} style={{ width: 240 }}>
                                <Select.Option key='dynamic'><FormattedMessage id='settings.network.dynamic' /></Select.Option>
                                <Select.Option key='static'><FormattedMessage id='settings.network.static' /></Select.Option>
                            </Select>
                        )}
                    </Form.Item>
                    <div>
                        <Form.Item label={<FormattedMessage id='settings.network.ip' />} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                            {getFieldDecorator('IP', {
                                initialValue: data ? data.ip : '',
                                rules: [
                                    { required: required, message: <FormattedMessage id='input.required' /> },
                                    { pattern: IP_PATTERN, message: <FormattedMessage id='settings.network.validIP' /> }],
                            })(
                                <Input style={{ width: 240 }} disabled={isDisable}></Input>
                            )}
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='settings.network.mask' />} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                            {getFieldDecorator('SubnetMask', {
                                initialValue: data ? data.netmask : '',
                                rules: [{ required: required, message: <FormattedMessage id='input.required' /> }],
                            })(
                                <Input style={{ width: 240 }} disabled={isDisable}></Input>
                            )}
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='settings.network.gateway' />} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                            {getFieldDecorator('Gateway', {
                                initialValue: data ? data.gateway : '',
                                rules: [{ required: required, message: <FormattedMessage id='input.required' /> }],
                            })(
                                <Input style={{ width: 240 }} disabled={isDisable}></Input>
                            )}
                        </Form.Item>
                        <Form.Item label={<FormattedMessage id='settings.network.DNS' />} labelCol={{ span: 10 }} wrapperCol={{ span: 8 }}>
                            {getFieldDecorator('dns', {
                                initialValue: data ? data.dns : '',
                                rules: [{ required: required, message: <FormattedMessage id='input.required' /> }],
                            })(
                                <Input style={{ width: 240 }} disabled={isDisable}></Input>
                            )}
                        </Form.Item>
                    </div>
                    {imgDom}
                    {networkSpeedDom}
                    <Form.Item style={{ textAlign: 'center' }}>
                        <Button type="primary" disabled={isLan || isDocker} htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                    </Form.Item>

                </Form>
            </div>
        )
    }
}
const WrapperedEditForm = Form.create()(EditForm);
const WrapperedEditForm2 = Form.create()(EditForm);
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.NetWorkSetting' />
}];
class NetWorkSetting extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            currentVlue: "lan"
        }
    }
    callback = (key) => {
        this.setState({
            currentVlue: key
        })
    }
    render() {
        return (
            <div>
                <Title items={breadcrumbSetting} />
                <Layout style={{ margin: '24px', backgroundColor: '#FFFFFF', minHeight: '420px' }}>
                    <Tabs defaultActiveKey="lan" onChange={this.callback}>
                        <TabPane tab={<FormattedMessage id='setup.lan.alert' />} key="lan">
                            <WrapperedEditForm {...this.props} currentVlue={this.state.currentVlue} />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id='setup.wan.alert' />} key="wan">
                            <WrapperedEditForm2 {...this.props} currentVlue={this.state.currentVlue} />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id='setting.system.cellular' />} key="3">
                            <CellularNetwork />
                        </TabPane>
                    </Tabs>
                </Layout>
            </div>
        )
    }
}

// export default NetWorkSetting

export default connect(
    (state) => {
        return {
            systemMode: state.systemMode
        }
    }
)(NetWorkSetting)
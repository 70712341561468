export default {
    "event.filter.userType":'User type',
    "event.filter.room":'Room number',
    "event.filter.floor":'Floor number',
    "event.title.list":'Past list',
    "event.title.seleted":'Not selected yet',
    "event.title.details":'Details',
    
    "event.description.entry":'Entry time',
    "event.description.go":'Appearance time',
    "event.history.query.hasMore":'More...',

    "event.title.img":'Record picture',
    "event.bar.timestamp":"Timestamp",
    "event.bar.score":"Score: {value}",
    "event.bar.score.title":"Score",
    "event.bar.deviceIP":"Device IP",
    "event.bar.face":"Face",
    "event.bar.original":"Original",
    "event.alert.msg2":"Total: {number} events",
    "event.stream.unknown":"Unknown",
    "event.stream.videoname":"Steam",
    "event.stream.Datetime":"Datetime",
    "event.stream.dateTime.1hr":"1 hr",
    "event.stream.dateTime.3hr":"3 hrs",
    "event.stream.dateTime.1day":"1 day",
    "event.stream.details.title.event":"Event",
    "event.stream.details.title.face":"Face",
    "event.history.epName":'Device',

    "event.searchImage.form.title":'Search criteria',
    "event.searchImage.content.title":'Result set',
    "event.searchImage.detail.title":'Detailed information',
    "event.searchImage.form.startTime":'Start time',
    "event.searchImage.form.endTime":'End time',
    "event.searchImage.content.tips":'Result query, please wait...',
    "event.searchImage.content.result.tips":'Due to too many results, you can find',
    "event.searchImage.content.result.nextSearch":'Click to query',
    "event.searchImage.searchOrder":'Order',
    "event.searchImage.searchOrder.order":'Order',
    "event.searchImage.form.upload.warning":'Please upload a face first',
    "event.searchImage.form.time.warning":'The start time should be less than the end time',
    "event.searchImage.searchOrder.reverse":'Reverse order',
    "event.searchImage.form.upload.type":'You can only upload JPG/PNG file',
    "event.searchImage.form.upload.size":' Image must smaller than 5MB',

    "event.compare.face.title" :'1:1 Comparison',
    "event.compare.base.title" :'Basement comparison',
    "event.compare.result.title" :'Comparison results',
    "event.compare.result.judge": 'Is it the same person',
    "event.compare.result.match.have": 'Best match',
    "event.compare.result.match.no.have": 'No match'
    
}
import React, { Component } from 'react';
import { Form, Button, Input, Select } from 'antd'
import { IP_PATTERN } from '../../utils/Config';
import { FormattedMessage } from 'react-intl';
import { getIp } from '../../services/system';
import { connect } from 'react-redux';
class EditForm extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            eth0: undefined,
            eth1: undefined,
            oldEth0: null,
            speedYes: true
        }
    }
    componentDidMount() {
        getIp().then(res => {
            const data = this.props.currentVlue === "eth1" ? res[1] : res[0];
            this.setState({
                eth0: res[0],
                oldEth0: res[0],
                eth1: res[1],
                speed: data.bandwidth,
                speedYes: data.bandwidth_varity
            })
        })
    }
    handleSubmit = (e) => {
        let oldEth0 = this.state.oldEth0;
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if ((this.props.systemMode === '2') || (values.type === oldEth0.type && (values.dns === oldEth0.dns) && (values.Gateway === oldEth0.gateway) && (values.IP === oldEth0.ip) && (values.SubnetMask === oldEth0.netmask))) {
                        this.props.onSubmit(values, false);
                    } else {
                        this.props.onSubmit(values, true);
                    }
                }
            }
        )
    }
    handleSelectChange = (value) => {
        this.props.form.resetFields();
        let eth = this.props.entry;
        if (value === this.state.oldEth0.status) {
            //和初始化返回类型一样   
            this.setState({
                [eth]: this.state.oldEth0
            })
        } else {
            // 没有默认值,清空
            this.setState({
                [eth]: { status: value }
            })
        }
    }
    handlePrePage = () => {
        if (this.props.entry === "eth1") {
            this.props.history.push(
                {
                    pathname: '/SetupHostname'
                }
            )
        } else {
            this.props.history.push(
                {
                    pathname: '/SetupLan'
                }
            )
        }

    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const eth = this.props.entry;
        const isDocker = this.props.systemMode === '2';
        let data = eth === "eth0" ? this.state.eth0 : this.state.eth1;
        let isDisable = ((data && data.status === 'dynamic') || eth === "eth1" || isDocker) ? true : false;
        let required = ((data && data.status === 'dynamic') || eth === "eth1" || isDocker) ? false : true;
        let imgDom;
        let isLan = eth === "eth1" ? true : false;
        if (!data && isLan && data !== undefined) {
            imgDom = <p style={{ textAlign: 'center', color: 'red' }}><FormattedMessage id='settings.network.se3.tips' /></p>
        }
        let networkSpeedDom = !this.state.speedYes ? <p style={{ textAlign: 'center', color: 'red' }}> <FormattedMessage id='settings.network.speed.tips' values={{ value: this.state.speed }} /></p> : null
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='settings.network.connectionType' />} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                    {getFieldDecorator('status', {
                        //rules: [{ required: true, message: 'Please select Connection type!' }],
                        initialValue: data ? data.status : ''
                    })(
                        <Select onChange={this.handleSelectChange} disabled={eth === "eth1" || isDocker}>
                            <Select.Option key='dynamic'><FormattedMessage id='settings.network.dynamic' /></Select.Option>
                            <Select.Option key='static'><FormattedMessage id='settings.network.static' /></Select.Option>
                        </Select>
                    )}
                </Form.Item>
                {/* <div style={{ display: this.state.type === 'static' ? '' : 'none' }}> */}
                <div>
                    <Form.Item label={<FormattedMessage id='settings.network.ip' />} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                        {getFieldDecorator('IP', {
                            initialValue: data ? data.ip : '',
                            rules: [
                                { required: required, message: <FormattedMessage id='input.required' /> },
                                { pattern: IP_PATTERN, message: <FormattedMessage id='settings.network.validIP' /> }],
                        })(
                            <Input disabled={isDisable}></Input>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='settings.network.mask' />} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                        {getFieldDecorator('SubnetMask', {
                            initialValue: data ? data.netmask : '',
                            rules: [{ required: required, message: <FormattedMessage id='input.required' /> }],
                        })(
                            <Input disabled={isDisable}></Input>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='settings.network.gateway' />} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                        {getFieldDecorator('Gateway', {
                            initialValue: data ? data.gateway : '',
                            rules: [{ required: required, message: <FormattedMessage id='input.required' /> }],
                        })(
                            <Input disabled={isDisable}></Input>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='settings.network.DNS' />} labelCol={{ span: 8 }} wrapperCol={{ span: 8 }}>
                        {getFieldDecorator('dns', {
                            initialValue: data ? data.dns : '',
                            rules: [{ required: required, message: <FormattedMessage id='input.required' /> }],
                        })(
                            <Input disabled={isDisable}></Input>
                        )}
                    </Form.Item>
                </div>
                {imgDom}
                {networkSpeedDom}
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type="primary" htmlType="submit"><FormattedMessage id='setup.next' /></Button>
                    <Button style={{ marginLeft: 8 }} onClick={this.handlePrePage}><FormattedMessage id='button.back' /></Button>
                </Form.Item>
            </Form>
        )

    }
}
// export default EditForm
export default connect(
    (state) => {
        return {
            systemMode: state.systemMode
        }
    }
)(EditForm)
import React, { Component } from 'react';
import { Row, Col, Layout, Progress, Modal, Popover } from 'antd'
import { FormattedMessage } from 'react-intl';
import Title from '../common/Title';
import moment from 'moment';
import {
    getHostName,
    getCpu,
    getTemperature,
    getFan,
    getTime,
    getSystemVersion,
    getDeviceSN,
    getMemoryInfo,
    getHardwareInfo,
    getIp,
    getUptime,
    getDiskInfo,
    getTpu
} from '../../services/basicInfo'
import { getLteModem } from '../../services/system';
import { intl } from '../../Intel';
import './basicInfo.css'
import echarts from 'echarts'

moment.locale('zh-cn');
const colSpan = {
    xs: 8,
    lg: 8,
    xl: 4
}
const colSpan1 = {
    xs: 24,
    lg: 24,
    xl: 10
}
const colSpan2 = {
    xs: 24,
    lg: 24,
    xl: 14,
}
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.Basic' />
}];
class BasicInfo extends Component {
    state = {
        hostname: '',
        version: '',
        uptime: '',
        DeviceSN: '',
        cpu: 0,
        wanIp: '',
        lanIp: '',
        wanBandwidth: '',
        lanBandwidth: '',
        productVersion: '',
        model: '',
        fanSpeed: '',
        versionDetail: '',
        totalMemory: 0,
        tpu: 0,
        tpuInfo1: '',
        tpuInfo2: '',
        aboutModal: false,
        memoryTpu: [],
        memorySystem: [],
        memoryVideo: [],
        diskTotal: 0,
        diskFree: 0,
        disk: 0,
        flowOctive: '',
        mcu: ''
    }
    componentDidMount() {
        getTemperature().then(res => {
            this.setState({
                coreTemperature: parseFloat(res.data.core_temperature).toFixed(2)
            }, () => {
                this.canvasChart1();
            })
        })
        getTpu().then(res => {
            let info = res.data.info.detail;
            let tpuInfo1 = info.split(';')[0];
            let tpuInfo2 = info.split(';')[1]
            this.setState({
                tpu: parseFloat((res.data.tpu_rate * 100).toFixed()),
                tpuInfo1: tpuInfo1,
                tpuInfo2: tpuInfo2
            })
        })
        getMemoryInfo().then(res => {
            let tpu = res.data.tpu;
            let system = res.data.system;
            let video = res.data.video;
            this.setState({
                totalMemory: res.data.hardware_total_memory,
                memorySystem: [system.system_scale, system.total, system.used, system.free],
                memoryTpu: [tpu.tpu_scale, tpu.total, tpu.used, tpu.free],
                memoryVideo: [video.video_scale, video.total, video.used, video.free]
            }, () => {
                this.canvasMemoryChart();
            })
        })
        getFan().then(res => {
            let fanSpeed = res.data.fan_speed.split('r')[0];
            if (fanSpeed === 0) {
                fanSpeed = 0;
            } else {
                fanSpeed = parseFloat((fanSpeed / 1000).toFixed(2))
            }
            this.setState({
                fanSpeed: fanSpeed
            }, () => {
                this.canvasChart2();
            })
        })
        getHostName().then(res => {
            this.setState({
                hostname: res.data.hostname
            })
        })
        getSystemVersion().then(res => {
            this.setState({
                version: res.data.version,
                versionDetail: res.data.details,
                mcu: res.data.mcu_version
            })
        })
        getHardwareInfo().then(res => {
            this.setState({
                productVersion: res.data.product_version,
                model: res.data.model
            })
        })
        getUptime().then(res => {
            let data = res.data.uptime;
            if (data.indexOf('minute') !== -1) {
                data = data.replace(/minute./, 'min');
            }
            this.setState({
                uptime: data
            })
        })
        getDeviceSN().then(res => {
            this.setState({
                DeviceSN: res.data.device_sn
            })
        })
        // this.setState({
        //     cpu: 10,
        //     // cpu_count: cpu_count,
        //     // cpu_freq: cpu_freq,
        //     // cpu_type: cpu_type
        // })
        getCpu().then(res => {
            let cpu_count = res.data.info.cpu_count;
            let cpu_freq = res.data.info.cpu_freq;
            let cpu_type = res.data.info.cpu_type + '@';
            let cpu = parseFloat((res.data.cpu_rate * 100).toFixed());
            this.setState({
                cpu: cpu,
                cpu_count: cpu_count,
                cpu_freq: cpu_freq,
                cpu_type: cpu_type
            })
        })
        getIp().then(res => {
            this.setState({
                wanIp: res.data[0].ip,
                wanBandwidth: res.data[0].bandwidth,
                lanIp: res.data[1].ip,
                lanBandwidth: res.data[1].bandwidth
            })
        })
        getDiskInfo().then(res => {
            this.setState({
                diskTotal: res.data.hardware_total_disk,
                diskFree: res.data.free_disk,
                disk: res.data.disk_rate
            })
        })
        getTime().then(res => {
            const time = moment(res.data.time, "x").add(1, 's').format("YYYY-MM-DD HH:mm:ss");
            this.setState({
                systemtime: time
            })
        })
        getLteModem().then(res => {
            this.setState({
                flowOctive: res.data.service
            })
        })
    }
    canvasChart1 = () => {
        var myChart = echarts.init(document.getElementById('chart1'));
        // 绘制图表
        myChart.setOption(
            {
                tooltip: {
                    formatter: '{c}℃'
                },
                series: [
                    {
                        name: 'template',
                        type: 'gauge',
                        detail: { formatter: '{value}℃' },
                        data: [{ value: this.state.coreTemperature, name: '' }],
                        axisLine: {
                            lineStyle: {
                                // color:[[0.2, "#0099FF"], [0.8, "#0066CC"], [1, "#0099FF"]]
                                color: [[1, {
                                    type: 'linear',
                                    x: 0,
                                    y: 0,
                                    x2: 1,
                                    y2: 0,
                                    colorStops: [{
                                        offset: 0, color: '#B0E2FF' // 0% 处的颜色
                                    }, {
                                        offset: 1, color: '#9A32CD' // 100% 处的颜色
                                    }],
                                    global: false // 缺省为 false
                                }]]
                                // , [0.8, "rgba(45,43,48,1)"], [1, "rgba(45,43,48,1)"]]

                            }
                        }
                    }]
            }
        );
    }
    canvasMemoryChart = () => {
        var myChart = echarts.init(document.getElementById('tpu-echart'));
        myChart.setOption({
            tooltip: {
                trigger: 'item',
                formatter: (a) => { return `${a.data.name}:${a.value[1]}<br/>${intl.formatMessage({ id: `basic.info.percentage.used` })}:${a.value[2]} ${intl.formatMessage({ id: `basic.info.percentage.available` })}:${a.value[3]}` }
            },
            series: [
                {
                    selectedOffset: 3,
                    hoverOffset: 2,
                    type: 'pie',
                    left: "center",
                    top: 'middle',
                    // radius: [0, '100%'],
                    radius: ['75%', '100%'],
                    // avoidLabelOverlap: false,
                    height: 80,
                    width: 80,
                    data: [
                        { value: this.state.memoryVideo, name: intl.formatMessage({ id: `basic.info.percentage.memory.Video` }), itemStyle: { color: 'rgb(75,134,242)' } },
                        { value: this.state.memoryTpu, name: intl.formatMessage({ id: `basic.info.percentage.memory.TPU` }), itemStyle: { color: 'rgb(134,242,248)' } },
                        { value: this.state.memorySystem, name: intl.formatMessage({ id: `basic.info.percentage.memory.system` }), itemStyle: { color: 'rgb(232,56,245)' } }
                    ],
                    labelLine: {
                        show: false
                    }
                },

            ]
        });
    }
    canvasChart2 = () => {
        var myChart = echarts.init(document.getElementById('chart2'));
        // 绘制图表
        myChart.setOption({
            // tooltip: {
            //     formatter: '{c}1000r/min'
            // },
            series: [
                {
                    min: 0,
                    max: 20,
                    name: '',
                    type: 'gauge',
                    detail: { formatter: '{value}' },
                    data: [{ value: this.state.fanSpeed, name: '' }],
                    axisLine: {
                        lineStyle: {
                            // color:[[0.2, "#9933CC"], [0.8, "#CC3399"], [1,'#9933CC']]
                            color: [[1, {
                                type: 'linear',
                                x: 0,
                                y: 0,
                                x2: 1,
                                y2: 0,
                                colorStops: [{
                                    offset: 0, color: '#87CEFF' // 0% 处的颜色
                                }, {
                                    offset: 1, color: '#0000FF' // 100% 处的颜色
                                }],
                                global: false // 缺省为 false
                            }]]
                        }
                    }
                },


            ],
        });
    }
    showAbout = () => {
        this.setState({
            aboutModal: true
        })
    }
    closeAbout = () => {
        this.setState({
            aboutModal: false
        })
    }
    render() {
        // let content = <div className="ext-disk">
        //     <div>/dev/sda1</div>
        //     <section style={{ paddingLeft: 14 }}>
        //         <div><FormattedMessage id='basic.info.percentage.outDisk.point' />: /data</div>
        //         <div><FormattedMessage id='basic.info.percentage.outDisk.total' />: 500G</div>
        //         <div><FormattedMessage id='basic.info.percentage.outDisk.available' />: 200G</div>
        //     </section>
        //     <div>/dev/sda2</div>
        //     <section style={{ paddingLeft: 14 }}>
        //         <div><FormattedMessage id='basic.info.percentage.outDisk.point' />: /data</div>
        //         <div><FormattedMessage id='basic.info.percentage.outDisk.total' />: 500G</div>
        //         <div><FormattedMessage id='basic.info.percentage.outDisk.available' />: 200G</div>
        //     </section>
        // </div>
        let tpuContent = <div>
            <div><FormattedMessage id='basic.info.percentage.memory.TPU' />{': ' + this.state.memoryTpu[1]}</div>
            <section><FormattedMessage id='basic.info.percentage.used' />{': ' + this.state.memoryTpu[2]}</section>
            <section><FormattedMessage id='basic.info.percentage.available' />{': ' + this.state.memoryTpu[3]}</section>
        </div>
        return (
            <div className="info">
                <Title items={breadcrumbSetting} />
                <Layout style={{ minHeight: '420px', padding: 24 }}>
                    <Row type="flex" justify="space-between" gutter={[12, 12]} className='availabilities'>
                        <Col span={4} {...colSpan}>
                            <div className="item" name="CPUsage">
                                <p className="title"><FormattedMessage id='basic.info.percentage.CPUsage' /></p>
                                <div className="content">
                                    <div className="left">
                                        <Progress status='normal' strokeColor="rgb(88,190,107)" width={80} strokeLinecap='round' type="circle" percent={this.state.cpu} />
                                    </div>
                                    <div className="right">
                                        <span name="cpu_count">{this.state.cpu_count}<FormattedMessage id='basic.info.cpu.info.cpuCount' /> </span>
                                        <span name="cpu_type"> {this.state.cpu_type}</span>
                                        <span name="cpu_freq"> {this.state.cpu_freq} </span>

                                    </div>
                                </div>
                            </div>
                        </Col>

                        <Col span={4} {...colSpan}>
                            <div className="item" name="TPUsage">
                                <p className="title">
                                    <FormattedMessage id='basic.info.percentage.TPUsage' />
                                </p>
                                <div className="content">
                                    <div className="left">
                                        <Popover content={tpuContent}>
                                            <Progress status='normal' strokeColor="rgb(134,242,248)" width={80} strokeLinecap='round' type="circle" percent={this.state.tpu} />
                                        </Popover>
                                    </div>
                                    <div className="right">
                                        <p>{this.state.tpuInfo1}</p>
                                        <p>{this.state.tpuInfo2}</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col span={4} {...colSpan}>
                            <div className="item" name="Memorysage">
                                <p className="title">
                                    <FormattedMessage id='basic.info.percentage.Memorysage' />
                                </p>
                                <div className="content">
                                    <div className="left">
                                        <div id="tpu-echart" className="tpu-chart"></div>
                                    </div>
                                    <div className="right">
                                        <p><FormattedMessage id='basic.info.percentage.total' />: {this.state.totalMemory}</p>
                                    </div>
                                </div>

                            </div>
                        </Col>
                        <Col span={4} {...colSpan}>
                            <div className="item" name='inDisk' >
                                <p className="title"><FormattedMessage id='basic.info.percentage.inDisk' /></p>
                                <div className="content">
                                    <div className="left">
                                        <Progress status='normal' strokeColor="#0099FF" width={80} strokeLinecap='round' type="circle" percent={parseFloat((this.state.disk * 100).toFixed(2))} />
                                    </div>
                                    <div className="right">
                                        <p><FormattedMessage id='basic.info.percentage.total' />: {this.state.diskTotal}</p>
                                        <p><FormattedMessage id='basic.info.percentage.available' />: {this.state.diskFree}</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4} {...colSpan}>
                            <div className="item" name='outDisk'>
                                <p className="title"><FormattedMessage id='basic.info.percentage.outDisk' /></p>
                                <div className="content">
                                    <div className="left">
                                        {/* <Popover content={content} title={<FormattedMessage id='basic.info.percentage.outDisk' />}> */}
                                        <Progress status='normal' strokeColor="#4682B4" width={80} strokeLinecap='round' type="circle" percent={0} />
                                        {/* </Popover> */}
                                    </div>
                                    <div className="right">
                                        <p><FormattedMessage name='total' id='basic.info.percentage.total' />: 0</p>
                                        <p><FormattedMessage name='available' id='basic.info.percentage.available' />: 0</p>
                                    </div>
                                </div>
                            </div>
                        </Col>
                        <Col span={4} {...colSpan} >
                            <div className="item" name='flow'>
                                <p className="title"><FormattedMessage id='basic.info.percentage.flow' /></p>
                                <div className="content">
                                    <div className="left">
                                        <div className="circle" style={{ border: this.state.flowOctive === 'active' ? '5px solid #66CC66' : '4px solid #dedede', fontSize: 20 }}>
                                            {/* <span className="icon iconfont icon-yidongshujuwangqia"></span> */}
                                            <span title={this.state.flowOctive} style={{ color: this.state.flowOctive === 'active' ? '#66CC66' : '#dedede', fontSize: 20 }} className="icon iconfont">&#xe6d7;</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row type="flex" justify="space-between" style={{ marginTop: 6 }} className="content-chart" gutter={[12, 12]}>
                        <Col span={12} {...colSpan1}>
                            <div className="item">
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.hostname' /></Col>
                                    <Col span={12} name='hostname' style={{ color: '#0EB4E5' }}>{this.state.hostname}</Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.systemVersion' /></Col>
                                    <Col name='version' span={12}>{this.state.version} <span onClick={this.showAbout} className="cursor"> <FormattedMessage id='config.detail.detailButton' /></span></Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.uptime' /></Col>
                                    <Col span={12} style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}>
                                        <Popover content={this.state.uptime}>
                                            <span name='uptime'>{this.state.uptime}</span>
                                        </Popover>
                                    </Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.model' /></Col>
                                    <Col name='model' span={12}>{this.state.model}</Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.hardwareInfo' /></Col>
                                    <Col name='productVersion' span={12}>{this.state.productVersion}</Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.mcu' /></Col>
                                    <Col name='mcu' span={12}>{this.state.mcu}</Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.SN' /></Col>
                                    <Col name='DeviceSN' span={12}>{this.state.DeviceSN}</Col>
                                </Row>

                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.wanIp' /></Col>
                                    <Col name='wanIp' span={12}>{this.state.wanIp}</Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id="basic.info.panal.wan" /></Col>
                                    <Col name='wanBandwidth' span={12}>{this.state.wanBandwidth}</Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id='basic.info.panal.lanIp' /></Col>
                                    <Col name='lanIp' span={12}>{this.state.lanIp}</Col>
                                </Row>
                                <Row className='info-panal-row'>
                                    <Col span={12}><FormattedMessage id="basic.info.panal.lan" /></Col>
                                    <Col name='lanBandwidth' span={12}>{this.state.lanBandwidth}</Col>
                                </Row>
                            </div>
                        </Col>
                        <Col span={12} {...colSpan2}>
                            <div className="item">
                                <div className="time-content" name="systemtime">{this.state.systemtime}</div>
                                <Row type="flex" justify="space-between" className="e-chart">
                                    <Col span={12}>
                                        <div className="left" id='chart1' name="coreTemperature" title={this.state.coreTemperature}>

                                        </div>
                                        <div className="theme"><FormattedMessage id="basic.info.title.chipTemperature" /></div>
                                    </Col>

                                    <Col span={12}><div className="right" id='chart2' name="fan" title={this.state.fanSpeed}>

                                    </div>
                                        <div className="theme"><FormattedMessage id="basic.info.title.fan" /></div>
                                    </Col>
                                </Row>

                            </div>
                        </Col>
                    </Row>
                    <Modal
                        title={<FormattedMessage id='basic.info.version.title' />}
                        footer={null}
                        maskClosable={false}
                        destroyOnClose={true}
                        width={1200}
                        onCancel={this.closeAbout}
                        visible={this.state.aboutModal}
                    >
                        <div dangerouslySetInnerHTML={{ __html: this.state.versionDetail }}></div>
                    </Modal>
                </Layout>
            </div >
        )
    }
}
export default BasicInfo
import React, { Component } from 'react';
import { Layout, Form, Button, TimePicker, Select, Switch, Input, Modal, message, Checkbox } from 'antd'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../../Intel';
import { getConfigs, setConfigs } from '../../../services/system';
import moment from 'moment'
const format = 'HH:mm:ss';


class EditForm extends Component {
    constructor() {
        super(...arguments)
        this.state = {
            isOpen: true,
            initIsopen: true,
            loading: false,
            initData: {}
        }
    }
    componentDidMount() {
        this.getInitData();
    }
    refreshPage = () => {
        sessionStorage.clear(); window.location.href = '/';
    }
    getInitData = () => {
        getConfigs().then(res => {
            let data = {
                APPLY_MODE: res.APPLY_MODE,
                GRAB_MIN_FACE: res.GRAB_MIN_FACE,
                GRAB_TIME_PART1: res.GRAB_TIME_PART1,
                GRAB_TIME_PART2: res.GRAB_TIME_PART2,
                GRAB_THRESHOLD: res.GRAB_THRESHOLD,
                GRAB_MODE: res.GRAB_MODE,
                PHOTO_TYPE: res.PHOTO_TYPE,
                // CLARITY: res.CLARITY,
                // BRIGHTNESS: res.BRIGHTNESS,
                // OCCLUDE: res.OCCLUDE,
                // PITCH: res.PITCH,
                // YAW: res.YAW,
                // ROLL: res.ROLL,
                POSE_THRESH: res.POSE_THRESH
            }
            data.GRAB_TIME_PART1_SWITCH = res.GRAB_TIME_PART1_SWITCH;
            data.GRAB_TIME_PART2_SWITCH = res.GRAB_TIME_PART2_SWITCH;
            data.GRAB_TIME_PART1_startTime = res.GRAB_TIME_PART1.split(',')[0];
            data.GRAB_TIME_PART1_endTime = res.GRAB_TIME_PART1.split(',')[1];
            data.GRAB_TIME_PART2_startTime = res.GRAB_TIME_PART2.split(',')[0];
            data.GRAB_TIME_PART2_endTime = res.GRAB_TIME_PART2.split(',')[1];
            if (res.APPLY_MODE === 'grab') {
                data.APPLY_MODE = true;
                this.setState({
                    isOpen: true,
                    initIsopen: true
                })
            } else {
                data.APPLY_MODE = false;
                this.setState({
                    isOpen: false,
                    initIsopen: false
                })
            }
            this.setState({
                initData: data
            })
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if (!values.GRAB_TIME_PART1_startTime || !values.GRAB_TIME_PART2_startTime || !values.GRAB_TIME_PART1_endTime || !values.GRAB_TIME_PART2_endTime) {
                        message.error(intl.formatMessage({ id: 'setting.smart.capture.form.time.require' }), 1.5);
                        return false;
                    }
                    if (new Date(values.GRAB_TIME_PART1_startTime) - new Date(values.GRAB_TIME_PART1_endTime) > 0 || new Date(values.GRAB_TIME_PART2_startTime) - new Date(values.GRAB_TIME_PART2_endTime) > 0) {
                        message.error(intl.formatMessage({ id: 'user.add.startTime.error' }), 1.5);
                        return false;
                    }
                    let GRAB_TIME_PART1 = `${moment(values.GRAB_TIME_PART1_startTime).format(format)},${moment(values.GRAB_TIME_PART1_endTime).format(format)}`;
                    values.GRAB_TIME_PART1 = GRAB_TIME_PART1;
                    let GRAB_TIME_PART2 = `${moment(values.GRAB_TIME_PART2_startTime).format(format)},${moment(values.GRAB_TIME_PART2_endTime).format(format)}`
                    values.GRAB_TIME_PART2 = GRAB_TIME_PART2;
                    let initData = this.state.initData;
                    let isChangeAPPLY_MODE = initData.APPLY_MODE !== values.APPLY_MODE;
                    if (values.APPLY_MODE) {
                        values.APPLY_MODE = 'grab';
                    } else {
                        values.APPLY_MODE = 'recognize';
                    }
                    if (values.GRAB_MIN_FACE) {
                        values.GRAB_MIN_FACE = parseInt(values.GRAB_MIN_FACE);
                    }
                    if (values.GRAB_THRESHOLD) {
                        values.GRAB_THRESHOLD = parseFloat(values.GRAB_THRESHOLD);
                    }
                    let needChange = {};
                    let fileds = ['GRAB_MIN_FACE', 'GRAB_TIME_PART1_SWITCH', 'GRAB_TIME_PART2_SWITCH', 'GRAB_TIME_PART1', 'GRAB_TIME_PART2', 'GRAB_THRESHOLD', 'GRAB_MODE', 'PHOTO_TYPE', 'POSE_THRESH']
                    let changeFileds = fileds.filter(item => {
                        if (initData[item] !== values[item]) {
                            needChange[item] = values[item];
                            return true;
                        } else {
                            return false;
                        }
                    });
                    if (changeFileds.length || isChangeAPPLY_MODE) {
                        if (isChangeAPPLY_MODE) {
                            needChange.APPLY_MODE = values.APPLY_MODE;
                        }
                        Modal.confirm({
                            title: intl.formatMessage({ id: this.props.isDocker ? 'info.gate.restart' : 'info.restart' }),
                            content: intl.formatMessage({ id: this.props.isDocker ? 'info.gate.restart.content' : 'info.gate.restart.content' }),
                            onOk: () => {
                                this.setState({
                                    loading: true
                                })
                                setConfigs({ "config_dict": needChange }).then(res => {
                                    this.refreshPage();
                                }).finally(() => {
                                    this.setState({
                                        loading: false
                                    })
                                })
                            }
                        })
                    } else {
                        message.warning(intl.formatMessage({ id: 'info.nochange.warning' }), 1);
                    }
                }
            }
        )
    }
    handleOpen = (value) => {
        this.setState({
            isOpen: value
        })
        if (value) {
            this.props.activeFaceValue('grab');
        } else {
            this.props.form.resetFields();
            this.props.activeFaceValue('recognize');
        }
    }
    handleReset = () => {
        this.props.form.resetFields();
    }
    static getDerivedStateFromProps = (nextProps, prevState) => {
        if (nextProps.tab !== prevState.tab) {
            if (nextProps.tab === 'grab') {
                nextProps.form.resetFields();
            }
            return {
                tab: nextProps.tab,
                isOpen: prevState.initIsopen
            }
        }
        //不需要更新状态，返回null
        return null
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let initData = this.state.initData;
        let disabled = !this.state.isOpen;

        return (
            <Form labelCol={{ span: 10 }} wrapperCol={{ span: 14 }} onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='setting.smart.capture.form.mode.applyMode' />}>
                    {getFieldDecorator('APPLY_MODE', {
                        initialValue: initData.APPLY_MODE,
                        valuePropName: 'checked',
                    })(
                        <Switch onChange={this.handleOpen} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.recognition.form.regMinFace' />} >
                    {getFieldDecorator('GRAB_MIN_FACE', {
                        initialValue: initData.GRAB_MIN_FACE,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                            { pattern: /^(\b[2-9]\d\b|\b[1-2]\d\d\b|\b300\b)$/, message: <FormattedMessage id='setting.smart.recognition.form.regMinFace.invalid' /> }
                        ],
                    })(
                        <Input style={{ width: 240 }} disabled={disabled} />
                    )}
                    <span> (20 ~ 300)</span>

                </Form.Item>
                <Form.Item label={<FormattedMessage values={{ value: '1' }} id='setting.smart.capture.form.time' />} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('GRAB_TIME_PART1_SWITCH', {
                        initialValue: initData.GRAB_TIME_PART1_SWITCH ? true : false,
                        valuePropName: 'checked',
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                        ],
                    })(
                        <Checkbox disabled={disabled} style={{ marginRight: 10 }}></Checkbox>
                    )}
                    {getFieldDecorator('GRAB_TIME_PART1_startTime', {
                        initialValue: moment(initData.GRAB_TIME_PART1_startTime, format),
                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}
                    {'~'}
                    {getFieldDecorator('GRAB_TIME_PART1_endTime', {
                        initialValue: moment(initData.GRAB_TIME_PART1_endTime, format)

                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}

                </Form.Item>
                <Form.Item label={<FormattedMessage id='setting.smart.capture.form.time' values={{ value: '2' }} />} style={{ marginBottom: 0 }}>
                    {getFieldDecorator('GRAB_TIME_PART2_SWITCH', {
                        initialValue: initData.GRAB_TIME_PART2_SWITCH ? true : false,
                        valuePropName: 'checked',
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                        ],
                    })(
                        <Checkbox disabled={disabled} style={{ marginRight: 10 }}></Checkbox>
                    )}
                    {getFieldDecorator('GRAB_TIME_PART2_startTime', {
                        initialValue: moment(initData.GRAB_TIME_PART2_startTime, format),
                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}
                    {'~'}
                    {getFieldDecorator('GRAB_TIME_PART2_endTime', {
                        initialValue: moment(initData.GRAB_TIME_PART2_endTime, format),
                    })(
                        <TimePicker disabled={disabled} format={format} />
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.threshold' />}>
                    {getFieldDecorator('GRAB_THRESHOLD', {
                        initialValue: initData.GRAB_THRESHOLD,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="input.required" />,
                            },
                            { pattern: /^([0-9]\d?|100)$/, message: <FormattedMessage id='settings.threshold.invalid' /> }
                        ],
                    })(
                        <Input style={{ width: 240 }} disabled={disabled} />
                    )}
                    <span> (0 ~ 100)</span>

                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.grabMode' />} >
                    {getFieldDecorator('GRAB_MODE', {
                        initialValue: initData.GRAB_MODE,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="select.required" />,
                            },
                        ],
                    })(
                        <Select disabled={disabled} style={{ width: 240 }}>
                            <Select.Option value="quality" key="quality"><FormattedMessage id="setting.smart.capture.form.grabMode.quality" /></Select.Option>
                            <Select.Option value="speed" key="speed"><FormattedMessage id="setting.smart.capture.form.grabMode.speed" /></Select.Option>
                        </Select>
                    )}
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.photoUpload' />}>
                    {getFieldDecorator('PHOTO_TYPE', {
                        initialValue: initData.PHOTO_TYPE,
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="select.required" />,
                            },
                        ],
                    })(
                        <Select disabled={disabled} style={{ width: 240 }}>
                            <Select.Option value="recognize_face" key="recognize_face"><FormattedMessage id="setting.smart.capture.form.photoUpload.face" /></Select.Option>
                            <Select.Option value="spot_pic" key="spot_pic"><FormattedMessage id="setting.smart.capture.form.photoUpload.back" /></Select.Option>
                            <Select.Option value="all" key="all"><FormattedMessage id="setting.smart.capture.form.photoUpload.backFace" /></Select.Option>
                        </Select>
                    )}
                </Form.Item>

                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.qualityFilter' />}>
                    {getFieldDecorator('POSE_THRESH', {
                        initialValue: initData.POSE_THRESH || 'high',
                        rules: [
                            {
                                required: true,
                                message: <FormattedMessage id="select.required" />,
                            },
                        ],
                    })(
                        <Select disabled={disabled} style={{ width: 240 }}>
                            <Select.Option value="off" key="off"><FormattedMessage id="setting.smart.capture.form.qualityFilter.off" /></Select.Option>
                            <Select.Option value="low" key="low"><FormattedMessage id="setting.smart.capture.form.qualityFilter.low" /></Select.Option>
                            <Select.Option value="middle" key="middle"><FormattedMessage id="setting.smart.capture.form.qualityFilter.middle" /></Select.Option>
                            <Select.Option value="high" key="high"><FormattedMessage id="setting.smart.capture.form.qualityFilter.high" /></Select.Option>
                        </Select>
                    )}
                </Form.Item>
                {/* <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.clarity' />}>
                    {getFieldDecorator('CLARITY', {
                        initialValue: initData.CLARITY
                    })(
                        <InputNumber disabled={true} min={0} max={100} />
                    )}
                    <span> (0 ~ 100)</span>

                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.brightness' />}>
                    {getFieldDecorator('BRIGHTNESS', {
                        initialValue: initData.BRIGHTNESS
                    })(
                        <InputNumber disabled={true} min={0} max={100} />
                    )}
                    <span> (0 ~ 100)</span>

                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.occlude' />}>
                    {getFieldDecorator('OCCLUDE', {
                        initialValue: initData.OCCLUDE
                    })(
                        <InputNumber disabled={true} min={0} max={100} />
                    )}
                    <span> (0 ~ 100)</span>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.pitch' />}>
                    {getFieldDecorator('PITCH', {
                        initialValue: initData.PITCH
                    })(
                        <InputNumber disabled={true} min={0} max={90} />
                    )}
                    <span> (0 ~ 90)</span>
                </Form.Item>
                <Form.Item style={{ marginBottom: 0 }} label={<FormattedMessage id='setting.smart.capture.form.yaw' />}>
                    {getFieldDecorator('YAW', {
                        initialValue: initData.YAW
                    })(
                        <InputNumber disabled={true} min={0} max={90} />
                    )}
                    <span> (0 ~ 90)</span>
                </Form.Item>
                <Form.Item label={<FormattedMessage id='setting.smart.capture.form.roll' />}>
                    {getFieldDecorator('ROLL', {
                        initialValue: initData.ROLL
                    })(
                        <InputNumber disabled={true} min={0} max={90} />
                    )}
                    <span> (0 ~ 90)</span>
                </Form.Item> */}
                <Form.Item disabled={disabled} style={{ textAlign: 'center' }} labelCol={{ span: 2 }} wrapperCol={{ span: 22 }}>
                    <Button type="primary" loading={this.state.loading} htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                    <Button style={{ marginLeft: 8 }} onClick={this.handleReset}><FormattedMessage id='button.clear' /></Button>
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);
class FaceCapture extends Component {
    render() {
        return (
            <div>
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 20 }}>
                    <WrapperedEditForm {...this.props} />
                </Layout>
            </div>
        )
    }
}

export default FaceCapture
import React from "react";
import { Form, Input, Button, Select, Slider } from "antd";
import { FormattedMessage } from 'react-intl';
import DateRange from "./DateRange";
import { USER_TYPE, USER_TYPE1 } from "../../../utils/Config";
import StreamSelect from "./EpSelect"
import _ from 'lodash';
const Option = Select.Option;
const FormItem = Form.Item;

class EventQueryForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isMore: false
        }
    }

    handleSubmit = (e) => {
        if (e !== undefined) {
            e.preventDefault();
        }
        this.props.queryStart();
        this.props.form.validateFields(async (err, fields) => {
            if (err) { return console.log(err); }

            const { name, datetime, groups, streams, score, userType, room } = fields;

            let params = {}
            if (name) {
                params.name = name;
            }
            if (userType) {
                params.userType = userType;
            }
            if (room) {
                params.room = room;
            }
            if (datetime !== undefined && datetime.startValue !== undefined && datetime.startValue !== null) {
                params.datetime_gte = (datetime.startValue.unix());
            }
            if (datetime !== undefined && datetime.endValue !== undefined && datetime.endValue !== null) {
                params.datetime_lte = (datetime.endValue.unix());
            }
            if (score !== undefined && score[0] !== undefined && score[1] !== undefined) {
                if (score[0] < score[1]) {
                    params.score_min = score[0];
                    params.score_max = score[1];
                }
                if (score[0] === score[1]) {
                    params.score_max = params.score_min = score[0];
                }

            }
            if (groups !== undefined) {
                params.groups = groups;
            }
            if (streams !== undefined) {
                params.streams = streams;
            }
            this.props.queryCallback(params);
        });
    }


    handleReset = () => {
        this.props.form.resetFields();
        //clear groups 
        this.props.form.setFieldsValue({ 'score': [] })
        //clear datetime
        this.props.form.setFieldsValue({ 'datetime': { startValue: null, endValue: null } })
        this.props.queryCallback({})
    }

    handleScoreRangeCallback = (range) => {
        this.props.form.setFieldsValue({
            score: {
                min: range.minValue,
                max: range.maxValue,
            }
        });
    }

    updateScoreValue = (value) => {
        this.setState({
            scoreValue: value
        })
    }
    handleShowMore = () => {
        this.setState({
            isMore: !this.state.isMore
        })
    }

    render() {
        let NEW_USER_TYPE = null;
        if (this.props.theme === 'PARK') {
            NEW_USER_TYPE = USER_TYPE;
        } else {
            NEW_USER_TYPE = USER_TYPE1;
        }
        const { getFieldDecorator } = this.props.form;

        const optionItem = _.map(NEW_USER_TYPE, item => {
            return <Option key={item.typeId} value={item.typeId}>
                <FormattedMessage id={`user.add.type.${item.description}`} />
            </Option>
        })
        const roomItem = this.props.theme === "SCHOOL" ? <FormItem label={<FormattedMessage id="event.filter.room" />}
        >
            {getFieldDecorator("room")(
                <Input style={{ width: "240px" }} />
            )}
        </FormItem > : null;
        const moreItem = <div>
            <FormItem
                label={<FormattedMessage id='event.stream.Datetime' />}
            >
                {getFieldDecorator("datetime")(
                    <DateRange />
                )}
            </FormItem>
            <Form.Item
                label={<FormattedMessage id='event.bar.score.title' />} >
                {getFieldDecorator('score')(<Slider min={0} max={100} style={{ width: "400px" }} range={true} step={1} />)}
            </Form.Item>
        </div>
        return (
            <Form layout="inline">
                {this.state.isMore ? moreItem : null}
                <FormItem
                    label={<FormattedMessage id='user.list.form.name' />}>
                    {getFieldDecorator("name")(
                        <Input style={{ width: "200px" }} />
                    )}
                </FormItem>
                <FormItem
                    label={
                        <FormattedMessage id="event.filter.userType" />
                    }
                >
                    {getFieldDecorator("userType")(
                        <Select style={{ width: "120px" }} allowClear={true}>{optionItem}</Select>
                    )}
                </FormItem>
                <FormItem
                    label={<FormattedMessage id='event.history.epName' />}

                >
                    {getFieldDecorator("streams")(
                        <StreamSelect width={240} data={this.props.videoData} />
                    )}
                </FormItem>
                {roomItem}
                <FormItem
                    style={{ textAlign: "right" }}
                >
                    <Button type="primary" onClick={this.handleSubmit}><FormattedMessage id='button.query' /></Button>
                    <div style={{ display: 'inline-block', width: 10 }}></div>
                    <Button onClick={this.handleReset}><FormattedMessage id='button.clear' /></Button>
                    <span onClick={this.handleShowMore} className="underline-cursor"><FormattedMessage id='event.history.query.hasMore' /></span>
                </FormItem>
            </Form>
        );
    }
}


export default EventQueryForm;

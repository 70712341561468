import { combineReducers } from 'redux';
import {
    GET_BOX_IP,
    SET_BOX_IP,
    SET_LANG_STATE,
    CHANGE_LANG_STATE,
    // GET_MODE,
    SET_MODE,
} from '../actions/actionTypes'
import initalState from '../initalState';


function IP_State(state = initalState.NetWork, action) {
    switch (action.type) {
        case GET_BOX_IP:
            return state;
        case SET_BOX_IP:
            return Object.assign({}, state, { ip: action.data });
        default:
            return state
    }
}
// function DeviceType_State(state = initalState.Device, action) {
//     switch (action.type) {
//         case GET_CAMERA_TYPE:
//             return state;
//         case SET_CAMERA_TYPE:
//             return Object.assign({}, state, { mode: state.mode, device_type: action.data, ota_type: state.ota_type })
//         case GET_MODE:
//             return state.mode
//         case SET_MODE:
//             return Object.assign({}, state, { mode: action.mode, device_type: state.device_type, ota_type: state.ota_type })
//         default:
//             return state
//     }
// }
function setMode(state = initalState.mode, action) {
    switch (action.type) {
        case SET_MODE:
            return action.mode
        default:
            return state
    }
}

function Lang(state = initalState.Lang, action) {
    switch (action.type) {
        case SET_LANG_STATE:
            return Object.assign({}, state, { lang: action.lang });
        case CHANGE_LANG_STATE:
            // 这样写不好，不符合设计原则，修改
            const lang = state.lang === 'en' ? 'zh' : 'en';
            localStorage.setItem('lang', lang);
            return Object.assign({}, state, { lang })
        default:
            break;
    }
    return state;
}
function OEM(state = initalState.OEM, action) {
    switch (action.type) {
        case 'UPDATE_OEM_CONFIG':
            return Object.assign({}, state, action.config)
        default:
            return state
    }
}

function systemMode(state = initalState.systemMode, action) {
    switch (action.type) {
        case 'SET_SYSTEM_MODE':
            return action.systemMode
        default:
            return state
    }
}
function init(state = initalState.init, action) {
    switch (action.type) {
        case 'SET_INIT':
            return action.init
        default:
            return state
    }
}
const reducers = combineReducers(
    {
        NetWork: IP_State,
        mode:setMode,
        Lang: Lang,
        OEM,
        systemMode:systemMode,
        init:init
    }
)
export default reducers
import React, { Component } from 'react'
import { Form, Input, Button, message } from 'antd'
// import Title from '../common/Title'
import $ from 'jquery'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { changePassword } from '../../services/login';
class NoCachePasswordInput extends Component {
    static getDerivedStateFromProps(nextProps) {
        if ('value' in nextProps) {
            return { inputValue: nextProps.value }
        }
        return { inputValue: undefined }
    }
    constructor(props) {
        super(props);
        const value = props.value || '';
        this.state = {
            inputValue: value
        }
    }
    handleChange = (e) => {
        const inputValue = e.target.value || '';
        if (!('value' in this.props)) {
            this.setState(
                { inputValue: inputValue }
            )
        }
        this.triggerChange(inputValue)
    }
    triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue)
        }
    }
    render() {
        return (
            <div>
                <input type='password' style={{ display: 'none' }} />
                <input type='text' style={{ display: 'none' }} />
                <Input type='password'
                    style={{ width: 240 }}
                    autoComplete='new-password'
                    readOnly
                    value={this.state.inputValue}
                    onFocus={(target) => { target.target.removeAttribute('readonly') }}
                    onBlur={(target) => { target.target.setAttribute('readonly', true) }}
                    onChange={this.handleChange} />
            </div>
        )
    }
}
class PasswordForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    if ($.trim(values.NewPassWord).length > 10) {
                        message.warning(intl.formatMessage({ id: 'settings.password.length' }))
                        return
                    }
                    if (values.OriginalPassword) {
                        if (values.NewPassWord !== values.NewPasswordR) {
                            message.error(intl.formatMessage({ id: 'settings.password.notMatch' }),1.5)
                            return
                        }
                        this.setState({
                            loading : true
                        })
                        changePassword({
                            username: sessionStorage.getItem('session'),
                            old_password: values.OriginalPassword,
                            new_password: values.NewPassWord
                        }).then(() => {
                            sessionStorage.clear();
                            window.location.href = '/';
                            this.setState({
                                loading : false
                            })
                        }).catch(() =>{
                            this.setState({
                                loading : false
                            })
                        })
                    }
                }
            }
        )
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit} autoComplete='new-password'>

                <Form.Item label={<FormattedMessage id='settings.password.oldpassword' />} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
                    {getFieldDecorator('OriginalPassword', {
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> },
                        { pattern: /(\S)/, message: <FormattedMessage id='input.required' /> }],
                    })(
                        <NoCachePasswordInput />
                    )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id='settings.password.newpassword' />} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
                    {getFieldDecorator('NewPassWord', {
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> },
                        { pattern: /(\S)/, message: <FormattedMessage id='input.required' /> }],
                    })(
                        <NoCachePasswordInput />

                    )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id='settings.password.repassword' />} labelCol={{ span: 9 }} wrapperCol={{ span: 15 }}>
                    {getFieldDecorator('NewPasswordR', {
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> },
                        { pattern: /(\S)/, message: <FormattedMessage id='input.required' /> }],
                    })(
                        <NoCachePasswordInput />

                    )}
                </Form.Item>
                <Form.Item wrapperCol={{ offset: 9 }}>
                    <Button type="primary" loading={this.state.loading} htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                </Form.Item>
            </Form>
        )
    }
}
const WrapperedForm = Form.create()(PasswordForm);
class Password extends Component {

    render() {
        return (
            <div>
                {/* <Title items={breadcrumbSetting} /> */}
                <div style={{ margin: 22, height: 450, paddingTop: 110, backgroundColor: '#FFFFFF' }}>
                    <div style={{ margin: '0 auto', width: 430 }}>
                        <WrapperedForm />
                    </div>
                </div>
            </div>
        )
    }
}

export default Password
import React, { Component } from 'react'
import Title from '../../common/Title';
import { Table, message, Modal, Form, Input, Button, Divider, Popover } from 'antd';
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../../Intel';
import Add from './Add';
import {getPermission,detailPermission,deletePermission} from '../../../services/permission'

const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/User/UserList',
    breadcrumbName: <FormattedMessage id='sidemenu.User' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.User.PermissionGroup' />
}];
class QueryForm extends Component {
    // ClearForm = () => {
    //     this.props.form.resetFields();
    //     this.props.query({});
    // }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let params = {}
                if (values.name) {
                    params['info.name'] = values.name;
                }

                this.props.query(params);
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;

        return (
            <Form layout='inline' onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='user.group.list.name' />} >
                    {getFieldDecorator('name', {

                    })(<Input style={{width:"200px"}}/>)}
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'><FormattedMessage id='button.query' /></Button>
                </Form.Item>
                {/* <Form.Item>
                    <Button onClick={this.ClearForm}><FormattedMessage id='button.clear' /></Button>
                </Form.Item> */}
            </Form>
        )
    }
}
const WrapperedQueryForm = Form.create()(QueryForm);
class PermissionGroup extends Component {
    constructor(props) {
        super(props);
        this.params = {
            page: 0,
            per_page: 10
        }
    }
    state = {
        data: [],
        editRow: null,
        modalVisible: false,
        selectedRowKeys: [],
        pagination: {}
    }
    componentDidMount() {
        this.fetch();
    }
    handleEdit = (row) => {
        this.setState({
            editRow: row,
            modalVisible: true
        })
    }
    onClickAdd = () => {
        this.setState({
            modalVisible: true
        })
    }
    handleModal = () => {
        this.setState({
            modalVisible: false,
            editRow: null
        })
    }
    handleAddOrEditSuccess = () => {
        this.handleModal();
        message.success(intl.formatMessage({ id: 'info.success' }),1);
        this.fetch();
    }
    getList = (_id) => {
        setTimeout(() => {
            return detailPermission(_id).then(
                (resp) => {
                    let group = resp.data;
                    let identities = group.identity_ids || [];
                    if (identities.length > 0) {
                        message.warning(intl.formatMessage({ id: 'permisson.delete.error' }));
                        return
                    }
                    deletePermission(_id).then(
                        () => {
                            message.success(intl.formatMessage({ id: 'info.success' }),1);
                            this.params.page = 0;
                            this.fetch();
                        }
                    )
                }
            ) 
        }, 3000);
       
    }
    deleteRecord = (_id) => {
        const _this = this;
        Modal.confirm({
            title: intl.formatMessage({ id: 'permisson.delete.title' }),
            content: intl.formatMessage({ id: 'permisson.delete.content' }),
            onOk() {
                if (_id === '0') {
                    message.error(intl.formatMessage({ id: 'permisson.delete.error' }))
                    return
                }
                return _this.getList(_id);
            }
        })
    }
    onClickClear = () => {
        this.setState({
            selectedRowKeys: []
        })
    }
    onRowSelectedChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }
    queryCallback = (params) => {
        this.params = params;
        this.fetch();
    }
    fetch = () => {
        this.params.page = this.params.page || 0;
        this.params.per_page = this.params.per_page || 10;
        getPermission({ params: this.params }).then(res => {
            this.setState({
                            data: res.data,
                            pagination: res.pagination,
                            selectedRowKeys: []
                        })
        })
        // axios.get(`${SOPHON_FACE}/permissions`, { params: this.params }).then(
        //     (resp) => {
        //         this.setState({
        //             data: resp.data.data,
        //             pagination: resp.data.pagination,
        //             selectedRowKeys: []
        //         })

        //     }
        // ).catch(
        //     (e) => {
        //         message.error(intl.formatMessage({ id: 'network.error' }));
        //         console.error('[query Groups failed]', e);
        //     }
        // )
    }
    add = () => {
        this.props.history.push('/User/PermissionGroup/Add');
    }
    columns_render = (text) => {
        return (
            <Popover content={text}>
                {text}
            </Popover>
        )
    }
    handleTableChange = (pagination) => {
        this.params.page = pagination.current - 1;
        this.fetch();
    }
    render() {
        const columns = [
            {
                title: <FormattedMessage id='user.group.list.groupID' />,
                dataIndex: '_id',
                align: 'left',
                width: 150,
                render: this.columns_render
            }, {
                title: <FormattedMessage id='user.group.list.name' />,
                dataIndex: 'info.name',
                align: 'left',
                width: 100,
                render: this.columns_render
            },
            {
                title: <FormattedMessage id='user.group.list.description' />,
                dataIndex: 'info.description',
                align: 'left',
                width: 150,
                render: this.columns_render
            },
            {
                title: <FormattedMessage id='user.group.list.action' />,
                key: 'action',
                align: 'left',
                width: 150,
                render: (text, record) => {
                    return (
                        <span>
                            <span className="cursor" onClick={() => this.handleEdit(record)}><FormattedMessage id='button.edit' /></span>
                            <Divider type="vertical" />
                            <span className="cursor" onClick={() => this.deleteRecord(record._id)}><FormattedMessage id='button.delete' /></span>
                        </span>
                    )
                }
            }
        ]
        // const rowSelection = {
        //     selectedRowKeys: this.state.selectedRowKeys,
        //     onChange: this.onRowSelectedChange
        // }
        return (
            <div>
                <Title items={breadcrumbSetting}></Title>
                <div style={{ margin: 24, padding:24, backgroundColor: '#FFFFFF',overflow:'auto' }}>
                    <div style={{marginBottom:20}}>
                        <WrapperedQueryForm query={this.queryCallback} />
                    </div>
                    {this.props.mode === 'mq' ? undefined : <div><Button type='primary' style={{ marginBottom: 20 }} onClick={this.onClickAdd}>+ <FormattedMessage id='button.add' /></Button></div>}

                    {/* <div style={{ margin: '16px 0' }}> */}
                        {/* <div data-show='true' className='ant-alert ant-alert-info'>
                            <i className="anticon anticon-info-circle ant-alert-icon"></i>
                            <span className="ant-alert-message" style={{ marginRight: 14 }}>
                                <span style={{ color: '#1890ff', margin: '0 5px' }}>{this.state.selectedRowKeys.length}</span><FormattedMessage id='permisson.alert.msg1' />
                            </span>
                            <span style={{ marginRight: 28 }}><FormattedMessage id='permisson.alert.msg2' values={{ number: this.state.pagination.total_count }} /></span>
                            <span className="cursor" onClick={this.onClickClear}><FormattedMessage id='button.clear' /></span>
                        </div> */}
                    {/* </div> */}
                    <Table
                        rowKey='_id'
                        columns={this.props.mode === 'mq' ? columns.slice(0, columns.length - 1) : columns}
                        dataSource={this.state.data}
                        // rowSelection={rowSelection}
                        pagination={{ showQuickJumper: true, current: this.params.page + 1, total: this.state.pagination.total_count }}
                        onChange={this.handleTableChange} />
                </div>

                <Modal
                    title={<FormattedMessage id='sidemenu.User.PermissionGroup.Add.edit' />}
                    footer={null}
                    destroyOnClose={true}
                    visible={this.state.modalVisible}
                    onCancel={this.handleModal}
                >
                    <Add handleModal={this.handleModal} entry={this.state.editRow} onSuccess={this.handleAddOrEditSuccess} />
                </Modal>

            </div>)
    }
}

export default connect(
    (state, ownProps) => {
        return {mode:state.mode}
    }
)(PermissionGroup)
import login from './en_US/login';
import Sidemenu from './en_US/Sidemenu';
import BasicInfo from './en_US/BasicInfo'; 
import Config from './en_US/Config';
import User from './en_US/User';
import Event from './en_US/Event';
import Settings from './en_US/Settings';
import Header from './en_US/Header';
import Setup from './en_US/Setup';
import Permission from './en_US/Permission';

export default {
    ...Setup,
    ...login,
    ...Header,
    ...Sidemenu,
    ...BasicInfo,
    ...Config,
    ...User,
    ...Event,
    ...Settings,
    ...Permission,
    "theme.type":'project type',
    "theme.park":'PARK',
    "device.select": 'Preview selection:',
    'device.select.first':'Please play the video before switching the page',
    'noDevicePlay': 'Please select preview device',
    'device.preview.network.tips.title':'Low network speed prompt',
    'device.preview.network.tips.content':'Your current network speed is {value}. For better preview effect, it is recommended to speed up to 1000Mb/s',
    'device.operation.frequent':'Frequent operation',
    'device.set.preview.error':"Setting error",
    "theme.school":'SCHOOL',
    "gate":"Gate",
    "captureCamera":"Capture Camera",
    "ipcGate":"Video camera(Gate)",
    "ipcStream":"Video camera(stream)",
    "device.add.exist":"Device already exists",
    "input.required":"Please input content",
    "select.required": "please choose",
    "add.error.maxLength":"Sorry, you can only add up to 16 channels",
    "network.error":"Network error",
    "process.error":"Process Error",
    "add.error":"Add failed",
    "add.error.play":"Please select the video you want to play first",
    "add.pld.play":"Please select the video you want to play",
    "edit.error":"Edit failed",
    "delete.error":"Delete failed",
    "info.none":"None",
    "info.unkown":"Unkown",
    "info.added":"Added",
    "info.nochange.warning": "No changes to parameters",
    "edit.success":"edit success",
    "info.relogin":"re-login",
    "info.restart":"Device will restart",
    "info.restart.content":"Device will restart after click 'OK'",
    "info.gate.restart": "The app will restart",
    "info.gate.restart.content": "The app will restart after clicking OK",
    "info.select":"please select items",
    "info.success":"success",
    "info.error": "Setup failed",
    "button.query":"Query",
    "button.clear":"Clear",
    "button.add":"Add",
    "button.save":"Save",
    "button.edit":"Edit",
    "button.delete":"Delete",
    "button.apply":"Apply",
    "button.cancel":"Cancel",
    "button.detail":"Detail",
    "button.tips":"{type} file",
    "button.expand":"Open",
    "button.close":"Close",
    "button.update":"Update",
    "button.back":"Back",
    "button.reset":"Reset",
    "url.invalid":"url is invalid",
    "ip.invalid":"IP adress is invalid",
    "hostname.invalid":"hostname is invalid",
    "port.invalid":"port is invalid",
    "connect.error":"Cannot connect device",
    "connect.error.workMode":"Cannot get work mode",
    "connect.error.deviceType":"Cannot get device type",
    "defaulthostname":"Please input hostname",
    "message.none":"None",
    "text.hour":"hr"
}
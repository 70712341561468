export default {
    "settings.network.connectionType":"网络连接方式",
    "settings.network.dynamic":"动态IP地址",
    "settings.network.static":"静态IP地址",
    "settings.network.ip":"ip地址",
    "settings.network.mask":"子网掩码",
    "settings.network.gateway":"网关",
    "settings.network.DNS":"DNS",
    "settings.network.validIP":"请输入正确的ip地址",
    "settings.network.change.title":"设备将会重启",
    "settings.network.speed.tips":"当前网速：{value}(建议网速1000Mb/s)",
    "settings.network.se3.tips":"SE3不支持LAN口设置",
    "settings.network.change.content":"IP被修改，设备需要重启来使设置生效",
    "settings.camera.type":"采集端类型",
    "settings.password.oldpassword":"旧密码",
    "settings.password.newpassword":"新密码",
    "settings.password.repassword":"重新输入新密码",
    "settings.password.length":"新密码长度不能多于10个字符",
    "settings.password.notMatch":"两次输入的新密码不一致",
    "settings.password.originalPasswordError":"原密码不正确",
    "settings.workmode.local":"单机模式",
    "settings.workmode.mq":"管理平台模式",
    "settings.workmode.mq.login_url.help":'请联系管理平台管理员获取地址。例如：http://xx.xx.xx.xx:9061/api/gateway/login',
    "settings.workmode.mq.notExist":'请求的授权码不存在',
    "settings.workmode.mq.get":"请联系管理平台管理员获取授权码",
    "settings.workmode.appia.url":"管理平台登录地址",
    "settings.workmode.appia.devsno":"本机授权码",
    "settings.workmode.change.title":"确定要切换工作模式吗？",
    "settings.workmode.mq.change.title":"确定要修改模式的配置吗？",
    "settings.workmode.mq.content":"修改配置会重启设备，并且可能会造成用户数据丢失!",
    "settings.workmode.change.content":"切换工作模式将会重置数据库并且重启设备或重启应用，您的用户数据将全部丢失!",
    "setting.box.restart.loading":'盒子正在重启中，请稍候...',
    "settings.IsSuportStranger.setSuccess":'设置陌生人自动入库成功',
    "settings.duplicateTime.setSuccess":'设置识别记录去重间隔成功',
    "settings.setThreshold.setSuccess":'匹配阈值设置成功',
    // "settings.threshold":'设置匹配阈值成功',
    "settings.threshold.invalid":"请输入一个0到100的整数",
    "settings.duplicateTime.invalid":"请输入一个1到60的整数",
    "settings.event.realTime.tag":"实时事件上传",
    "settings.event.tips":"可以输入多个url，使用','隔开",
    "settings.event.url":"服务器接口url",
    "settings.event.History.tag":"历史事件上传",
    "settings.event.History.peroid":"上报时间间隔",
    "settings.event.History.seconds":"秒",
    "settings.event.contentType":"数据类型",
    "settings.update.public.tag":"公有云升级",
    "settings.update.private.tag":"私有云升级",
    "settings.update.local.tag":"本地升级",
    "settings.update.currentVersion":"当前版本",
    "settings.update.latestVersion":"最新版本",
    "settings.update.privateUrl":"OTA服务器地址",
    "settings.update.local.title":"支持上传zip、tar或者tgz格式的文件",
    "settings.update.local.msg":"请将升级包做成zip、tar或者tgz格式文件，并且要将升级包重命名为版本名，版本命名遵循VxRxCxx这样的格式",
    "settings.update.local.speed":"{percent}%, 速度: {speed} M/s",
    "settings.update.local.Upload":"上传",
    "settings.update.local.updating...":"更新中...",
    "settings.update.local.checking file...":"正在检查文件...",
    "settings.update.local.Reading file...":"正在读取文件...",
    "settings.update.local.Uploading file...":"正在上传文件...",
    "settings.update.public.updating":"{msg},请不要重启或关闭设备!!!",
    "settings.update.public.noVersion":"公有云没有可供更新的最新版本:{msg}",
    "settings.update.private.url":"url 设置成功",
    "settings.update.local.readFileError":"文件读取错误",
    "settings.update.local.updating":"设备正在升级，请不要重启或者关闭设备!",
    "settings.update.local.versionNotMatch":"文件名不满足版本命名规则",
    "settings.update.local.versionOld":"上传的版本必须高于当前版本",
    "settings.update.local.fileError":"请上传zip、tar或者tgz文件",
    "settings.relogin.content":"需要重新登录来生效配置",
    "settings.relogin.ipc.content":"本次切换设备类型会重置数据库并且重启设备",
    "settings.update.pulic":"公有云升级配置将会覆盖私有云升级配置",
    "settings.update.private":"私有云升级配置将会覆盖公有云升级配置",
    "settings.update.error":"设备升级失败!",
    "settings.update.complete":"设备升级成功!",
    "settings.update.version":"当前版本为: {version}",
    "settings.time.ntp.label":"ntp服务",
    "settings.time.manual.label":"手动设置",
    "settings.time.manual.date":"日期",
    "settings.time.manual.time":"时间",
    "settings.time.currenttime":"当前时间",
    "settings.time.type":"类型",
    "settings.time.stay":"在",
    "settings.time.manual.warning.required":"必须要设置日期或者时间",
    "settings.monitor.url":"状态管理服务器URL",
    "settings.event.url.placeholder":`输入一系列服务器URL，各个URL之间以","分隔`,
    "settings.event.peroid":"大于{peroid}秒",
    "settings.event.peroid.format":"请输入一个大于等于{peroid}的整数",
    "settings.event.dev_sno":"dev_sno配置",
    "settings.event.login_url":"login_url配置",
    "settings.event.cloud_server_address":"云平台服务器地址",
    "settings.event.mq.tag":"消息队列配置",

    "settings.reboot.weeks.never":'永不',
    "settings.reboot.weeks.daily":'每日',
    "settings.reboot.weeks.monday":'每周一',
    "settings.reboot.weeks.tuesday":'每周二',
    "settings.reboot.weeks.wednesday":'每周三',
    "settings.reboot.weeks.thursday":'每周四',
    "settings.reboot.weeks.friday":'每周五',
    "settings.reboot.weeks.saturday":'每周六',
    "settings.reboot.weeks.sunday":'每周日',
    "settings.usage.type":'用途',
    "settings.usage.normal" : '通用类型',
    "settings.usage.fiqa" :'FIQA专用',
    "settings.usage.feature":'特征提取专用',

    "setting.smart.attributes.tab.title": '人脸属性',
    "setting.smart.recognition.tab.title": '人脸识别参数',
    "setting.smart.capture.tab.title": '人脸抓拍参数',
    "setting.smart.capture.form.mode.applyMode": '开启抓拍模式',
    "setting.smart.capture.form.time": '抓拍时间段{value}',
    "setting.smart.capture.form.threshold": '灵敏度',
    "setting.smart.capture.form.grabMode": '抓拍模式',
    "setting.smart.capture.form.grabMode.speed": '快速抓拍',
    "setting.smart.capture.form.grabMode.quality": '质量优先',
    "setting.smart.capture.form.photoUpload": '图片上传',
    "setting.smart.capture.form.photoUpload.face": '人脸图',
    "setting.smart.capture.form.photoUpload.back": '背景大图',
    "setting.smart.capture.form.photoUpload.backFace": '人脸图+背景大图',

    "setting.smart.capture.form.qualityFilter": '质量过滤标准',
    "setting.smart.capture.form.qualityFilter.off": '关',
    "setting.smart.capture.form.qualityFilter.low": '低标准',
    "setting.smart.capture.form.qualityFilter.middle": '中标准',
    "setting.smart.capture.form.qualityFilter.high": '高标准',

    "setting.smart.capture.form.clarity": '清晰度',
    "setting.smart.capture.form.brightness": '亮度',
    "setting.smart.capture.form.occlude": '遮挡',
    "setting.smart.capture.form.pitch": '俯仰角',
    "setting.smart.capture.form.yaw": '偏航角',
    "setting.smart.capture.form.roll": '翻滚角',

    "setting.smart.recognition.form.time.baseStringer":'陌生人库容量',
    "setting.smart.recognition.form.time.baseUser":'人员底库容量',
    "setting.smart.recognition.formItem.reboot.tips":'（修改容量设备会自动重启，调低容量会清空数据库;清空范围包括：人员、陌生人、设备列表、事件、权限组等）',
    "setting.smart.recognition.form.time.thousand":'{value} 万',
    "setting.smart.capture.form.time.require":"请填写抓拍时间",
    "setting.smart.recognition.form.time.require":"请填写布防时间",
    "setting.smart.recognition.form.applyMode": '开启识别模式',
    "setting.smart.recognition.form.IsSuportStranger":"陌生人自动入库",
    "setting.smart.recognition.form.duplicateTime":"识别记录去重间隔",
    "setting.smart.recognition.form.threshold":"匹配阈值",
    "setting.smart.recognition.form.regTimePart":"布防时间段{value}",
    "setting.smart.recognition.form.regMinFace":"最小人脸像素", 
    "setting.smart.recognition.form.regMinFace.invalid":"请输入一个20到300的整数", 

    "setting.smart.attributes.form.applyMode": '开启属性检测',
    "setting.smart.attributes.form.gender": '性别',
    "setting.smart.attributes.form.age": '年龄',
    "setting.smart.attributes.form.glasses": '眼镜',
    "setting.smart.attributes.form.mask": '口罩',
    "setting.smart.attributes.form.hat": '帽子',
    "setting.smart.attributes.form.Sunglasses": '墨镜',

    "setting.system.log.get.sure":'确定开始收集日志',
    "setting.system.log.get.tips.content":'收集日志将花费几分钟时间，收集完成请到固定路径查看',
    "setting.system.log.get.collect":'日志收集',
    "setting.system.log.get.timeTips":'收集到的日志压缩文件会自动保存到浏览器下载目录',
    "setting.system.log.get.user":'导出用户日志',
    "setting.system.log.get.system":'导出系统日志',
    "setting.system.tytd.title":'TTYD',

    "setting.system.cellular.rareFlow":'移动数据',
    "setting.system.cellular":'蜂窝数据',
    "setting.system.cellular.rareFlow.tips":'(启动后，产生的流量将由运营商收取相应费用)',
    "setting.system.cellular.rareFlow.connectType":'优先连接方式',

    "setting.system.cellular.rareFlow.noSignal":'无信号',
    "setting.system.cellular.rareFlow.connected":'已连接',
    "setting.system.cellular.rareFlow.noCard":'未检测到卡',
    "setting.system.cellular.rareFlow.signalCheck":'信号搜索中...',
    "setting.system.cellular.connectType.check":'(获取中...)',
    

    "setting.system.cellular.rareFlow.connectType.flow":'优先数据流量',
    "setting.system.cellular.rareFlow.connectType.broadband":'优先有线宽带',
    "setting.system.cellular.rareFlow.connectType.flow.tips":'(优先使用数据流量，若数据流量故障，需要手动切换至有线宽带)',
    "setting.system.cellular.rareFlow.connectType.broadband.tips":'(优先使用有线宽带，若有线宽带故障，需要手动切换至数据流量)'
}
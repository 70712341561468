import React, { Component } from 'react';
import { Form, Input, Button} from 'antd'
// import $ from 'jquery'
// import axios from '../../../utils/AxiosWrapper'
// import { SOPHON_FACE } from '../../../utils/Config'
import { FormattedMessage } from 'react-intl';
// import { intl } from '../../../Intel';
import {addPermission,editPermission} from '../../../services/permission'

// const prefix = SOPHON_FACE;
class EditForm extends Component {
	handleSubmit = (e) => {
		e.preventDefault();
		this.props.form.validateFields(
			(err, values) => {
				if (!err) {
				
					if (this.props.entry){
						// 编辑
						editPermission(this.props.entry._id,{info:values}).then(() => {
							this.props.onSuccess();
						})

					}else{
						addPermission({info:values}).then(() => {
							this.props.onSuccess();
						})
					}
					// let data = {}
					// if (!$.trim(values.name)) {
					// 	return
					// }
					// else {
					// 	data.name = values.name
					// }
					// if (values.description) {
					// 	data.description = values.description || ''
					// }
					// if (this.props.entry) {
					// 	// 编辑
					// 	axios.put(`${prefix}/permissions/${this.props.entry._id}`, {
					// 		info: data
					// 	}).then(() => {
					// 		this.props.onSuccess();
					// 	}
					// 	).catch((e) => {
					// 		message.error(intl.formatMessage({ id: 'add.error' }));
					// 	})

					// } else {
					// 	// 新增
					// 	axios.get(`${prefix}/permissions`, {
					// 		params: {
					// 			"info.name": values.name,
					// 			exact: true
					// 		}
					// 	}).then(
					// 		(resp) => {
					// 			if (resp.data.data.length > 0) {
					// 				message.warning(intl.formatMessage({ id: 'permisson.add.exsit' }))
					// 				return  false;
					// 			}
					// 			axios.post(`${prefix}/permissions`, {
					// 				info: data
					// 			}).then(() => {
					// 				this.props.onSuccess();
					// 			}
					// 			).catch((e) => {
					// 				message.error(intl.formatMessage({ id: 'add.error' }));
					// 			})
					// 		})
					// }
				}
			})
	}
	render() {
		const { getFieldDecorator } = this.props.form;
		return (
			<Form onSubmit={this.handleSubmit} >
				<Form.Item label={<FormattedMessage id='user.group.list.name' />} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					{getFieldDecorator('name', {
						rules: [{ required: true, message: <FormattedMessage id='input.required' /> }, {
							pattern: /[\S]/, message: `please don't input space only`
						}],
						initialValue: this.props.entry ? this.props.entry.info.name : undefined
					})(
						<Input />
					)}
				</Form.Item>
				<Form.Item label={<FormattedMessage id='user.group.list.description' />} labelCol={{ span: 6 }} wrapperCol={{ span: 18 }}>
					{getFieldDecorator('description', {
						initialValue: this.props.entry ? this.props.entry.info.description : undefined
					})(
						<Input.TextArea rows={4} />
					)}
				</Form.Item>
				<Form.Item wrapperCol={{ offset: 6 }}>
					<Button type='primary' style={{ marginRight: 11 }} onClick={this.handleSubmit}><FormattedMessage id='button.apply' /></Button>
					<Button onClick={this.props.handleModal}><FormattedMessage id='button.cancel' /></Button>
				</Form.Item>
			</Form>
		)

	}
}
const WrapperedEditForm = Form.create()(EditForm);
class UserGroupAdd extends Component {
	render() {
		return (
			<div>
				<div style={{ backgroundColor: '#FFFFFF', margin: '26px 36px' }}>
					<WrapperedEditForm {...this.props} />
				</div>
			</div>
		)
	}
}

export default UserGroupAdd
import React, { Component } from 'react';
import { Radio, Form, Input, Button, DatePicker, TimePicker, message, Layout, Row, Col } from 'antd';
import moment from 'moment';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import {
    getTimeSelectMode,
    setTimeSelectMode,
    getTime,
    setTime,
    getNtpConfig,
    setNtpConfig
} from '../../services/system';

moment.locale('zh-cn');


const FormLayout = {
    labelCol: {
        span: 8
    },
    wrapperCol: {
        span: 14
    }
}
class NtpForm extends Component {
    state = {
        ntpConfig: '',
        loading:false
    }

    componentDidMount() {
        this.getNtpConfig()
    }

    getNtpConfig = () => {
        getNtpConfig().then(
            (resp) => {
                this.setState({
                    ntpConfig: resp.data.ntp_config
                })
            }
        )
    }
    submit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    let zoneOffset = new Date().getTimezoneOffset() / 60;
                    this.setState({
                        loading : true
                    })
                    setTimeSelectMode({ time_select_mode: '0' }).then(() => {
                        setNtpConfig({ ntp_config: values.ntpserver, timezone: zoneOffset }).then(
                            () => {
                                message.success(intl.formatMessage({ id: 'info.success' }), 1);
                                this.props.refreshTime();
                                this.setState({
                                    loading : false
                                })
                            }
                        ).catch(() => {
                            this.setState({
                                loading : false
                            }) 
                        })
                    }).catch(() => {
                        this.setState({
                            loading : false
                        })
                    })
                }
            }
        )
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        const { ntpConfig } = this.state;
        return (
            <Form onSubmit={this.submit}>
                <Form.Item label={<FormattedMessage id='settings.time.ntp.label' />} {...FormLayout}>
                    {getFieldDecorator('ntpserver', {
                        initialValue: ntpConfig,
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> }]
                    })(
                        <Input.TextArea></Input.TextArea>
                    )}
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button type='primary' loading={this.state.loading} htmlType='submit'><FormattedMessage id='button.apply' /></Button>
                </Form.Item>
            </Form>
        )
    }
}

const WrapperedNtpForm = Form.create()(NtpForm)

class SetTimeForm extends Component {
    state = {
        loading: false
    }
    submit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                const date = values.date;
                const time = values.time;
                if (!date && !time) {
                    message.warning(intl.formatMessage({ id: 'settings.time.manual.warning.required' }));
                    return
                }
                let set_time = '';
                if (date) {
                    set_time = set_time.concat(date.format('YYYY-MM-DD'));
                }
                if (time) {
                    set_time = set_time.concat(' ').concat(time.format('HH:mm:ss'));
                }
                set_time = set_time.replace(/^\s+|\s+$/g, '')//trim space
                set_time = new Date(set_time).getTime();
                let zoneOffset = new Date().getTimezoneOffset() / 60;
                this.setState({
                    loading: true
                })
                setTimeSelectMode({ time_select_mode: '1' }).then(() => {
                    setTime({ time: set_time, timezone: zoneOffset }).then(() => {
                        message.success(intl.formatMessage({ id: 'info.success' }), 1);
                        this.props.refreshTime();
                        this.setState({
                            loading : false
                        })
                    }).catch(() => {
                        this.setState({
                            loading : false
                        })
                    }) 
                }).catch(() => {
                    this.setState({
                        loading : false
                    })
                }) 
            }
        })
    }

    render() {
        const { getFieldDecorator } = this.props.form;
        const { systemTime } = this.props;
        return (
            <Form onSubmit={this.submit}>
                <Form.Item label={<FormattedMessage id='settings.time.manual.date' />} {...FormLayout}>
                    {getFieldDecorator('date', {
                        initialValue: moment(systemTime, "x")
                    })(
                        <DatePicker></DatePicker>
                    )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id='settings.time.manual.time' />} {...FormLayout}>
                    {getFieldDecorator('time', {
                        initialValue: moment(systemTime, 'x')
                    })(
                        <TimePicker></TimePicker>
                    )}
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button loading={this.state.loading} type='primary' htmlType='submit'><FormattedMessage id='button.apply' /></Button>
                </Form.Item>
            </Form>
        )
    }
}
const WrapperedSetTimeForm = Form.create()(SetTimeForm)
class TimeSetting extends Component {
    state = {
        type: '',
        systemTime: ''
    }
    componentDidMount() {
        this.getSystemTime();
        this.interval = setInterval(
            () => {
                const currentTime = moment(this.state.currentTime, "x").add(1, 's');
                const currentTimeText = currentTime.format("YYYY-MM-DD HH:mm:ss");
                this.setState({
                    currentTime,
                    currentTimeText
                })
            }, 1000)
    }
    componentWillUnmount() {
        clearInterval(this.interval)
    }

    getSystemTime = () => {
        getTime().then(res => {
            const currentTimeText = moment(res.data.time, "x").format("YYYY-MM-DD HH:mm:ss");
            this.setState({
                systemTime: res.data.time,
                currentTime:res.data.time,
                currentTimeText:currentTimeText
            })
        })
        getTimeSelectMode().then(res => {
            //"0":ntp服务设置时间模式。
            // "1":手动设置时间模式
            this.setState({
                type: res.data.time_select_mode
            })
        })
    }
    onChange = (e) => {
        this.setState({
            type: e.target.value
        })
    }

    getForm = () => {
        const { type } = this.state;
        if (type === '0') {
            return <WrapperedNtpForm refreshTime={this.getSystemTime} />
        }
        if (type === '1') {
            return <WrapperedSetTimeForm systemTime={this.state.systemTime} refreshTime={this.getSystemTime} />
        }
        return null
    }

    render() {
        return (
            <React.Fragment>
                <Layout style={{ margin: '24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 100 }}>
                    <div style={{ width: '60%', margin: '0 auto' }}>
                        <Row className='.ant-form-item'>
                            <Col span={FormLayout.labelCol.span} className='ant-form-item-label'><label><FormattedMessage id='settings.time.currenttime' /></label></Col>
                            <Col span={FormLayout.wrapperCol.span} className='ant-form-item-control-wrapper ant-form-item-control'>{this.state.currentTimeText}</Col>
                        </Row>
                        <Row className='.ant-form-item'>
                            <Col span={FormLayout.labelCol.span} className='ant-form-item-label'><label><FormattedMessage id='settings.time.type' /></label></Col>
                            <Col span={FormLayout.wrapperCol.span} className='ant-form-item-control-wrapper ant-form-item-control'>
                                <Radio.Group onChange={this.onChange} value={this.state.type} style={{ margin: '0 auto' }}>
                                    <Radio value='0'><FormattedMessage id='settings.time.ntp.label' /></Radio>
                                    <Radio value='1'><FormattedMessage id='settings.time.manual.label' /></Radio>
                                </Radio.Group>
                            </Col>
                        </Row>
                        {this.getForm()}
                    </div>
                </Layout>
            </React.Fragment>
        )
    }
}

export default TimeSetting
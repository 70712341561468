import api from '../utils/api';
const SOPHON_SYSTEM = "/SophonSystem/api";

const userLogin = ({username,password}) => api({
    url:`${SOPHON_SYSTEM}/login`,
    data:{username,password},
    method: 'post',
}).then(res => res.data);

const changePassword = ({username,old_password,new_password}) => api({
    url:`${SOPHON_SYSTEM}/password`,
    data:{username,new_password,old_password},
    method: 'post',
}).then(res => res.data);
export {
    userLogin,
    changePassword
}
import React, { Component } from 'react'
import { Tabs, Layout } from 'antd'
import { FormattedMessage } from 'react-intl';
import Title from '../common/Title'
import TimeSetting from './TimeSetting'
import Password from './Password'
import Hostname from './Hostname'
// import ClientTheme from './ClientTheme'
import RebootSetting from './RebootSetting'
import DebugSetting from './DebugSetting';

const { TabPane } = Tabs;
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.SystemSetting' />
}];
class SystemSetting extends Component {
    render() {
        return (
            <React.Fragment>
                <Title items={breadcrumbSetting} />
                <Layout style={{ margin: '24px', backgroundColor: '#FFFFFF', minHeight: '420px' }}>
                    <Tabs defaultActiveKey="1">
                        <TabPane tab={<FormattedMessage id="sidemenu.System.TimeSetting" />} key="1">
                            <TimeSetting />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id="sidemenu.System.ChangePassword" />} key="2">
                            <Password />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id="sidemenu.System.Hostname" />} key="3">
                            <Hostname />
                        </TabPane>
                        {/* <TabPane tab={<FormattedMessage id="sidemenu.System.ClientTheme" />} key="4">
                            <ClientTheme />
                        </TabPane> */}
                        <TabPane tab={<FormattedMessage id="sidemenu.System.RebootSetting" />} key="5">
                            <RebootSetting />
                        </TabPane>
                        <TabPane tab={<FormattedMessage id="sidemenu.System.DebugSetting" />} key="6">
                            <DebugSetting />
                        </TabPane>
                    </Tabs>
                </Layout>
            </React.Fragment>
        )
    }
}

export default SystemSetting
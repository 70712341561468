import React, { Component } from 'react';
import { Alert, Form, Button, Input, message } from 'antd'
import axios from '../../utils/AxiosWrapper'
import { SOPHON_SYSTEM, HOSTNAME_PATTERN } from '../../utils/Config';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
class EditForm extends Component {
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {

                    axios.post(`${SOPHON_SYSTEM}/hostname`, { hostname: values.Hostname }).then(
                        (resp) => {

                            this.props.history.push('/SetupLan')
                        }
                    ).catch(
                        (e) => { message.error(intl.formatMessage({id:'network.error'}));; console.error(e) }
                    )
                }
            }
        )
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='sidemenu.System.Hostname'/>} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('Hostname', {
                        rules: [
                            { required: true, message: <FormattedMessage id='input.required'/> },
                            { pattern: HOSTNAME_PATTERN, message: <FormattedMessage id='hostname.invalid'/> },
                            // { max: 30 },
                            { whitespace: true }
                        ],
                    })(
                        <Input maxLength={30} placeholder={intl.formatMessage({id:'defaulthostname'})} />
                    )}
                </Form.Item>
                <Form.Item style={{ textAlign: 'center' }}>

                    <Button type="primary" htmlType="submit"><FormattedMessage id='setup.next'/></Button>
                    <Button style={{ marginLeft: 8 }}
                        onClick={() => {
                            // axios.post(`${SOPHON_SYSTEM}/hostname`, {
                            //     hostname: 'BM1682'
                            // })
                            this.props.history.push('/SetupLan')
                        }}><FormattedMessage id='setup.skip'/></Button>
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);
class Host extends Component {
    state = {
        seconds: 5
    }
    refsDom = null
    componentDidMount() {
        const _this = this;
        this.timer = setInterval(() => {
            _this.setState({
                seconds: _this.state.seconds - 1
            });
            if (this.state.seconds < 0) {
                _this.refsDom.setAttribute('style', 'display:none');
                clearInterval(_this.timer);
            }

        }, 1000);
    }
    componentWillUnmount() {
        clearInterval(this.timer);
    }

    render() {
        return (
            <div>
                <div style={{ margin: '18px 0' }}>
                    <div ref={el => this.refsDom = el}><Alert message={<FormattedMessage id='setup.hostname.alert' values={{seconds:this.state.seconds}}/>} showIcon /></div>
                </div>
                <div style={{ backgroundColor: '#FFFFFF', padding: '48px 143px 33px 143px', marginBottom: 242 }}>
                    <WrapperedEditForm {...this.props} />
                </div>
            </div>
        )
    }
}

export default Host
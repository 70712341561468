export default {
    "login.title":"Login",
    "login.submit":"Login",
    "login.footer.help":"Help",
    "login.footer.privacy":"Privacy",
    "login.footer.terms":"Terms",
    "login.username.placeholder":"Admin/User",
    "login.password.placeholder":"Password",
    "login.error":"Password or username not correct",
    "login.mq.deviceKey.error":"Please enter the working mode of the system to reconfigure",
    "login.mq.deviceKey.error.reason":"Management platform login request failure reason:"
}
import React, { Component } from 'react';
import { List, message } from 'antd';
import moment from 'moment/moment';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { USER_TYPE, USER_TYPE1 } from '../../utils/Config';
import axios from '../../utils/AxiosWrapper';
import { getTheme } from '../../services/system'
class HistoryList extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            currentPage: 1,
            total: 12,
            maxDatetime: 0,
            list: [],
            pageSize: 16,
            loading: false,
            all_num: 0,
        }
    }

    componentDidMount = () => {
        getTheme().then(res => {
            this.theme = res
            this.streamList();
        })

    }
    formatTime = value => moment(value).format('YYYY-MM-DD HH:mm:ss');
    onPageChange = (currentPage) => {
        this.setState({ currentPage }, () => {
            this.streamList();
        })
    }
    streamList = () => {
        axios.get(`/SophonFace/api/events`, { params: { identity_id: this.props.userInfo.identity_id, per_page: this.state.pageSize, page: this.state.currentPage - 1 } }).then(
            (resp) => {
                if (resp.data) {
                    this.setState({
                        list: resp.data.data,
                        loading: false,
                        all_num: resp.data.pagination.total_count
                    });
                    //this.handleRelativeQueryAndCache(resp.data.result);
                    let allDatetime = [];
                    resp.data.data.forEach(item => {
                        allDatetime.push(item.timestamp)
                    });
                    let maxDatetime = parseInt(Math.max(...allDatetime) * 1000);
                    this.setState({
                        maxDatetime
                    })
                }

            }
        ).catch(
            (e) => {
                console.error('[GET Events Error]', e);
                message.error(intl.formatMessage({ id: "network.error" }));
                this.setState({ loading: false, data: [], all_num: 0 });
            }
        )
    }
    render() {
        const userInfo = this.props.userInfo;
        let NEW_USER_TYPE = null;
        if (this.theme === 'PARK') {
            NEW_USER_TYPE = USER_TYPE;
        } else {
            NEW_USER_TYPE = USER_TYPE1;
        }
        let listEle =
            <List
                loading={this.state.loading}
                bordered={false}
                grid={{ gutter: 24, column: 4, xxl: 4 }}
                dataSource={this.state.list}

                pagination={{
                    pageSize: this.state.pageSize,
                    total: this.state.all_num,
                    onChange: this.onPageChange,
                    current: this.state.currentPage,
                    defaultCurent: this.state.currentPage
                }}
                renderItem={item => {
                    return (
                        (
                            <List.Item>
                                <div className="content">
                                    <div className="img-box-one" style={{ backgroundImage: `url(/SophonFace/api/event_images/${item.common.recognize_face_pic_id}?datetime=${item.timestamp})` }}></div>
                                    <p>{item.time ? this.formatTime(parseInt(item.time * 1000)) : ''}</p>
                                </div>
                            </List.Item>
                        )
                    )
                }}
            >
            </List>
        return (
            <div className="user-history-list">
                <div className="title-pic">
                    <div className="left" style={{ backgroundImage: `url(SophonFace/api/images/${userInfo.imageId}?t=${+new Date()})` }}></div>
                    <div className="right">
                        <p>
                            <span>{NEW_USER_TYPE.find((e) => { return e.typeId === parseInt(userInfo.userType) }) ? <FormattedMessage id={`user.add.type.${NEW_USER_TYPE.find((e) => { return e.typeId === parseInt(userInfo.userType) }).description}`} /> : ''}</span>
                            <span>:{userInfo.userName}</span>
                        </p>
                        <p><FormattedMessage id="user.history.lastDatetime" /></p>
                        <p>{this.state.maxDatetime ? this.formatTime(this.state.maxDatetime) : "无"}</p>
                    </div>
                </div>
                {listEle}
            </div>
        )
    }

}

export default HistoryList
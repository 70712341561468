import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl';

class Footer extends Component {
    render() {
        return (
            <div style={{ position:"fixed",bottom:"0px",right:0,left:0,height:50,padding:"10px", backgroundColor:"#fafafa", color: 'RGBA(0, 0, 0, 0.45)' }}>
                <div className='footer-link'>
                    <div><FormattedMessage id='login.footer.help'/></div>
                    <div><FormattedMessage id='login.footer.privacy'/></div>
                    <div><FormattedMessage id='login.footer.terms'/></div>
                </div>
            </div>
        )
    }
}

export default Footer
import React, { Component } from 'react'
import { Modal, Form, Select, Input, Button, Alert, message } from 'antd'
import { URL_PATTERN } from '../../utils/Config';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { getConfig, setConfig, setIp, setConfigs } from '../../services/system';
class EditForm extends Component {
    state = {
        type: '',
        loginUrl: '',
        devSno: '',
        workMode: '',
        eth0: {},
        loading: false,
        loginRes: { code: 0, message: '' }
    }
    componentDidMount() {
        getConfig('WORK_MODE').then(res => {
            if (res.WORK_MODE === 'mq') {
                getConfig(`LOGIN_URL`).then(res => {
                    this.setState({
                        loginUrl: res.LOGIN_URL
                    })
                })
                getConfig(`DEV_SNO`).then(res => {
                    this.setState({
                        devSno: res.DEV_SNO
                    })
                })
                getConfig(`LOGIN_STATUS`).then(res => {
                    let data = { code: res.LOGIN_STATUS.code, message: '' };
                    if (data.code !== 0) {
                        data.message = localStorage.getItem('lang') === 'en' ? res.LOGIN_STATUS.message : res.LOGIN_STATUS.chinese_message;
                        this.setState({
                            loginRes: data
                        })
                    }
                })
            }
            this.setState({
                type: res.WORK_MODE,
                workMode: res.WORK_MODE
            })
        })
    }

    /**
     * 说明：因为修改work mode会造成小盒子自动重启，修改ip也会造成小盒子重启，所以目前只能暂时采取这样的策略：
     * 1.在本页面统一修改ip和work mode
     * 2.如果修改了ip，则先修改ip且传重启参数，然后修改work mode,依靠修改ip接口重启小盒子
     * 3.如果没有修改ip但是修改了work mode，则修改ip不传重启参数，然后修改work mode，依靠work mode接口重启
     * 
     */
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    let restart = false;
                    if (this.props.location.eth0) {
                        restart = true;
                        this.reIp = this.props.location.eth0.IP;
                    }
                    //工作模式，config，ip都没变 ，直接重启
                    if ((this.state.workMode === values.mode && !restart && values.mode === 'local') || (values.mode === 'mq' && !restart && this.state.loginUrl === values.loginUrl && this.state.devSno === values.devSno)) {

                        Modal.confirm({
                            title: intl.formatMessage({ id: 'settings.network.change.title' }),
                            // content: intl.formatMessage({ id: restart ? 'settings.network.change.content' : (data.WORK_MODE ? 'settings.workmode.change.content' : 'settings.workmode.mq.content') }),
                            // okType: 'danger',
                            onOk: () => {
                                this.setState({
                                    loading: true
                                })
                                setConfig({ "INIT": 1 }).then(res => {
                                    this.setState({
                                        loading: false
                                    })
                                    message.loading(intl.formatMessage({ id: 'setting.box.restart.loading' }), 1);
                                    this.logout();
                                }).catch(() => {
                                    this.setState({
                                        loading: false
                                    })
                                })
                            }

                        })

                    } else {
                        let data = {};
                        if (this.state.workMode !== values.mode) {
                            data.WORK_MODE = values.mode;
                        }
                        if (values.mode === 'mq' && this.state.loginUrl !== values.loginUrl) {
                            data.LOGIN_URL = values.loginUrl;
                        }
                        if (values.mode === 'mq' && this.state.devSno !== values.devSno) {
                            data.DEV_SNO = values.devSno;
                        }
                        data.INIT = 1;
                        Modal.confirm({
                            title: intl.formatMessage({ id: 'settings.network.change.title' }),
                            // content: intl.formatMessage({ id: restart ? 'settings.network.change.content' : (data.WORK_MODE ? 'settings.workmode.change.content' : 'settings.workmode.mq.content') }),
                            // okType: 'danger',
                            onOk: () => {
                                this.setState({
                                    loading: true
                                })
                                if (restart) { //设置IP
                                    if (data.WORK_MODE || data.DEV_SNO || data.LOGIN_URL) {
                                        this.editIp(this.props.location.eth0 || {}, false);
                                    } else {
                                        this.editIp(this.props.location.eth0 || {}, true); //先设置ip 不重启,再设置工作模式
                                    }
                                }
                                setConfigs({ "config_dict": data }).then(() => {
                                    this.setState({
                                        loading: false
                                    })
                                    message.loading(intl.formatMessage({ id: 'setting.box.restart.loading' }), 1);
                                    this.logout();
                                }).catch(() => {
                                    this.setState({
                                        loading: false
                                    })
                                })
                            }
                        })

                    }
                }
            }
        )
    }
    logout = () => {
        // sessionStorage.clear();
        if (this.reIp) {
            window.location.href = "http://" + this.reIp;
        } else {
            window.location.href = '/';
        }
    }
    setIp = (config, isReboot) => {
        if (config.status === 'dynamic') {
            setIp({
                device: "eth0",
                isreboot: isReboot,
                status: 'dynamic'
            })
        } else {
            setIp({
                new_ip: config.IP,
                device: "eth0",
                status: 'static',
                netmask: config.SubnetMask,
                gateway: config.Gateway,
                dns: config.dns,
                isreboot: isReboot
            })
        }
    }
    editIp = async (eth0 = {}, isReboot) => {
        console.log('----start edit ip---');
        await this.setIp(eth0, isReboot);
        console.log('----edit ip finish---')
    }
    handleSelectChange = (value) => {
        this.setState(
            { type: value }
        )
    }
    render() {
        const loginRes = this.state.loginRes;
        const { getFieldDecorator } = this.props.form;
        const configDom = this.state.type === 'mq' ?
            <div>
                <Form.Item label={<FormattedMessage id='appia.url' />} labelCol={{ span: 8 }} help={<FormattedMessage id='settings.workmode.mq.login_url.help' />} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('loginUrl', {
                        rules: [
                            { required: true, message: <FormattedMessage id='input.required' /> },
                            { pattern: URL_PATTERN, message: <FormattedMessage id='url.invalid' /> }
                        ],
                        initialValue: this.state.loginUrl
                    })(
                        <Input></Input>
                    )}
                </Form.Item>
                <Form.Item
                    // validateStatus={loginRes.code ? "error" : ''}
                    label={<FormattedMessage id='settings.workmode.appia.devsno' />}
                    labelCol={{ span: 8 }}
                    wrapperCol={{ span: 16 }}
                    help={loginRes.code ? <span className="red">{loginRes.message}</span> : <FormattedMessage id='settings.workmode.mq.get' />}>
                    {getFieldDecorator('devSno', {
                        rules: [
                            { required: true, message: <FormattedMessage id='input.required' /> }
                        ],
                        initialValue: this.state.devSno
                    })(
                        <Input></Input>
                    )}
                </Form.Item>
            </div> : null;

        return (
            <Form onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='sidemenu.System.WorkMode' />} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                    {getFieldDecorator('mode', {
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> }],
                        initialValue: this.state.workMode
                    })(
                        <Select onChange={this.handleSelectChange}>
                            <Select.Option key='local'><FormattedMessage id='settings.workmode.local' /></Select.Option>
                            <Select.Option key='mq'><FormattedMessage id='settings.workmode.mq' /></Select.Option>
                        </Select>
                    )}
                </Form.Item>
                {configDom}
                <Form.Item style={{ textAlign: 'center' }}>
                    <Button loading={this.state.loading} type="primary" htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                    <Button style={{ marginLeft: 8 }} onClick={() => {
                        this.props.location.eth0 ? this.props.history.push({
                            pathname: '/SetupWan',
                            data: this.props.location.eth0
                        }) : this.props.history.push('/SetupWan')
                    }}><FormattedMessage id='button.back' /></Button>
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);
class SetupWorkMode extends Component {
    render() {
        return (
            <div>
                <div style={{ margin: '18px 0' }}>
                    <div><Alert message={<FormattedMessage id='setup.workMode.alert' />} showIcon /></div>
                </div>
                <div style={{ backgroundColor: '#FFFFFF', padding: '48px 120px 33px', marginBottom: 242 }}>
                    <WrapperedEditForm {...this.props} />
                </div>
            </div>
        )
    }
}
export default SetupWorkMode

import React from "react";

import { Select } from 'antd';

const Option = Select.Option;


class StreamSelect extends React.Component {

	
	handleChange = (value) => {
		this.triggerChange(value)
	}

	triggerChange = (changedValue) => {
		const onChange = this.props.onChange;
		if (onChange) {
			if (this.props.mode === 'multiple'){
				onChange(Object.assign([], this.state, changedValue));
			}
			else {
				onChange(changedValue);
			}
			
		}
	}
	
	render () {
		return (
			<Select
				mode={this.props.mode}
				showSearch
				disabled= {this.props.disabled}
				style={{ width: this.props.width }}
				optionFilterProp="children"
				value={this.props.value}
				onChange={this.handleChange}
				onFocus={this.handleFocus}
				onBlur={this.handleBlur}
			>
				{
					this.props.data.map((value) => <Option key={value.key}> {value.name}</Option>)
				}
			</Select>
		)
	}
}


StreamSelect.defaultProps = {
	width: '240px',
	mode: "multiple"
}

export default StreamSelect;

import React, { Component } from 'react';
import zh_CN from 'antd/lib/locale-provider/zh_CN';
import { ConfigProvider } from 'antd';
import zh from 'react-intl/locale-data/zh';
import en from 'react-intl/locale-data/en';
import zh_CN_LOCAL from './locale/zh_CN';
import en_US_LOCAL from './locale/en_US';
import { IntlProvider, addLocaleData } from 'react-intl'
addLocaleData([...zh, ...en])
const messages = {
    "zh": zh_CN_LOCAL,
    "en": en_US_LOCAL
}
const lang = localStorage.getItem('lang')||navigator.language.split('-')[0]||'zh';
localStorage.setItem('lang',lang);
const IntlProviderLocal = new IntlProvider({ locale:lang,messages:messages[lang]});
const { intl } = IntlProviderLocal.getChildContext();

class Intel extends Component {

    render() {
        const { children } = this.props;
        return (
            <IntlProvider key={lang} locale={lang} messages={messages[lang]}>
                <ConfigProvider locale={/^zh.*/.test(lang) ? zh_CN : undefined}>
                <React.Fragment>
                    {children}
                </React.Fragment>
                </ConfigProvider>
            </IntlProvider>

        )
    }
}

export default Intel
export {intl}
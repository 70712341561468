
import React, { Component } from 'react';
import { FormattedMessage } from 'react-intl';
import '../face-compare.css';
import FaceUpload from '../Face/FaceUpload';
import { USER_TYPE, USER_TYPE1 } from "../../../utils/Config";
import { pictureMatch } from '../../../services/match'
import { getTheme } from '../../../services/system';

class Base extends Component {
    constructor(props) {
        super(props);
        this.faceImg = '';
        this.state = {
            score: 0,
            retcode: null,
            matchImg: '',
            info : {}
        }
    }
    handleFace = (faceImg) => {
        this.faceImg = faceImg;
        pictureMatch({ "rgb_image_base64": this.faceImg }).then(res => {
                this.setState({
                    retcode: res.retcode,
                    info: res.identity_info,
                    score: res.score.toFixed(),
                    matchImg: res.reg_pic
                })
        }).catch(() => {
            this.handleData();
        })
    }
    componentDidMount = async () => {
        getTheme().then(res => {
            this.setState({
                theme: res
            })
        })
    }
    handleData = () => {
        this.setState({
            score: 0,
            matchImg: '',
            retcode: null,
        })
    }

    handleClearImg = () => {
        this.handleData();
        this.faceImg = '';
    }
    renderUserType = (value) => {
        let NEW_USER_TYPE = null;
        if (this.state.theme === 'PARK') {
            NEW_USER_TYPE = USER_TYPE;
        } else {
            NEW_USER_TYPE = USER_TYPE1;
        }
        for (let item of NEW_USER_TYPE) {
            if (item.typeId === value) {
                return <FormattedMessage id={`user.add.type.${item.description}`} />
            }
        }
    }
    render() {
        let { info, retcode } = this.state;
        return (
            <div className="compare-face" style={{ margin: "24px", padding: "24px", minHeight: '800px', "backgroundColor": "rgb(255, 255, 255)" }}>
                <h2 className='title'><FormattedMessage id='event.compare.base.title' /></h2>
                <div className="content flext-justify-center">
                    <div className="left flext-justify-btw">
                        <div className="left-face">
                            <FaceUpload getImg={this.handleFace} clearImg={this.handleClearImg} />
                        </div>
                        <div className="right-face">
                            <div style={{ width: 300, height: 300,boxSizing:'border-box', border: retcode ? (retcode === '1' ? '1px dashed #00FF7F' : '1px dashed red') : '1px dashed #d9d9d9', background: `#fafafa url(${this.state.matchImg}) no-repeat center` }} >
                                <section style={{ textAlign: 'right', color: retcode ? (retcode === '1' ? '#00FF7F' : 'red') : '' }}>{retcode ? <FormattedMessage id='event.compare.result.match.have' /> : ''}</section>
                            </div>
                        </div>
                    </div>
                    <div className="right">
                        <div className="title">
                            <FormattedMessage id='event.compare.result.title' />
                        </div>
                        <div className="result">
                            {this.state.retcode ? <><p><FormattedMessage id='event.bar.score' values={{ value: this.state.score }} /> </p>
                            <p><FormattedMessage id='user.list.form.name' /> : <span>{info.name}</span></p>
                            <p><FormattedMessage id='settings.time.type' /> : <span>{this.renderUserType(info.type)}</span></p>
                            <p><FormattedMessage id='user.list.form.userID' /> : <span>{info.user_id}</span></p></> : ''}
                        </div>
                    </div>
                </div>

            </div>
        )
    }
}

export default Base
import React, { Component } from 'react'
import { Table, Button, Divider, Form, Input, Popconfirm, message, Avatar, Modal, Popover } from 'antd'
import HistoryList from './HistoryList'
import Title from '../common/Title'
import axios from '../../utils/AxiosWrapper'
import { COMMON_PREFIX, SOPHON_UTIL, USER_TYPE, USER_TYPE1, SOPHON_FACE } from '../../utils/Config'
import { connect } from 'react-redux';
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { getTheme } from '../../services/system';
import './userList.css';
import UserAddFrom from './UserAddForm';

const prefix = COMMON_PREFIX;
//查询条
class QueryForm extends Component {
    constructor() {
        super(...arguments);
        this.state = {
            userInfo: null
        }
    }
    ClearForm = () => {
        this.props.form.resetFields();
        this.props.query({});
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, values) => {
            if (!err) {
                let params = {}
                if (values.name) {
                    params['info.name'] = values.name;
                }
                if (values.identity_id) {
                    params['identity_id'] = values.identity_id;
                }
                this.props.query(params);
            }
        });
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        return (
            <Form layout='inline' onSubmit={this.handleSubmit}>
                <Form.Item label={<FormattedMessage id='user.list.form.name' />}>
                    {getFieldDecorator('name', {

                    })(<Input style={{ width: "150px" }} />)}
                </Form.Item>
                <Form.Item label={<FormattedMessage id='user.list.form.id' />}>
                    {getFieldDecorator('identity_id', {

                    })(<Input style={{ width: "150px" }} />)}
                </Form.Item>
                <Form.Item>
                    <Button type='primary' htmlType='submit'><FormattedMessage id='button.query' /></Button>
                </Form.Item>
                <Form.Item>
                    <Button onClick={this.ClearForm}><FormattedMessage id='button.clear' /></Button>
                </Form.Item>
            </Form>
        )
    }
}
const WrapperedQueryForm = Form.create()(QueryForm);

class Bar extends Component {
    state = {
        spinning: false
    }

    render() {
        return (
            <div>
                {this.props.mode !== 'mq' ? <div style={{ marginTop: "20px" }}>
                    {/* <Link to='/User/Add' style={{ display: 'inline-block', marginRight: 8 }} className='ant-btn ant-btn-primary'><Icon type='plus' /><FormattedMessage id='button.add' /></Link> */}
                    <Button type="primary" icon="plus" onClick={this.props.showAddForm} style={{ marginRight: 8 }}><FormattedMessage id='button.add' /></Button>
                    <Button type="primary" icon="download" style={{ marginRight: 8 }}><a href={`/SophonFogSys/api/BatchToolDownload`} style={{ color: "#ffffff" }}><FormattedMessage id='user.list.bar.bulkDownload' /></a></Button>
                    <Button onClick={this.props.BatchDelete} style={{ marginRight: 8 }}><FormattedMessage id='button.delete' /></Button>
                </div> : undefined}
            </div>
        )
    }
}
//面包屑标题栏配置
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/User/UserList',
    breadcrumbName: <FormattedMessage id='sidemenu.User' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.User.UserList' />
}];

class UserTable extends Component {
    constructor(props) {
        super(props);
        this.params = {
            per_page: 10,
            page: 0
        };
        this.state = {
            full_data: [],//当前页的用户数据
            pagination: {},
            //display_data: [],
            selectedRowKeys: [],
            loading: true,//loading state
            // groups: {},
            // group_cache: {},
            // face_cache: {},
            permissionsMap: {},
            userAddVisible: false
        }
        this.columns = [{
            title: <FormattedMessage id='user.list.form.name' />,
            dataIndex: 'info.name',
            align: 'center',
            width: 120,
            render: (text) => {
                return (
                    <Popover content={text}>
                        <span className='fixed-td-width'>{text}</span>
                    </Popover>
                )
            }
        }, {
            title: <FormattedMessage id='user.list.form.id' />,
            dataIndex: '_id',
            align: 'center',
            width: 150,
            render: (text) => {
                return (<Popover content={text}>
                    <span className='fixed-td-width'>{text}</span>
                </Popover>)
            }
        },
        //  {
        //     title: <FormattedMessage id='user.list.form.visitCardNumber'/>,
        //     dataIndex: 'info.visit_card_number',
        //     align: 'center',
        //     //editable: true,
        //     //width: 200
        // },
        {
            title: <FormattedMessage id='user.list.form.profile' />,
            key: 'image',
            align: 'center',
            width: 100,
            //editable:true,
            render: (text, record) => {
                let timestramp = new Date().getTime().toString().substring(0, 9);//一个精确到分钟的时间戳，防止图片缓存
                // let src = this.state.face_cache[record._id] ? `${prefix}/images/${this.state.face_cache[record._id]}?t=${timestramp}` : ''
                let src = `${prefix}/images/${record.info.image_id}?t=${timestramp}`
                return (
                    <Popover content={(<img alt='' src={src} style={{ maxHeight: 100, maxWidth: 134 }}></img>)}>
                        <Avatar alt='' src={src} />
                    </Popover>
                )
            }
        },
        {
            title: <FormattedMessage id='sidemenu.User.PermissionGroup' />,
            dataIndex: 'info.permission_ids',
            align: 'center',
            width: 100,
            render: (text) => {
                const nameList = Array.isArray(text) ? text.map(id => this.state.permissionsMap[id]) : []
                return (
                    <Popover content={nameList.join(',')}>
                        <span>
                            {nameList.join(',')}
                        </span>
                    </Popover>
                )
            }
        },
        {
            title: <FormattedMessage id='user.list.form.userType' />,
            dataIndex: 'info.type',
            align: 'center',
            width: 100,
            render: (text) => {
                let NEW_USER_TYPE = null;
                if (this.theme === 'PARK') {
                    NEW_USER_TYPE = USER_TYPE;
                } else {
                    NEW_USER_TYPE = USER_TYPE1;
                }
                let type = NEW_USER_TYPE.find((e) => { return e.typeId === parseInt(text) })
                return (
                    <div>{type ? <FormattedMessage id={`user.add.type.${type.description}`} /> : ''}</div>
                )
            }
        },
        {
            title: <FormattedMessage id='user.list.form.action' />,
            key: 'action',
            align: 'center',
            width: 150,
            render: (text, record) => {
                return (
                    <div>
                        {
                            record.info.type === 2 ? <span>
                                {/* <a onClick={() => this.edit(record)}><FormattedMessage id='button.edit'/></a> */}
                                {/* <Divider type='vertical' /> */}
                                <Popconfirm title={<FormattedMessage id='user.list.delete.tips' />} onConfirm={() => this.deleteRecord(record._id)}>
                                    <span className="cursor"><FormattedMessage id='button.delete' /></span>
                                </Popconfirm>
                                <Divider type='vertical' />
                                <span className="cursor" onClick={() => this.showAddForm(record, 'detail')}><FormattedMessage id='button.detail' /></span>
                                <br></br>
                                <span className="cursor" onClick={() => this.showHistory(record)}><FormattedMessage id='user.history.PassRecord' /></span>
                            </span> :
                                <span>
                                    {/* <span className="cursor" onClick={() => this.edit(record)}><FormattedMessage id='button.edit' /></span> */}
                                    <span className="cursor" onClick={() => this.showAddForm(record)}><FormattedMessage id='button.edit' /></span>
                                    <Divider type='vertical' />
                                    <Popconfirm title={<FormattedMessage id='user.list.delete.tips' />} onConfirm={() => this.deleteRecord(record._id)}>
                                        <span className="cursor"><FormattedMessage id='button.delete' /></span>
                                    </Popconfirm>
                                    <Divider type='vertical' />
                                    <span className="cursor" onClick={() => this.showAddForm(record, 'detail')}>
                                        <FormattedMessage id='button.detail' /></span>
                                    <br></br>
                                    <span className="cursor" onClick={() => this.showHistory(record)}><FormattedMessage id='user.history.PassRecord' /></span>
                                </span>
                        }


                    </div>

                )
            }
        }];
    }
    showHistory = (row) => {
        this.setState({
            userInfo: { userName: row.info.name, userType: row.info.type, identity_id: row._id, imageId: row.info.image_id },
            historyVisible: true
        })
    }
    showAddForm = (row, isDetail) => {
        if (isDetail) { //详情
            row.disable = true;
        } else {
            row.disable = false;
        }
        if (row) {
            this.setState({
                entry: row
            }, () => {
                this.setState({
                    userAddVisible: true
                })
            })

        } else {
            this.setState({
                userAddVisible: true
            })
        }

    }
    userAddCancel = () => {
        this.setState({
            userAddVisible: false
        })
    }
    handleHistoryCancel = () => {
        this.setState({
            historyVisible: false
        })
    }
    fetch = (refresh = false) => {
        if (refresh) {
            this.params.page = 0;
        }
        this.params.page = this.params.page || 0;
        this.params.per_page = this.params.per_page || 10;
        let url = `${SOPHON_FACE}/identities?with_face_id=true`;
        axios.get(url, { params: this.params }).then(
            (resp) => {
                if (resp.status === 200) {
                    let full_data = resp.data.data;
                    let pagination = resp.data.pagination;
                    // this.queryCacheInfo(full_data);
                    // this.cacheUserGroup(data_list)
                    this.setState({
                        full_data: full_data,
                        pagination: pagination,
                        loading: false,
                        selectedRowKeys: []
                    });

                }

            }
        ).catch(
            (e) => {
                //console.error('Get identities Error', e);
                this.setState({ full_data: [], loading: false });
            }
        )
    }

    queryCacheInfo = (identities_list) => {
        identities_list.forEach(
            (identity) => {
                if (!this.state.group_cache[identity._id]) {
                    //console.log('start cache')
                    let group_cache = this.state.group_cache;
                    group_cache[identity._id] = {
                        name: [],
                        ids: []
                    }
                    axios.get(`${prefix}/identities/${identity._id}?with_group_id=true`)
                        .then(
                            (resp) => {
                                if (resp.status === 200) {
                                    if (resp.data.data.group_ids && resp.data.data.group_ids.length > 0) {
                                        let group_ids = resp.data.data.group_ids;

                                        group_cache[identity._id].ids = group_ids;
                                        //console.log('groups:',this.state.groups)
                                        group_ids.forEach(
                                            (id) => {
                                                if (this.state.groups[id] === undefined) {

                                                    let groups = this.state.groups;
                                                    groups[id] = '';
                                                    axios.get(`${prefix}/groups/${id}`).then(
                                                        (resp) => {
                                                            // this.state.group_cache[identity._id].name[index] = resp.data.data.info.name;
                                                            groups[id] = resp.data.data.info.name;
                                                            this.setState({ groups: this.state.groups })
                                                        }
                                                    )
                                                    //this.state.group_cache[identity._id].name[index] = this.state.groups[id];
                                                }


                                            }
                                        )
                                        this.setState({
                                            group_cache: this.state.group_cache
                                        }
                                            // ,()=>{console.log(this.state.group_cache)}
                                        )

                                    }

                                }
                            }
                        )
                }

            }
        )
    }
    handleTableChange = (pagination) => {
        this.params.page = pagination.current - 1;
        this.fetch();
    }
    componentDidMount() {
        getTheme().then(res => {
            this.theme = res;
            this.fetch();
        });
        axios.get(`${COMMON_PREFIX}/permissions`).then(
            (resp) => {
                const permissionsMap = {}
                resp.data.data.forEach(
                    (group) => {
                        permissionsMap[group._id] = group.info.name;
                    }
                )
                this.setState({
                    permissionsMap
                });
            }
        );
    }

    onRowSelectedChange = (selectedRowKeys) => {
        this.setState({ selectedRowKeys });
    }


    onClickClear = () => {
        this.setState({ selectedRowKeys: [] })
    }

    edit = (record) => {
        axios.get(`${prefix}/identities/${record._id}?with_group_id=true`).then(res => {
            this.props.history.push({
                pathname: '/User/Edit',
                state: {
                    record: record,
                    group_ids: res.data.data.group_ids || []
                }
            })
        })

    }
    deleteRecord = (key) => {
        let param = {
            iteratively_delete: true
        }
        axios.delete(`${prefix}/identities/${key}`, { data: param }).then(
            (resp) => {
                this.params.page = 0;
                this.fetch()
            }
        ).catch(
            (e) => {
                message.error(intl.formatMessage({ id: 'network.error' }));
            }
        )
    }
    BatchDelete = () => {
        if (this.state.selectedRowKeys.length === 0) {
            message.info(intl.formatMessage({ id: 'info.select' }));
            return
        }
        const _this = this;
        Modal.confirm({
            title: intl.formatMessage({ id: 'user.list.delete.tips' }),
            onOk() {
                let delete_ids = []
                _this.state.selectedRowKeys.forEach(
                    (identitiy_id) => {
                        delete_ids.push(identitiy_id)
                    }
                )
                axios.delete(`${SOPHON_UTIL}/export`, { data: { 'identity_ids': delete_ids } }).then(
                    (resp) => {
                        _this.params.page = 0;
                        _this.fetch();
                    }
                ).catch(
                    () => {
                        message.error(intl.formatMessage({ id: 'network.error' }))
                    }
                )
            }
        })


    }
    handleQueryForm = (params) => {
        this.params = params;
        this.fetch()
    }
    getSelectedKeys = () => {
        return this.state.selectedRowKeys
    }
    handleSuccess = () => {
        message.success(intl.formatMessage({ id: 'info.success' }), 1);
        this.fetch();
        this.userAddCancel();
    }
    render() {
        const rowSelection = {
            selectedRowKeys: this.state.selectedRowKeys,
            onChange: this.onRowSelectedChange
        }

        return (
            <div>
                <Title items={breadcrumbSetting} />
                <div style={{ margin: '24px', padding: 24, backgroundColor: '#FFFFFFFF', overflow: "auto" }}>
                    <div>
                        <WrapperedQueryForm query={this.handleQueryForm} />
                    </div>
                    <Bar
                        fetch={this.fetch}
                        BatchDelete={this.BatchDelete}
                        mode={this.props.mode}
                        showAddForm={this.showAddForm}
                        getSelectedKeys={this.getSelectedKeys} />
                    <div style={{ margin: '16px 0' }}>
                        <div data-show='true' className='ant-alert ant-alert-info'>
                            <i className="anticon anticon-info-circle ant-alert-icon"></i>
                            <span className="ant-alert-message" style={{ marginRight: 14 }}>
                                <span style={{ color: '#1890ff', margin: '0 5px' }}>{this.state.selectedRowKeys.length}</span><FormattedMessage id='user.list.alert.msg1' />
                            </span>
                            <span style={{ marginRight: 28 }}><FormattedMessage id='user.list.alert.msg2' values={{ number: this.state.pagination.total_count || 0 }} /></span>
                        </div>
                    </div>
                    <Table
                        columns={this.props.mode === 'mq' ? this.columns.slice(0, this.columns.length - 1) : this.columns}
                        loading={this.state.loading}
                        dataSource={this.state.full_data}
                        rowSelection={rowSelection}
                        rowKey='_id'
                        pagination={{ showQuickJumper: true, total: this.state.pagination.total_count || 0, current: this.params.page + 1 }}
                        onChange={this.handleTableChange}
                        // scroll={{ y: "55vh" }}
                    />
                </div>
                <Modal
                    title={<FormattedMessage id='user.history.PassRecord' />}
                    width={1000}
                    footer={null}
                    destroyOnClose={true}
                    visible={this.state.historyVisible}
                    onCancel={this.handleHistoryCancel}
                >
                    <HistoryList userInfo={this.state.userInfo} />
                </Modal>
                <Modal
                    title="员工信息"
                    footer={null}
                    visible={this.state.userAddVisible}
                    onCancel={this.userAddCancel}
                    destroyOnClose={true}
                    width={750}>
                    <UserAddFrom onSuccess={this.handleSuccess} theme = {this.theme} onCancel={this.userAddCancel} entry={this.state.entry} />
                </Modal>
            </div>
        )
    }
}
export default connect(
    (state, ownProps) => {
        return {mode: state.mode}
    }
)(UserTable)
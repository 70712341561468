import React, { Component } from 'react'
import md5 from 'spark-md5'
import Title from '../common/Title';
import { Layout, Button, message, Spin, Icon, Alert, Modal, Progress } from 'antd';
import axios, { fileUpload } from '../../utils/AxiosWrapper'
import { SOPHON_SYSTEM } from '../../utils/Config';
// import { comparePublicVersion } from '../../utils/Handlers'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';

class Local extends Component {
    state = {
        can_submit: false,
        uploading: false,
        message: '',
        current_version: '',
        progress: {
            percent: 0,
            total: 0,
            loaded: 0,
            speed: 0
        }
    }
    selectFile = () => {
        this.refs.file.click()
    }
    componentDidMount() {
        this.checkCurrentVersion()
    }
    checkCurrentVersion = () => {
        axios.get(`${SOPHON_SYSTEM}/otaSWVersion`).then(
            (resp) => {
                this.setState(
                    {
                        current_version: resp.data.data.version
                    }
                )
            }
        )
    }
    handleSelectFile = () => {
        const files = this.refs.file.files;
        if (files.length === 0) {
            this.refs.filename.innerHTML = ''
            return
        }
        if (files[0].name) {

        }
        this.setState({ can_submit: true })
        this.refs.filename.innerHTML = files[0].name
    }
    handleUpload = () => {
        let filename = this.refs.file.files[0].name
        if (/.+\.(zip|tar|tgz)$/.test(filename)) {
            if (!/^V\d{1}R\d{1}C\d+\.(zip|tar|tgz)$/.test(filename)) {
                message.error(intl.formatMessage({ id: 'settings.update.local.versionNotMatch' }));
                return
            }
            // let new_version = filename.substring(0, filename.lastIndexOf('.'))
            // if (!comparePublicVersion(this.state.current_version, new_version)) {
            //     message.error(intl.formatMessage({ id: 'settings.update.local.versionOld' }))
            //     return
            // }
            this.setState(
                {
                    uploading: true,
                    message: 'Reading file...',
                    progress: {
                        percent: 0,
                        total: 0,
                        loaded: 0,
                        speed: 0
                    }
                }
            )
            this.md5sum();

        } else {
            message.error(intl.formatMessage({ id: 'settings.update.local.fileError' }));
            //console.log(this.refs.file.files)
            return
        }
    }
    md5sum = () => {
        const blobSlice = File.prototype.slice || File.prototype.mozSlice || File.prototype.webkitSlice;
        const file = this.refs.file.files[0];
        const _this = this;
        if (file) {
            let chunkSize = 1024 * 1024 * 2;//2M
            let chunks = Math.ceil(file.size / chunkSize);
            let current_chunk = 0;
            const sparkMd5 = new md5.ArrayBuffer();
            const fileReader = new FileReader();
            function loadChunks() {
                let start = current_chunk * chunkSize;
                let end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;
                fileReader.readAsArrayBuffer(blobSlice.call(file, start, end))
            }
            fileReader.onload = function (e) {
                //console.log('read chunk :',current_chunk+1,'of',chunks);
                sparkMd5.append(e.target.result);
                current_chunk++;
                if (current_chunk < chunks) {
                    loadChunks()
                } else {
                    _this.setState({
                        message: 'Uploading file...'
                    })

                    let md5 = sparkMd5.end();
                    console.log(md5)
                    _this.sendData(file, md5)
                }
            }
            fileReader.onerror = function () {
                message.error(intl.formatMessage({ id: 'settings.update.local.readFileError' }))
                _this.setState({
                    uploading: false,
                    message: ''
                })
            }
            loadChunks()
        }
    }
    onUploadProgress = (event) => {
        let percent = event.loaded / event.total * 100
        let progress = {}
        progress.percent = parseFloat(percent.toFixed(2));
        progress.loaded = event.loaded;
        progress.total = event.total;
        progress.speed = ((event.loaded - this.state.progress.loaded || 0) / (1024 * 1024)).toFixed(2)
        if (progress.percent === 100) {
            progress.speed = 0
            this.setState({
                message: 'checking file...',
                progress: progress
            })

        } else {
            this.setState({
                progress: progress
            })
        }

    }
    sendData = (file, md5) => {
        if (!file || !md5) {
            message.error('lost file or md5')
            return
        }
        const _this = this;
        let fd = new FormData();
        fd.append('file', file)
        fd.append('md5', md5)
        console.log('start:', new Date())
        fileUpload.post(`${SOPHON_SYSTEM}/LocalUpgrade`, fd, { onUploadProgress: this.onUploadProgress }).then(
            (resp) => {
                _this.setState({
                    message: 'updating...'
                })
                console.log('finish:', new Date())
                this.props.checkUpdateState(9e5, 3, () => { _this.setState({ uploading: false }) })
                message.warning(intl.formatMessage({ id: 'settings.update.local.updating' }))
            }
        ).catch(
            (e) => {
                console.log('finish: error happened at ', new Date())

                if (e.response && e.response.data && e.response.data.error) {
                    message.error(`${e.response.data.error.status}:${e.response.data.error.message}`)
                }
                else {
                    message.error(intl.formatMessage({ id: 'network.error' }))
                }
                _this.setState({
                    uploading: false,
                    message: '',
                    progress: {
                        percent: 0,
                        total: 0,
                        loaded: 0,
                        speed: 0
                    }
                })
            }
        )
    }

    render() {
        return (
            // <Spin spinning={this.state.uploading} tip={this.state.message} size='large'>
            <div style={{ textAlign: 'center' }}>
                <input ref='file' type='file' style={{ display: 'none' }} onChange={this.handleSelectFile}></input>
                <div style={{ margin: 30 }}>
                    <Alert
                        showIcon={true}
                        message={<FormattedMessage id='settings.update.local.title' />}
                        description={<FormattedMessage id='settings.update.local.msg' />} />
                </div>
                <div style={{ marginBottom: 20 }}>
                    <div><span><FormattedMessage id='settings.update.currentVersion' />: {this.state.current_version}</span></div>
                    <div style={{ width: 104, margin: '10px auto' }}>
                        <Spin spinning={this.state.uploading}>
                        <div className='ant-upload ant-upload-select ant-upload-select-picture-card' style={{ margin: '0 auto' }}>
                            <span tabIndex='0' className='ant-upload' role='button' onClick={this.selectFile}>
                                <div>
                                    <i className='anticon anticon-plus'>
                                        <svg viewBox="64 64 896 896" className="" data-icon="plus" width="1em" height="1em" fill="currentColor" aria-hidden="true">
                                            <path d="M848 474H550V152h-76v322H176c-4.4 0-8 3.6-8 8v60c0 4.4 3.6 8 8 8h298v322h76V550h298c4.4 0 8-3.6 8-8v-60c0-4.4-3.6-8-8-8z">
                                            </path>
                                        </svg>
                                    </i>
                                </div>
                            </span>
                        </div>
                    </Spin>
                    <span ref='filename'></span>
                    </div>
                    
                    <div style={{ width: 500, margin: '0 auto' }}>
                        <Progress
                            percent={this.state.progress.percent}
                            format={(percent) => (
                                <React.Fragment>
                                    <FormattedMessage id='settings.update.local.speed' values={{ percent, speed: this.state.progress.speed || 0 }} />
                                </React.Fragment>
                            )

                            }
                        ></Progress></div>

                </div>
                <Button disabled={!this.state.can_submit} type='primary' onClick={this.handleUpload} loading={this.state.uploading}><Icon type='upload' /><FormattedMessage id={`settings.update.local.${this.state.message || 'Upload'}`} /></Button>

            </div>
            //* </Spin> */
        )
    }
}
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/System/Basic',
    breadcrumbName: <FormattedMessage id='sidemenu.System' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.System.Update' />
}];
class Update extends Component {

    state = {
        current_version: ''
    }
    componentDidMount() {
        axios.get(`${SOPHON_SYSTEM}/otaSWVersion`).then(
            (resp) => {
                this.setState({ current_version: resp.data.data.version })
            }
        )
    }
    componentWillUnmount = () => {
        if (this.timer) {
            clearTimeout(this.timer)
            console.log('timer:' + this.timer + ' has been removed')
        }
    }
    /**
     * 检查升级结果，首先15分钟查询一次，失败后会5分钟查询一次，直到达到最大尝试次数
     * defultTime:首次查询的时间
     * max_retry:最大尝试次数
     */
    checkUpdateState = (defaultTime, max_retry, callBack,) => {
        let retry_time = max_retry || 3;//default 3
        const _this = this;
        const retry = function (defaultTime) {
            let time = defaultTime || 9e5;//15min
            if (retry_time <= 0) {
                Modal.error({
                    title: intl.formatMessage({ id: 'settings.update.error' }),
                    content: intl.formatMessage({ id: 'settings.update.version' }, { version: _this.state.current_version })
                })
                if (typeof callBack === 'function') {
                    callBack()
                }

                return
            }
            if (_this.timer) {
                clearTimeout(_this.timer)
            }
            _this.timer = setTimeout(() => {
                retry_time--;
                console.log('start:', 3 - retry_time, ' at:', new Date().toLocaleString())
                axios.get(`${SOPHON_SYSTEM}/otaSWVersion`).then(
                    (resp) => {
                        let flag = resp.data.data.version !== _this.state.current_version;
                        let method = flag ? 'success' : 'error';
                        let title = flag ? intl.formatMessage({ id: 'settings.update.complete' }) : intl.formatMessage({ id: 'settings.update.error' })
                        Modal[method]({
                            title,
                            content: intl.formatMessage({ id: 'settings.update.version' }, { version: resp.data.data.version })
                        })
                        if (typeof callBack === 'function') {
                            callBack()
                        }

                        return
                    }
                ).catch(
                    () => {
                        console.log('retry')
                        retry(3e5)//5min
                        //retry(10000)
                    }
                )
            }, time)
        }
        retry(defaultTime)

    }
    render() {
        return (
            <div>
                <Title items={breadcrumbSetting} />
                <Layout style={{ margin: '24px', backgroundColor: '#FFFFFF', minHeight: '420px' }}>
                    <Local checkUpdateState={this.checkUpdateState} />
                </Layout>
            </div>
        )
    }
}

export default Update
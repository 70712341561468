export default {
    "user.list.form.name":"Name",
    "user.list.form.userID":"User ID",
    "user.list.form.id":"ID",
    "user.list.form.visitCardNumber":"Visit Card Number",
    "user.list.form.profile":"Profile Photo",
    "user.list.form.group":"Group",
    "user.list.form.userType":"User Type",
    "user.list.bar.bulkDownload":"Bulk import tool download",
    "user.list.form.description":"Description",
    "user.list.form.action":"Action",
    "user.list.bar.batchAdd":"Batch Add",
    "user.list.bar.downloadToPC":"Download to PC",
    "user.list.bar.downloadToTF":"Download to TF Card",
    "user.list.bar.download":"Download",
    "user.list.bar.bulkAdd.title":"Bulk Add",
    "user.list.bar.bulkAdd.tab1":"Bulk Add",
    "user.list.bar.bulkAdd.tab2":"Register from zip",
    "user.list.bar.bulkAdd.tab3":"Import",
    "user.list.bar.bulkAdd.template":"Download template",
    "user.list.bar.bulkAdd.groups":"Groups",
    "user.list.bar.bulkAdd.images":"Upload Images",
    "user.list.bar.bulkAdd.imagesTips":"Select folder",
    "user.list.bar.bulkAdd.imagesMsg":"Select: {number} file(s)",
    "user.list.bar.bulkAdd.excel":"Upload excel",
    "user.list.bar.bulkAdd.excelTips":"Select excel",
    "user.list.bar.bulkAdd.excelMsg":"Excel",
    "user.list.bar.bulkAdd.zip.tips":"Register from zip file",
    "user.list.bar.bulkAdd.import.tips":"Import from zip file exported from other box",
    "user.list.alert.msg1":"Item(s) selected",
    "user.list.alert.msg2":"Total: {number} user(s)",
    "user.list.delete.tips":"Sure to delete user(s)",
    "user.add.takePhotoTitle":"Take Photo",
    "user.add.selectPhotoTitle":"Select Photo",
    "user.add.camera.error":"The camera failed. Please confirm that the camera is installed or the browser with high version is used.",
    "user.add.camera.canvas":"The browser don't suppport <canvas>,please use higher version.(like：Firefox 21，chrome 49 or higher)",
    "user.add.takePhoto":"Take Photo",
    "user.add.remake":"Remake",
    "user.add.upload":"Upload",
    "user.add.reupload":"Reupload",
    "user.add.type.Staff":"Staff",
    "user.add.type.Student":"Student",
    "user.add.type.Visitor":"Visitor",
    "user.add.type.Stranger":"Stranger",
    "user.add.type.Blacklist":"Blacklist",
    "user.add.type.Vip":"VIP",
    "user.group.list.name":"Name",
    "user.group.list.groupID":"Group ID",
    "user.group.list.action":"Action",
    "user.group.list.description":"Description",
    "user.group.alert.msg1":"Group(s) selected",
    "user.group.alert.msg2":"Total: {number} group(s)",
    "user.group.add.exsit":"Group name alreay exsits",
    "user.add.selectPhoto.image.large":"Image resolution should be less than 4096 * 4096",
    "user.add.selectPhoto.image.error":"Read image failed",
    "user.add.userID.existed":"UserID already existed",
    "user.add.startTime.error":"End time should be later than start time",
    "user.add.image.null":"Need a photo or picture, please try again.",
    "user.group.delete.title":"Delete this user group?",
    "user.group.delete.error":"This group is associated with user,please remove user from this group first.",
    "user.group.delete.error.stranger":"Can not delete Stranger Group",
    "user.group.delete.error.error":"Delete group failed",
    "user.add.import.noFile":"please select file",
    "user.add.import.image":"Only supports less than 1000 images",
    "user.add.import.tips":"Import done, please see result.zip for more details.",
    "user.export.all.tips":"Export all users?",
    "user.export.selected.tips":"Export selected users?",
    "user.add.error.badrequest":"Bad Request",
    "user.add.error.params":"Bad ReRequst:Params error",
    "user.add.error.image.noFace":"No face in the image,please upload image again",
    "user.add.error.image.error":"Image Error:{msg},please upload image again",
    "user.add.error.image.large":"Image too large:{msg}",
    "user.add.error.image.multipleFace":"Multiple Face:{msg}",
    "user.add.error.params.long":"Requst params too long",
    "user.add.input.blank":"Please don't input space only",
    "user.history.lastDatetime":"Last visit time",
    "user.history.PassRecord":"Historical record",
    'user.preview.register.image':"Picture details",
    'user.add.form.uploadMethod' : 'Upload method',
    'user.add.form.shootmethod' : 'Shooting method',
    'user.add.form.moreConfig' : 'More configuration',
    'user.add.form.mobile' : 'Phone number',
    'user.add.form.email' : 'Mailbox',
    'user.add.form.idNumber' : 'ID number',
    'user.add.form.studentId' : 'Student ID',
    "user.add.form.mobile.pattern":"Please enter a valid mobile phone number",
    "user.add.form.idNumber.pattern":"Please enter a valid ID number",
    "user.add.form.email.pattern":"Please enter a valid email address",
    "user.add.info.user":"Personal information",

    'user.add.form.gender' : 'Gender',
    'user.add.form.gender.female' : 'Female',
    'user.add.form.gender.male' : 'Male',
    'user.add.form.take.problem' : 'Encountered problems with the shooting method?',
    "user.add.form.take.problem.title": 'Reason: http does not support photo upload by default, can be configured as follows',
    'user.add.form.photo' : 'Identify photos:',
    'user.add.form.jobNumber' : 'Job number',
    'user.add.form.title' : 'Position',
    'user.add.form.department' : 'Department',
    'user.add.form.entryTime' : 'Entry Time',
    'user.add.form.address' : 'Address',
    'user.add.form.building_no' : 'Building_no',
    'user.add.form.floor' : 'Floor',
    'user.add.form.room' : 'Room No',
    'user.add.form.startTime' : 'Visiting time',
    'user.add.form.endTime' : 'Departure time',
    'user.add.form.purpose' : 'Purpose of visit',
    'user.add.form.interviewee' : 'Interviewee',
    'user.add.form.comeFrom' : 'Interviewed unit',
    'user.add.form.message' : 'Welcome Message',
    'user.add.form.visitor.tips' : 'If you are not a visitor, you do not need to fill in the information',
    
} 


import api from '../utils/api';
const SOPHON_CONFIG = "/SophonMatch/api/picturesearch";

const pictureSearch = (data) => api({
    url:`${SOPHON_CONFIG}`,
    data:data,
    method: 'post',
    config:{timeout:120000}
}).then(res => res.data);


export {
    pictureSearch
}
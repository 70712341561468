import React, { Component } from 'react'
import { Layout, ConfigProvider } from 'antd'
import { Route, Switch } from 'react-router-dom'
import SideMenu from './common/SideMenu'
import Header from './common/Header'
import Footer from './common/Footer'
import Host from './Setup/Host'
import UserList from './user/UserList'
import Password from './System/Password';
import SystemSetting from './System/SystemSetting';
import PageNotFound from './common/404';
import PermissionGroup from './user/PermissionGroup/List'
import PermissionGroupAdd from './user/PermissionGroup/Add'
import SetupPage from './SetupPage';
import BasicInfo from './System/BasicInfo'
import NetWorkSetting from './System/NetWorkSetting';
import WorkModeSetting from './System/WorkMode';
import ApplicationSetting from './System/Application';
import SmartSetting from './System/SmartSetting';
import IPCStreamList from './IPC_stream/IPCStreamList';
import IPCStreamAddList from './IPC_stream/IPCStreamAddList';
import Update from './System/Update';
import StreamEventMain from './Event/History/Index'
import SearchByImage from './Event/Image/SearchByImage'
import CompareFace from './Event/Face/Face'
import CompareBase from './Event/Base/Base'
import EventServerSetting from './System/EventServerSetting';
import zhCN from 'antd/es/locale/zh_CN';
import enUS from 'antd/es/locale/en_US';
import { connect } from 'react-redux'
const lang = localStorage.getItem('lang') || navigator.language.split('-')[0] || 'en';
class Main extends Component {
    state = {
        collapsed: false
    }
    toggleHandler = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }
    render() {
        if (!this.props.init) {
            return false;
        } else {
            let selectedkey = undefined;
            let openkeys = undefined;
            let indexCompoent = null;
            if (this.props.init === "1") {
                if (this.props.location.pathname === '/') {
                    indexCompoent = IPCStreamList;
                    openkeys = 'Config';
                    selectedkey = 'IPCStream'
                } else {
                    const _path = this.props.location.pathname.split('/');
                    if (_path.length >= 3) {
                        openkeys = _path[1]
                        selectedkey = _path[2]
                    }
                }
            }
            return (
                <ConfigProvider locale={lang === 'zh' ? zhCN : enUS}>
                    <Layout style={{ maxHeight: '100vh', height: 'auto', marginBottom: "50px" }}>
                        {this.props.init === "0" ? (<SetupPage></SetupPage>) :
                            (<Layout style={{ height: 'calc(100vh - 50px)' }}>
                                <SideMenu collapsed={this.state.collapsed}
                                    trigger={null}
                                    collapsible
                                    selectedkey={selectedkey}
                                    openkeys={openkeys}
                                    routerHistory={this.props.history}
                                />
                                <Layout style={{ height: 'auto' }}>
                                    <Header toggleHandler={this.toggleHandler} collapsed={this.state.collapsed} />
                                    <Layout.Content id ="main-page" style={{ marginTop: 64, height: "auto",overflowY:'auto',overflowX:"hidden" }} >
                                        <Switch>
                                            <Route exact path='/' component={indexCompoent}></Route>
                                            <Route exact path='/System/ChangePassword' component={Password}></Route>
                                            <Route exact path='/System/Basic' component={BasicInfo}></Route>
                                            <Route exact path='/System/application' component={ApplicationSetting}></Route>
                                            <Route exact path='/System/NetWorkSetting' component={NetWorkSetting}></Route>
                                            <Route exact path='/System/WorkMode' component={WorkModeSetting}></Route>
                                            <Route exact path='/System/SmartSetting' component={SmartSetting}></Route>
                                            <Route exact path='/System/SystemSetting' component={SystemSetting}></Route>
                                            <Route exact path='/System/Update' component={Update}></Route>
                                            <Route exact path='/System/EventServerSetting' component={EventServerSetting}></Route>
                                            <Route exact path='/Info/Basic/Host' component={Host}></Route>
                                            <Route exact path='/Config/IPCStream' component={IPCStreamList}></Route>
                                            <Route exact path='/Config/IPCStreamAddList' component={IPCStreamAddList}></Route>
                                            <Route exact path='/User/UserList' component={UserList}></Route>
                                            <Route exact path='/User/PermissionGroup' component={PermissionGroup}></Route>
                                            <Route exact path='/User/PermissionGroup/Add' component={PermissionGroupAdd}></Route>
                                            <Route exact path='/Event/History' component={StreamEventMain}></Route>
                                            <Route exact path='/Event/Image' component={SearchByImage}></Route>
                                            <Route exact path='/Event/Face' component={CompareFace}></Route>
                                            <Route exact path='/Event/Base' component={CompareBase}></Route>
                                            <Route component={PageNotFound}></Route>
                                        </Switch>
                                    </Layout.Content>
                                    <Footer />
                                </Layout>
                            </Layout>)}
                    </Layout>
                </ConfigProvider>
            )
        }
    }
}


// Main.propTypes = {
//     systemMode: PropTypes.string.isRequired
//   }
const mapStateToProps = (state, ownProps) => {
    return {
        // systemMode: state.systemMode,
        init:state.init
    }
}
export default connect(mapStateToProps)(Main);
import login from './zh_CN/Login';
import sidemenu from './zh_CN/Sidemenu';
import BasicInfo from './zh_CN/BasicInfo';
import Config from './zh_CN/Config';
import User from './zh_CN/User';
import Event from './zh_CN/Event';
import Settings from './zh_CN/Settings';
import Header from './zh_CN/Header';
import Setup from './zh_CN/Setup';
import Permission from './zh_CN/Permission';
export default {
    ...Setup,
    ...login,
    ...Header,
    ...sidemenu,
    ...BasicInfo,
    ...Config,
    ...User,
    ...Event,
    ...Settings,
    ...Permission,
    "theme.type": '项目类型',
    "theme.park": '园区',
    "theme.school": '校园',
    "device.select": '预览选择:',
    'device.select.first':'请先播放视频，再切换页面',
    'device.operation.frequent':'操作频繁',
    'device.preview.network.tips.title':'网速过低提示',
    'device.preview.network.tips.content':' 您当前网速为{value},为了更好的预览效果，建议提速到1000Mb/s',
    'device.set.preview.error':"设置错误",
    "gate": "闸机",
    "captureCamera": "抓拍机",
    "ipcGate": "网络摄像机(闸机)",
    "ipcStream": "网络摄像机(视频流)",
    "device.add.exist": "设备已经存在",
    'noDevicePlay': '请选择预览设备',
    "input.required": "请输入内容",
    "select.required": "请选择",
    "network.error": "网络错误",
    "process.error": "程序发生错误",
    "add.error": "添加失败",
    "add.error.play": "请先选择需要播放的视频",
    "add.error.maxLength": "抱歉，最多只能添加16路",
    "add.pld.play": "请选择需要播放的视频",
    "edit.error": "编辑失败",
    "delete.error": "删除失败",
    "info.none": "暂无",
    "info.unkown": "其他",
    "info.added": "添加成功",
    "edit.success": "编辑成功",
    "info.relogin": "重新登录",
    "info.restart": "设备将会重启",
    "info.restart.content": "设备将在点击确认后重启",
    "info.gate.restart": "应用将会重启",
    "info.gate.restart.content": "应用将在点击确认后重启",
    "info.select": "请选择记录",
    "info.success": "操作成功",
    "info.error": "操作失败",
    "info.nochange.warning":"无参数更改",
    "button.query": "查询",
    "button.clear": "清除",
    "button.add": "添加",
    "button.save":"保存",
    "button.edit": "编辑",
    "button.delete": "删除",
    "button.apply": "确定",
    "button.cancel": "取消",
    "button.detail": "详情",
    "button.tips": "{type}文件",
    "button.expand": "展开",
    "button.close": "关闭",
    "button.update": "升级",
    "button.back": "后退",
    "button.reset": "重新编辑",
    "url.invalid": "url不符合格式",
    "ip.invalid": "IP地址不符合格式",
    "hostname.invalid": "主机名不符合格式",
    "port.invalid": "端口不符合格式",
    "connect.error": "无法成功连接设备",
    "connect.error.workMode": "无法获取设备的工作模式",
    "connect.error.deviceType": "无法获取当前外接设备类型",
    "defaulthostname": "请输入主机名称",
    "message.none": "无"
}
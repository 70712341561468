import React, { Component } from 'react'
import Header from './common/Header'
import { connect } from 'react-redux';
import { Route, Switch,Redirect,withRouter } from 'react-router-dom'
import Host from './Setup/Host';
import SetupWan from './Setup/SetupWan';
import Footer from './common/Footer'
import SetupLan from './Setup/SetupLan'
import SetupWorkMode from './Setup/SetupWorkMode';
class SetupPage extends Component{
    render(){
        const { title } = this.props;
        return(
            <div style={{height:'100vh'}}>
                <div style={{position:'fixed',height:64,padding:'17px 28px',zIndex:2}}>
                    {/* <img src={logo}></img> */}
                </div>
                <Header setup={true}/>
                <div style={{ margin: '64px auto', width: 700, paddingTop: 48 }}>
                <div style={{ fontSize: 24, textAlign: 'center' }}>
                    {title}
                </div>
                    <Switch>
                        <Route exact path='/SetupHostname' component={Host}></Route>
                        <Route exact path='/SetupLan' component={SetupLan}></Route>
                        <Route exact path='/SetupWan' component={SetupWan}></Route>
                        <Route exact path='/SetupWorkMode' component={SetupWorkMode}></Route>
                        <Redirect to='/SetupHostname'></Redirect>
                    </Switch>
                </div>
                <Footer/>
            </div>
        )
    }
}
export default withRouter(connect(
    (state)=>({
        title:state.OEM.title
    })
)(SetupPage))
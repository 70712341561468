
// import axios from 'axios';
import { message } from 'antd';
import { intl } from '../Intel';
import axios from './AxiosWrapper';

const api = ({method,url,data,config}) => {
  let req = axios[method](url,data,config);
  return req.then(res => {
     return req;
   }).catch(err => {
    if(err && err.response && err.response.data && err.response.data.error){
      const response_msg = err.response.data.error;
      if(localStorage.getItem('lang')==='en'){
          message.error(response_msg.message,1.5);
      }else{
          message.error(response_msg.chinese_message,1.5);
      }
     }else if(err && err.response&& err.response.status !== 500 && err.response.status !== 502){
      message.error(err.response.statusText,1.5);
     }else{
      message.error(intl.formatMessage({ id: 'network.error' }),1.5)
     }
     return Promise.reject(err);
   })
};

export default api;

export default {
    "basic.info.panal.title":"Basic information",
    "basic.info.panal.ip":"IP",
    "basic.info.panal.hostname":"Hostname",
    "basic.info.panal.systemtime":"System Time",
    "basic.info.panal.boardTemperature":"Board Temperature",
    "basic.info.panal.systemVersion":"System Version",
    "basic.info.panal.workMode":"Work Mode",

    "basic.info.panal.devices":"Device Information",
    "basic.info.panal.devices_number":"Equipment Quantity",

    "basic.info.panal.uptime":"Uptime",
    "basic.info.cpu.info.cpuCount":'Cores',
    "basic.info.panal.model":"Product model",
    "basic.info.panal.SN":'S/N',
    "basic.info.panal.mcu":'MCU version',
    "basic.info.panal.hardwareInfo":'Hardware version',
    "basic.info.panal.wanIp":'WAN port IP',
    "basic.info.panal.lanIp":'LAN port IP',
    "basic.info.panal.wan":'WAN port bandwidth',
    "basic.info.panal.lan":'LAN port bandwidth',

    'basic.info.title.chipTemperature':'Chip junction temperature (℃)',
    "basic.info.title.fan":"Fan speed (x1000r / min)",
    "basic.info.percentage.CPUsage":"CPU utilization",
    "basic.info.percentage.TPUsage":"TPU utilization",
    "basic.info.percentage.Memorysage":"Memory utilization",
    "basic.info.percentage.memory.Video":"Video memory",
    "basic.info.percentage.memory.TPU":"Tpu memory",
    "basic.info.percentage.memory.system":"System memory",
    "basic.info.percentage.total":"In total",
    "basic.info.percentage.available":"Available",
    "basic.info.percentage.used":"Used",
    "basic.info.percentage.inDisk":"Built in hard disk",
    "basic.info.percentage.outDisk":"External hard disk",
    "basic.info.percentage.outDisk.total":"Total capacity",
    "basic.info.percentage.outDisk.point":"Mount point",
    "basic.info.percentage.outDisk.available":"Currently available",
    'basic.info.percentage.flow':'Mobile data',
    "basic.info.version.title":"Version number: FIQA",
}
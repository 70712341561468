export default {
    "permisson.add.exsit":"权限组已经存在",
    "permisson.delete.title":"确定删除选中的权限组?",
    "permisson.delete.content":"如果此权限组包含大量人员，需要耗费较长时间",
    "permisson.alert.msg1":"权限组被选中",
    "permisson.alert.msg2":"共:{number}条权限组记录",
    "permisson.delete.error":"该权限组当前还有关联用户，请移除关联用户再尝试",
    "permisson.delete.error.stranger":"陌生人权限组不可删除",
    "permisson.delete.error.error":"删除权限组失败",
    "sidemenu.User.PermissionGroup.Add.edit":"添加|编辑权限组"
}
export default {
    "permisson.add.exsit":"Permisson group has exsit",
    "permisson.delete.title":"Delete this permission group?",
    "permisson.delete.content":"If this permission group contains a large number of people, it will take time",
    "permisson.alert.msg1":"Group(s) selected",
    "permisson.alert.msg2":"Total: {number} group(s)",
    "permisson.delete.error":"This group is associated with user,please remove user from this group first.",
    "permisson.delete.error.stranger":"Can not delete Stranger Group",
    "permisson.delete.error.error":"Delete group failed",
    "sidemenu.User.PermissionGroup.Add.edit":"Add|Edit permission group",
}
import React, { Component } from 'react'
import { Layout, Menu, Icon } from 'antd'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import createMenuData from './menu/Sidemenu'
import { FormattedMessage } from 'react-intl';
import { loadStatic } from '../../utils/OEMPTAH';

const CreateMenu = (props) => {
    const { Item, SubMenu } = Menu;
    const Items = createMenuData(props.systemMode).map((e) => {
        const SubItems = e.subMenu.map((sub) => {
            if (sub) {
                return (sub.key ? <Item key={sub.key}>
                    <Link to={'/' + e.key + '/' + sub.key}><FormattedMessage id={`sidemenu.${e.key}.${sub.key}`} /></Link>
                </Item> : '')
            } else {
                return false;
            }
        }
        );
        //console.log(SubItems)
        const _Item = (<SubMenu title={<span><Icon type={e.icon} /><FormattedMessage id={`sidemenu.${e.key}`} /></span>} key={e.key}>
            {SubItems}
        </SubMenu>)
        //console.log(_Item)
        return _Item;
    });
    return <Menu
        theme="dark"
        mode="inline"
        selectedKeys={[props.selectedKey]}
        openkeys={[props.openKeys]}
        defaultOpenKeys={[props.defaultOpenKeys]}
        defaultSelectedKeys={[props.defaultSelectedKey]}
        onSelect={props.handleSelectMenu}>{Items}</Menu>
}

class SideMenu extends Component {
    state = {
        menu: [],
        openKeys: null,
        selectedKey: null
    }

    componentDidMount() {
        loadStatic('LOGO1').then(
            (data) => {
                if (this.logo1)
                    this.logo1.src = data
            }
        );
        loadStatic('LOGO2').then(
            (data) => {
                if (this.logo2)
                    this.logo2.src = data
            }
        )
    }
    static getDerivedStateFromProps(props, state) {
         if (props.selectedkey !== state.selectedKey || props.openkeys !== state.openKeys) {
            return {
            selectedKey: props.selectedkey,
            openKeys: props.openkeys,
          };
        }
        return null;
    }
    handleSelectMenu = (elem) => {
        this.setState({
            openKeys: elem.openKeys,
            selectedKey: elem.selectedKey
        })
    }
    render() {
        const Sider = Layout.Sider;
        let { collapsed, collapsible, trigger } = this.props;
        return (
            <Sider 
            breakpoint="xl"
            collapsed={collapsed || this.state.collapsed} 
            onBreakpoint={broken => {
                this.setState({
                    collapsed : broken
                })
              }}
            collapsible={collapsible} 
            trigger={trigger} 
            className='sider-menu-box'>
                <div className='sider-container-content'>
                    <div className="logo" style={{ display: 'flex', justifyContent: 'center' }}>
                        {/* <img ref= {el => this.logo1 =el} alt="logo" style={{ display: this.props.collapsed || this.state.collapsed ? '' : 'none', width: 32 }}></img>
                        <img ref={el => this.logo2 =el} alt="logo" style={{ display: this.props.collapsed || this.state.collapsed ? 'none' : '', width: '100%' }}></img> */}
                        <img src='logo_1.png' alt="logo" style={{ display: this.props.collapsed || this.state.collapsed ? '' : 'none', width: 32, marginRight: 'auto' }}></img>
                        <img src='dgos-light.png' alt="logo" style={{ display: this.props.collapsed || this.state.collapsed ? 'none' : '', width: '70%' }}></img>
                    </div>
                    <div  style={{marginBottom:70}}>
                    <CreateMenu
                        systemMode={this.props.systemMode}
                        selectedKey={this.state.selectedKey}
                        openkeys={this.state.openKeys}
                        defaultSelectedKey={this.state.selectedKey}
                        defaultOpenKeys={this.state.openKeys}
                        onSelect={this.handleSelectMenu}>
                    </CreateMenu>
                    </div>
                </div>
            </Sider>
        )
    }
}
const mapStateToProps = (state, ownProps) => {
    return {systemMode : state.systemMode}
}
const WrappedMenu = connect(mapStateToProps)(SideMenu)
export default WrappedMenu
import React, { Component } from "react";
import { Table, Modal, Form, Button, Row, Col, Input, Checkbox, Popover, Popconfirm, Divider, message, Select } from "antd";
import Title from "../common/Title";
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import GroupInput from '../common/GroupInput'
import PermissionInput from '../common/PermissionInput';
import { RTSP_PATTERN, RTMP_PATTERN, VIDEO_PATTERN, GB28181_PATTERN, USB_PATTERN, IP_PATTERN } from '../../utils/Config';
import { getDevices, addDevice, editDevice, remoteDevice } from '../../services/devices'
const { Option } = Select;
function trim(s) {
    return s.replace(/(^\s*)|(\s*$)/g, "");
}
const FormItemLayout = {
    labelCol: { span: 6 },
    wrapperCol: { span: 14 },
}
const breadcrumbSetting = [{
    path: '/',
    breadcrumbName: <FormattedMessage id='home' />
}, {
    path: '/Config/IPCStream',
    breadcrumbName: <FormattedMessage id='sidemenu.Config' />
}, {
    breadcrumbName: <FormattedMessage id='sidemenu.Config.IPCStreamAddList' />
}];

class EditForm extends Component {
    constructor(props) {
        super(props);
        this.state = {
            info: {},
            loading: false,
            isMoreConfig: false,
            urlPlaceholder: 'rtsp://192.168.1.179:554/av0_0',
            url_patter: RTSP_PATTERN,
            isRtsp: true,
            rtmpOpen: false
        }
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, values) => {
                if (!err) {
                    let netRelay = {};
                    for (let item in values.netRelay) {
                        if (values.netRelay[item]) {
                            netRelay[item] = values.netRelay[item];
                        }
                    }

                    values.device_key = trim(values['device_key']);
                    values['netRelay'] = netRelay;
                    if (!values['rtmp_address']) {
                        delete values['rtmp_address']
                    } else {
                        values.rtmp_address = trim(values['rtmp_address']);
                    }
                    // }
                    let methods = addDevice;
                    if (this.props.entry) { // 编辑
                        methods = editDevice;
                        values["device_id"] = this.props.entry.device_id;
                    }
                    this.setState({ loading: true });
                    methods({ "devices_list": [values] }).then(() => {
                        message.success(intl.formatMessage({ id: 'info.success' }), 1);
                        this.props.EditSuccess();
                        this.setState({
                            loading: false
                        })
                    }).catch(() => {
                        this.setState({
                            loading: false
                        })
                    })
                }
            }
        )
    }
    showMoreConfig = () => {
        this.setState({
            isMoreConfig: !this.state.isMoreConfig
        })
    }
    handleDeviceType = (value) => {
        let urlPlaceholder = null;
        let patter = null;
        let isRtsp = false;
        let showGb28181Rule = false;
        if (value === "photo") {
            urlPlaceholder = '192.168.1.179';
            patter = IP_PATTERN;
        } else if (value === "rtsp") {
            urlPlaceholder = 'rtsp://192.168.1.179:554/av0_0';
            patter = RTSP_PATTERN;
            isRtsp = true;
        } else if (value === "file") {
            patter = VIDEO_PATTERN;
            urlPlaceholder = '/data/videotest.mp4'
        } else if (value === 'usb') {
            patter = USB_PATTERN;
            urlPlaceholder = '/dev/USB0'
        } else if (value === 'gb28181') {
            patter = GB28181_PATTERN;
            urlPlaceholder = 'gb28181://...';
            showGb28181Rule = true;
        }
        this.setState({
            urlPlaceholder,
            url_patter: patter,
            isRtsp: isRtsp,
            showGb28181Rule
        })
    }

    componentDidMount = () => {
        const isEdit = this.props.entry ? true : false;
        if (isEdit) {
            let isRtsp = this.props.entry.device_type === 'rtsp' ? true : false;
            let rtmpOpen = this.props.entry.is_start_rtmp ? true : false
            this.setState({
                isRtsp: isRtsp,
                rtmpOpen: rtmpOpen
            })
        }
    }
    handleRtmpOpen = (value) => {
        this.setState({
            rtmpOpen: value.target.checked
        })
    }
    getPatter = (type) => {
        let patter = null;
        if (type === "photo") {
            patter = IP_PATTERN;
        } else if (type === "rtsp") {
            patter = RTSP_PATTERN;
        } else if (type === "file") {
            patter = VIDEO_PATTERN;
        } else if (type === 'usb') {
            patter = USB_PATTERN;
        } else if (type === 'gb28181') {
            patter = GB28181_PATTERN;
        }
        return patter;
    }
    render() {
        let entry = this.props.entry;
        const isEdit = this.props.entry ? true : false;
        const { getFieldDecorator } = this.props.form;
        const prefixSelector = getFieldDecorator('device_type', {
            initialValue: isEdit ? entry.device_type : 'rtsp',
        })(
            <Select style={{ width: 100 }} disabled={isEdit} onChange={this.handleDeviceType}>
                <Option value="photo">PHOTO</Option>
                <Option value="rtsp">RTSP</Option>
                <Option value="file">FILE</Option>
                <Option value="gb28181">GB28181</Option>
            </Select>
        );
        const advancedConfig = (
            <div className="advancedConfig" style={{ height: (this.state.isMoreConfig) ? 'auto' : 0, overflow: (this.state.isMoreConfig) ? 'hidden' : 'auto' }}>
                <Form.Item label={<FormattedMessage id='config.add.form.netRelay' />} style={{ margin: 0 }} {...FormItemLayout}></Form.Item>
                <Form.Item label={<FormattedMessage id='config.add.form.netRelay.ip' />} style={{ margin: 0 }} {...FormItemLayout}>
                    <Row>
                        <Col span={16}>
                            {getFieldDecorator('netRelay.ip', {
                                initialValue: isEdit && entry.netRelay ? entry.netRelay.ip : undefined,
                                rules: [{ pattern: IP_PATTERN, message: <FormattedMessage id='config.add.form.url.error.patter' /> }]
                            })(<Input placeholder="192.168.1.180" />)}
                        </Col>
                        <Col span={1} style={{ textAlign: "center" }}>:</Col>
                        <Col span={7}>
                            {getFieldDecorator('netRelay.net_port', {
                                initialValue: isEdit && entry.netRelay ? entry.netRelay.net_port : undefined
                            })(
                                <Input placeholder="6000"></Input>
                            )}
                        </Col>
                    </Row>
                </Form.Item>
                <Form.Item label={<FormattedMessage id='config.add.form.netRelay.door_port' />} labelCol={{ span: 6 }} wrapperCol={{ span: 14 }}>
                    <Row>
                        <Col span={6}>

                            {getFieldDecorator('netRelay.door_port', {
                                initialValue: isEdit && entry.netRelay ? entry.netRelay.door_port : undefined
                            })(
                                <Select placeholder="1" allowClear={true}>
                                    <Option key="1">1</Option>
                                    <Option key="2">2</Option>
                                    <Option key="3">3</Option>
                                    <Option key="4">4</Option>
                                </Select>
                            )}

                        </Col>
                        <Col span={18}>
                            <Form.Item label={<FormattedMessage id='config.add.form.netRelay.delay_time' />} labelCol={{ span: 15 }} wrapperCol={{ span: 9 }}>
                                {
                                    getFieldDecorator('netRelay.delay_time', {
                                        initialValue: isEdit && entry.netRelay ? entry.netRelay.delay_time : undefined
                                    })(
                                        <Input placeholder="5" addonAfter="s"></Input>
                                    )
                                }
                            </Form.Item>
                        </Col>
                    </Row>
                </Form.Item>
                {this.state.isRtsp ? <>
                    <Form.Item label={<><FormattedMessage id='config.add.form.rtmp.title' /><span className="red"><FormattedMessage id='config.add.form.rtmp.title.tips' /></span></>} style={{ margin: 0 }} labelCol={{ span: 7 }}></Form.Item>
                    <Form.Item label={<FormattedMessage id='config.add.form.rtmp.start' />} style={{ margin: 0 }} {...FormItemLayout}>
                        {getFieldDecorator('is_start_rtmp', {
                            initialValue: isEdit && entry.is_start_rtmp,
                            valuePropName: 'checked'
                        })(
                            <Checkbox onChange={this.handleRtmpOpen}></Checkbox>
                        )}
                    </Form.Item>
                    <Form.Item label={<FormattedMessage id='config.add.form.rtmp.device_key' />} style={{ marginTop: 0 }} {...FormItemLayout}>
                        {getFieldDecorator('rtmp_address', {
                            rules: [{ required: this.state.rtmpOpen ? true : false, message: <FormattedMessage id='input.required' /> },
                            {
                                pattern: RTMP_PATTERN, message: <FormattedMessage id='config.add.form.url.error.patter' />
                            }],
                            initialValue: isEdit ? entry.rtmp_address : undefined
                        })(
                            <Input placeholder='rtmp://127.0.0.1:1935/live/ch107' />
                        )}
                    </Form.Item>
                </> : null}
            </div >
        )

        return (
            <Form onSubmit={this.handleSubmit} labelCol={{ span: 8 }} wrapperCol={{ span: 16 }}>
                <Form.Item label={<FormattedMessage id='config.add.form.gate.name' />} {...FormItemLayout}>
                    {
                        getFieldDecorator('device_name', {
                            rules: [{ required: true, message: <FormattedMessage id='input.required' /> }],
                            initialValue: isEdit ? entry.device_name : undefined
                        })(
                            <Input></Input>
                        )
                    }
                </Form.Item>
                <Form.Item label={<FormattedMessage id='config.add.form.device_key' />} {...FormItemLayout}>
                    <Row gutter={8}>
                        <Col>
                            {getFieldDecorator('device_key', {
                                rules: [{ required: true, message: <FormattedMessage id='input.required' /> },
                                {
                                    pattern: (isEdit ? this.getPatter(entry.device_type) : this.state.url_patter), message: <FormattedMessage id='config.add.form.url.error.patter' />
                                }],
                                initialValue: isEdit ? entry.device_key : undefined
                            })(
                                <Input placeholder={this.state.urlPlaceholder} addonBefore={prefixSelector} disabled={isEdit} />
                            )}
                        </Col>
                        <Col style={{ lineHeight: '20px' }}>
                            {(this.state.showGb28181Rule || (isEdit && entry.device_type === 'gb28181')) ? <span style={{ lineHeight: 0 }} onClick={this.props.handleRuleShow} className="cursor"><FormattedMessage id='config.add.form.gb28181.addressRule' /></span> : ''}
                        </Col>
                    </Row>

                </Form.Item>
                <Form.Item label={<FormattedMessage id='config.add.form.group' />} {...FormItemLayout}>
                    {getFieldDecorator('group_id', {
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> }]
                    })(<GroupInput />

                    )}
                </Form.Item>
                <Form.Item label={<FormattedMessage id='config.add.form.permission' />} {...FormItemLayout}>
                    {getFieldDecorator('permission_id', {
                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> }],
                        initialValue: isEdit ? entry.permission_id : undefined
                    })(<PermissionInput name='permission_id' test="ipcstream-add-permission" />

                    )}
                </Form.Item>
                <Form.Item
                    style={{ margin: 0, textAlign: "right" }}
                    wrapperCol={{ span: 20 }}>
                    <span className="cursor" onClick={this.showMoreConfig}>
                        <FormattedMessage id='config.add.form.moreConfig' />
                    </span>
                </Form.Item>
                {advancedConfig}
                <Form.Item wrapperCol={{ offset: 10 }}>
                    <Button type="primary" htmlType="submit" loading={this.state.loading}><FormattedMessage id='button.apply' /></Button>
                </Form.Item>
            </Form>
        )

    }
}
const WrapperedEditForm = Form.create()(EditForm);

class IPCStreamAddList extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            video_list: [],
            addVisible: false,
            entry: null,
            total: 0,
            pageSize: 20,
            loading: false,
            currentPage: 1,
            isRuleVisible: false
        }
        this.columns = [
            {
                title: <FormattedMessage id='config.add.form.serial_number' />,
                dataIndex: 'name',
                width: 60,
                render: (text, record, index) => `${this.state.pageSize * (this.state.currentPage - 1) + 1 + index}`
            },
            {
                title: <FormattedMessage id='config.add.form.device_name' />,
                width: 80,
                dataIndex: 'device_name',
                render: (text) => {
                    return (
                        <Popover content={text}>
                            <span>{text}</span>
                        </Popover>
                    )
                }
            },
            {
                title: <FormattedMessage id='config.add.form.device_key' />,
                dataIndex: 'device_key',
                width: 150,
                render: (text) => {
                    return (
                        <Popover content={text}>
                            <span>{text}</span>
                        </Popover>
                    )
                }
            },
            {
                title: <FormattedMessage id='config.add.form.protocol' />,
                dataIndex: 'device_type',
                width: 50,
            },
            {
                title: <FormattedMessage id='config.add.form.permission' />,
                dataIndex: 'permission_name',
                width: 80,
                render: (text) => {
                    return (
                        <Popover content={text}>
                            <span>{text}</span>
                        </Popover>
                    )
                }
            },
            {
                title: <FormattedMessage id='user.list.form.action' />,
                dataIndex: 'device_id',
                key: 'device_id',
                width: 120,
                render: this.renderAction
            }
        ]
    }
    renderStatus = (value) => {
        if (value && value === 'Offline') {
            return <FormattedMessage id='config.detail.device.offline' />

        } else if (value && value === 'Online') {
            return <FormattedMessage id='config.detail.device.online' />
        }
    }
    handleRuleShow = () => {
        this.setState({
            isRuleVisible: true
        })
    }
    handleEditForm = (value, row) => {
        this.setState({
            entry: row,
            addVisible: true
        })
    }
    handleDeleteVideo = (id) => {
        remoteDevice(false, id).then(res => {
            message.success(intl.formatMessage({ id: 'info.success' }), 1);
            this.getVideoList(true);
        })
    }
    renderAction = (value, row) => {
        return <span>
            <span className="cursor" onClick={() => this.handleEditForm(null, row)}><FormattedMessage id='button.edit' /></span>
            <Divider type="vertical" />
            <Popconfirm title={<FormattedMessage id='config.detail.deleteTips' />} onConfirm={() => this.handleDeleteVideo(value)}>
                <span className="cursor"><FormattedMessage id='button.delete' /></span>
            </Popconfirm>
        </span>
    }
    handleAdd = () => {
        this.setState({
            addVisible: true,
            entry: null
        });
    }
    onPageChange = (currentPage) => {
        this.setState({ currentPage })
    }
    handleEditSuccess = () => {
        this.setState({
            addVisible: false,
            entry: null
        }, () => {
            this.getVideoList();
        });
    }
    handleRuleClose = () => {
        this.setState({
            isRuleVisible: false
        })
    }
    handleCancel = () => {
        this.setState({
            addVisible: false,
        });
    }
    getVideoList = async (toFirstPage) => {
        this.setState({
            loading: true
        })
        const video_list = (await getDevices()).devices_list;
        let totalCount = video_list.length;
        if (toFirstPage) {
            this.setState({
                currentPage: 1
            })
        }
        this.setState({
            video_list,
            total: totalCount,
            loading: false
        })

    }
    componentDidMount() {
        this.getVideoList();
    }
    render() {
        return (
            <div>
                <Title items={breadcrumbSetting} />
                <div style={{ margin: '24px', padding: '24px', backgroundColor: '#FFFFFFFF' }}>
                    <div style={{ marginBottom: "20px" }}><Button type="primary" icon="plus" onClick={this.handleAdd}><FormattedMessage id='button.add' /></Button></div>
                    <Table scroll={{ x: 800 }} rowKey={record => record.device_id} loading={this.state.loading} pagination={{ total: this.state.total, pageSize: this.state.pageSize, onChange: this.onPageChange, current: this.state.currentPage }} columns={this.columns} dataSource={this.state.video_list}></Table>
                </div>
                <Modal
                    width={630}
                    title={!this.state.entry ? <FormattedMessage id='button.add' /> : <FormattedMessage id='button.edit' />} destroyOnClose={true} footer={null} onAddSuccess={this.handleOk}
                    onCancel={this.handleCancel}
                    visible={this.state.addVisible}>
                    <WrapperedEditForm handleRuleShow={this.handleRuleShow} EditSuccess={this.handleEditSuccess} entry={this.state.entry} />
                </Modal>
                <Modal
                    width={630}
                    title={<FormattedMessage id='config.add.form.gb28181.addressRule' />} visible={this.state.isRuleVisible} onOk={this.handleRuleClose} onCancel={this.handleRuleClose}>
                    <p style={{ wordBreak: "break-all", margin:'0px 0px 10px 0'}}>
                        <h4><FormattedMessage id='config.add.form.gb28181.rule.udp1' /></h4>
                        <div>gb28181://34020000002019000001:123456@35.26.240.99:5666?deviceid=35018284001310090010#localid=12478792871163624979#localip=172.10.18.201#localmediaport=20108</div>
                        <h4 style={{ margin: '5px 0 0 0' }}><FormattedMessage id='config.add.form.gb28181.rule.udp2' /></h4>
                        <div>gb28181_playback://34020000002019000001:123456@35.26.240.99:5666?deviceid=35018284001310090010#devicetype=3#localid=12478792871163624979#localip=172.10.18.201#localmediaport=20108#begtime=20191018160000#endtime=20191026163713</div>
                        <h4 style={{ margin: '5px 0 0 0' }}><FormattedMessage id='config.add.form.gb28181.rule.tcp1' /></h4>
                        <div>gb28181://34020000002019000001:123456@35.26.240.99:5666?deviceid=35018284001310090010#localid=12478792871163624979#localip=172.10.18.201</div>
                        <h4 style={{ margin: '5px 0 0 0' }}><FormattedMessage id='config.add.form.gb28181.rule.tcp2' /></h4>
                        <div>gb28181_playback://34020000002019000001:123456@35.26.240.99:5666?deviceid=35018284001310090010#devicetype=3#localid=12478792871163624979#localip=172.10.18.201#localmediaport=20108#begtime=20191018160000#endtime=20191026163713</div>
                    </p>
                    <p>
                        <div>deviceid : <FormattedMessage id="config.add.form.gb28181.rule.deviceid" /></div> 
                        <div>devicetype : <FormattedMessage id="config.add.form.gb28181.rule.devicetype" /></div>
                        <div>localid : <FormattedMessage id="config.add.form.gb28181.rule.localid" /></div>
                        <div>localip : <FormattedMessage id="config.add.form.gb28181.rule.localip" /></div>
                        <div>localmediaport : <FormattedMessage id="config.add.form.gb28181.rule.localmediaport" /></div>
                        <div>begtime : <FormattedMessage id="config.add.form.gb28181.rule.begtime" /></div>
                        <div> endtime : <FormattedMessage id="config.add.form.gb28181.rule.endtime" /></div>
                    </p>
                </Modal>
            </div>
        )
    }
}
export default IPCStreamAddList;
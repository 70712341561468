
import React from "react";
import moment from "moment-timezone";
import { Row, Col, DatePicker, Tag } from "antd";
import {  FormattedMessage } from "react-intl";
class DateRange extends React.Component {
  state = {
    startValue: null,
    endValue: null
  };
  static getDerivedStateFromProps(nextProps) {
    if ('value' in nextProps) {
      return {
        ...(nextProps.value || {})
      }
    }
    return null
  }
  disabledStartDate = (startValue) => {
    const endValue = this.state.endValue;
    if (!startValue || !endValue) {
      return false;
    }
    return startValue.valueOf() > endValue.valueOf();
  }

  disabledEndDate = (endValue) => {
    const startValue = this.state.startValue;
    if (!endValue || !startValue) {
      return false;
    }
    return endValue.valueOf() <= startValue.valueOf();
  }
  triggerChange = (changeValue) => {
    const onChange = this.props.onChange;
    if (onChange) {
      onChange(Object.assign({}, this.state, changeValue));
    }
  }
  onStartChange = async (value) => {
    this.setState({ startValue: value }, () => { this.triggerChange({ startValue: value }) })
  }

  onEndChange = async (value) => {
    this.setState({ endValue: value }, () => { this.triggerChange({ endValue: value }) })
  }

  setRecent = async (minutes) => {
    let curMoment = moment();
    let prevMoment = curMoment.clone().subtract(moment.duration(minutes, 'minutes'))
    await this.onStartChange(prevMoment);
    await this.onEndChange(curMoment);
  }

  render() {
    const { startValue, endValue } = this.state;
    return (
      <div>
        <Row>
          <Col span={12}>
            <DatePicker
              disabledDate={this.disabledStartDate}
              showTime={{
                defaultValue: moment('00:00:00', 'HH:mm')
              }}
              format="YYYY-MM-DD HH:mm"
              value={startValue}
              style={{width:"100%"}}
              onChange={this.onStartChange}
            />
          </Col>
          <Col span={12}>
            <DatePicker
              disabledDate={this.disabledEndDate}
              style={{width:"100%"}}
              showTime={{
                defaultValue: moment('23:59:59', 'HH:mm')
              }}
              format="YYYY-MM-DD HH:mm"
              value={endValue}
              onChange={this.onEndChange}
            />
          </Col>
          </Row>
          <Row>
            <Col span={8} style={{ textAlign: 'center' }} ><Tag onClick={() => this.setRecent(60)}><FormattedMessage id='event.stream.dateTime.1hr' /></Tag></Col>
            <Col span={8} style={{ textAlign: 'center' }} ><Tag onClick={() => this.setRecent(180)}><FormattedMessage id='event.stream.dateTime.3hr' /></Tag></Col>
            <Col span={8} style={{ textAlign: 'center' }} ><Tag onClick={() => this.setRecent(1440)}><FormattedMessage id='event.stream.dateTime.1day' /></Tag></Col>
          </Row>
        </div>
        );
      }
    }
  
  export default DateRange;

export default {
    "basic.info.panal.title":"基础信息",
    "basic.info.panal.ip":"IP地址",
    "basic.info.panal.hostname":"主机名",
    "basic.info.panal.systemtime":"系统时间",
    "basic.info.panal.boardTemperature":"主板温度",
   
    "basic.info.panal.systemVersion":"系统版本",
    
    // "ipc.IPCStream.title":"网络摄像机(视频流)信息",
    // "ipc.IPCStream.number":"网络摄像机(视频流)数量",

    "basic.info.panal.devices":"设备信息",
    "basic.info.panal.devices_number":"设备数量",

    "basic.info.cpu.info.cpuCount":'核',

    "basic.info.panal.model":"产品型号",
    "basic.info.panal.workMode":"工作模式",
    "basic.info.panal.uptime":"系统运行时间",
    "basic.info.panal.SN":'S/N',
    "basic.info.panal.mcu":'MCU版本',
    "basic.info.panal.hardwareInfo":'硬件版本',
    "basic.info.panal.wanIp":'WAN口IP',
    "basic.info.panal.lanIp":'LAN口IP',
    "basic.info.panal.wan":'WAN口带宽',
    "basic.info.panal.lan":'LAN口带宽',
    
    "basic.info.version.title":"版本号：FIQA",

    'basic.info.title.chipTemperature':'芯片结温(℃)',
    "basic.info.title.fan":"风扇转速(x1000r/min)",
    "basic.info.percentage.CPUsage":"CPU使用率",
    "basic.info.percentage.TPUsage":"TPU使用率",
    "basic.info.percentage.Memorysage":"内存使用率",
    "basic.info.percentage.memory.Video":"Video内存",
    "basic.info.percentage.memory.TPU":"TPU内存",
    "basic.info.percentage.memory.system":"系统内存",
    "basic.info.percentage.total":"总共",
    "basic.info.percentage.available":"可用",
   
    "basic.info.percentage.used":"已用",
    "basic.info.percentage.inDisk":"内置硬盘",
    "basic.info.percentage.outDisk":"外扩硬盘",
    "basic.info.percentage.outDisk.total":"总容量",
    "basic.info.percentage.outDisk.point":"挂载点",
    "basic.info.percentage.outDisk.available":"当前可用",
    'basic.info.percentage.flow':'移动数据',
    
}
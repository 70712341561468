import React, { Component } from 'react'
import { Select } from 'antd'
import axios from '../../utils/AxiosWrapper'
import { COMMON_PREFIX } from '../../utils/Config';
class PermissionInput extends Component {

    constructor(props) {
        super(props);
        // const value = props.value;
        this.mode = props.mode || '';
        this.state = {
            // selected: this.transformData(value),
            groups: []
        }
    }

    componentDidMount() {
        axios.get(`${COMMON_PREFIX}/permissions`).then(
            (resp) => {
                this.setState({
                    groups: resp.data.data
                })
            }
        )
    }

    handleSelect = () => {
        if (this.mode && this.mode === 'tags')
            this.SelectRef.blur();
    }

    triggerChange = (changedValue) => {
        const onChange = this.props.onChange;
        if (onChange) {
            onChange(changedValue)
        }
    }

    render() {
        const { mode } = this.props;
        // const { selected } = this.state;
        return (
            <div test={this.props.test}>
                <Select
                    {...this.props}
                    // ref={elem => this.SelectRef = elem}
                    mode={mode}
                    // value={selected}
                    // onChange={this.handleChange}
                    // onSelect={this.handleSelect}
                    disabled={this.props.disabled}
                >
                    {this.state.groups.map(
                        (group) => <Select.Option key={group._id}>{group.info.name}</Select.Option>
                    )}
                </Select>
            </div>

        )
    }


}
//export default GroupInput
export default PermissionInput
import React, { Component } from 'react'
import { Layout, Select, message, Form, Row, Col, Button } from 'antd'
import { FormattedMessage } from 'react-intl';
import { intl } from '../../Intel';
import { setRegularReboot, getRegularReboot } from '../../services/system'
const weeks = [
    { value: 'never', name: 'never' },
    { value: 'daily', name: 'daily' },
    { value: '1', name: 'monday' },
    { value: '2', name: 'tuesday' },
    { value: '3', name: 'wednesday' },
    { value: '4', name: 'thursday' },
    { value: '5', name: 'friday' },
    { value: '6', name: 'saturday' },
    { value: '7', name: 'sunday' }
]
const times = [];
for (let i = 0; i < 24; i++) {
    if (i < 10) {
        times.push({value:`0${i}`,name:`0${i}:00`});
    } else {
        times.push({value:`${i}`,name:`${i}:00`});
    }
}


class RebootSetting extends Component {
    constructor(props) {
        super(props);
        this.state = {
            days: '',
            hour: '',
            loading: true,
            isNever: false,
            submiting:false
        }
    }
    componentDidMount() {
        getRegularReboot().then(res => {
            if(!res.days){
                this.setState({
                    isNever: true
                })
            }
            this.setState({
                loading: false,
                days: res.days,
                hour: res.hour
            })
        }).catch(() => {
            this.setState({
                loading: false
            })
        })
    }
    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields(
            (err, value) => {
                if (!err) {
                    if(value.days==='never'){
                        value.days ='';  
                        value.hour =''; 
                    }else if(value.days==='daily'){
                        value.days ='1,2,3,4,5,6,7';  
                    }
                    this.setState({
                        submiting : true
                    })
                    setRegularReboot(value).then(res => {
                        this.setState({
                            submiting : false
                        })
                        message.success(intl.formatMessage({ id: 'info.success' }), 1);
                    }).catch(() => {
                        this.setState({
                            submiting : false
                        })
                    })
                }
            })
    }
    handleDays = (value) => {
       if(value === 'never'){
           this.setState({
            isNever: true
           })
       }else{
        this.setState({
            isNever: false
           })
       }
    }
    render() {
        const { getFieldDecorator } = this.props.form;
        let days = this.state.days;
        if (days.split(',').length > 2) {
            days = 'daily'
        }
        if (!days) {
            days = 'never'
        }
        return (
            <div>
                <Layout style={{ margin: '20px 24px', backgroundColor: '#FFFFFF', minHeight: '420px', paddingTop: 100 }}>
                    <Form onSubmit={this.handleSubmit}>
                        <Row justify="center" type="flex">
                            <Col>
                                <Form.Item>
                                    {getFieldDecorator('days', {
                                        rules: [{ required: true, message: <FormattedMessage id='input.required' /> }],
                                        initialValue: this.state.loading ? undefined : days
                                    })(
                                        <Select style={{ width: 120 }} loading={this.state.loading} onChange={this.handleDays}>
                                            {weeks.map(item =>
                                                <Select.Option key={item.value}><FormattedMessage id={`settings.reboot.weeks.${item.name}`} /></Select.Option>
                                            )}
                                        </Select>

                                    )}
                                </Form.Item>
                            </Col>
                            {!this.state.isNever ?
                                <React.Fragment>
                                    <Col style={{ padding: 10 }}> <FormattedMessage id='settings.time.stay' /></Col>
                                    <Col >
                                        <Form.Item>
                                            {getFieldDecorator('hour', {
                                                rules: [{ required: true, message: <FormattedMessage id='select.required' /> }],
                                                initialValue: this.state.loading ? undefined : this.state.hour
                                            })(
                                                <Select style={{ width: 120 }} loading={this.state.loading}>
                                                    {times.map(item =>
                                                        <Select.Option key={item.value}>{item.name}</Select.Option>
                                                    )}
                                                </Select>
                                            )}
                                        </Form.Item>
                                    </Col>
                                </React.Fragment>
                                : null}

                        </Row>
                        <Form.Item style={{ textAlign: 'center' }}>
                            <Button loading={this.state.submiting} type="primary" htmlType="submit"><FormattedMessage id='button.apply' /></Button>
                        </Form.Item>
                    </Form>
                </Layout>
            </div>
        )
    }
}
export default Form.create()(RebootSetting);


import { uuid } from '../utils/Config';

import api from '../utils/api';

const SOPHON_FACE = '/SophonFace/api/permissions';


//创建权限组
const addPermission = (data) => {
    let id = uuid();
    return api({
        method: 'put',
        url: `${SOPHON_FACE}/${id}`,
        data
    })
}

// 获取权限组 
const getPermission = (data) => api({
    method: 'get',
    url: `${SOPHON_FACE}`,
    data
}).then(res => res.data)


// 编辑权限组 

const editPermission = (id,data) => api({
    method: 'put',
    url: `${SOPHON_FACE}/${id}`,
    data
})

// 删除权限组
const deletePermission = (id) => api({
    method: 'delete',
    url: `${SOPHON_FACE}/${id}`
})

// 获取人员组的关系
const detailPermission = (id) => api({
    method: 'get',
    url: `${SOPHON_FACE}/${id}`
}).then(res => res.data)

export {
    addPermission,
    getPermission,
    editPermission,
    detailPermission,
    deletePermission
}




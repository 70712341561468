export default {
    "event.filter.userType":'人员类型',
    "event.filter.room":'房间号',
    "event.filter.floor":'楼层号',
    "event.title.list":'过往列表',
    "event.title.img":'记录图片',
    "event.title.seleted":'暂未选中',
    "event.title.details":'详细信息',
    "event.description.entry":'入镜时间',
    "event.description.go":'出镜时间',
    "event.bar.timestamp":"时间",
    "event.bar.score":"得分: {value}",
    "event.bar.score.title":"得分",
    "event.bar.deviceIP":"设备IP",
    "event.bar.face":"底库",
    "event.bar.original":"原图",
    "event.alert.msg2":"共:{number}条历史记录",
    "event.stream.unknown":"未知",
    "event.stream.videoname":"视频流",
    "event.stream.Datetime":"时间",
    "event.stream.dateTime.1hr":"1小时",
    "event.stream.dateTime.3hr":"3小时",
    "event.stream.dateTime.1day":"1天",
    "event.stream.details.title.event":"事件",
    "event.stream.details.title.face":"人脸",
    "event.history.epName":'设备',
    "event.history.query.hasMore":'更多...',
    "event.searchImage.form.title":'搜索条件',
    "event.searchImage.content.title":'结果集',
    "event.searchImage.detail.title":'详细信息',
    "event.searchImage.form.startTime":'开始时间',
    "event.searchImage.form.endTime":'结束时间',
    "event.searchImage.content.tips":'结果查询中，请稍候...',
    "event.searchImage.content.result.tips":'由于结果过多，为您查询到',
    "event.searchImage.content.result.nextSearch":'点击接着查询',
    "event.searchImage.searchOrder":'顺序',
    "event.searchImage.searchOrder.order":'顺序',
    "event.searchImage.searchOrder.reverse":'逆序',
    "event.searchImage.form.upload.warning":'请先上传一张人脸',
    "event.searchImage.form.time.warning":'开始时间要小于结束时间',
    "event.searchImage.form.upload.type":' 请上传JPG/PNG/JPEG的图片',
    "event.searchImage.form.upload.size":' 请上传小于5M的图片',

    "event.compare.face.title" :'1:1比对',
    "event.compare.base.title" :'底库比对',
    "event.compare.result.title" :'对比结果',
    "event.compare.result.judge": '是否同一个人',
    "event.compare.result.match.have": '最佳匹配',
    "event.compare.result.match.no.have": '暂无匹配'
   
}
import React from "react";
import moment from "moment-timezone";
import axios from "axios";
import { Layout, Form, Table, Avatar, Popover, message, Descriptions, Row, Col } from "antd";
import { FormattedMessage } from 'react-intl';
import { USER_TYPE, USER_TYPE1 } from "../../../utils/Config";
import LogQueryForm from "./LogQueryForm";
import Title from '../../common/Title'
import { intl } from '../../../Intel';
import { getTheme } from '../../../services/system';
import { getDevices } from '../../../services/devices'
moment.locale("zh-tw");
const { Content } = Layout;
const WrappedLogQueryForm = Form.create()(LogQueryForm);
const API_PREFIX = '';
const breadcrumbSetting = [{
  path: '/',
  breadcrumbName: <FormattedMessage id='home' />
}, {
  path: '/Event/History',
  breadcrumbName: <FormattedMessage id='sidemenu.Event' />
}, {
  breadcrumbName: <FormattedMessage id='sidemenu.Event.History' />
}];

class StreamEventMain extends React.Component {
  constructor(props) {
    super(props);
    this.params = {
      page: 0,
      per_page: 10

    }
    this.state = {
      queryResult: [],
      pagination: {},
      selectedEvent: null,
      tableLoading: false,
      gateList: [],
      // groupData: [],
      videoData: []
    };
    this.columns = [
      {
        title: <FormattedMessage id='event.bar.face' />,
        dataIndex: "regId",
        key: "regId",
        width: 100,
        align: 'center',
        fixed: 'left',
        render: (text, record) => {
          if (!record.regId) {
            return <div style={{ width: 80, height: 80, overflow: 'hidden', margin: '0 auto' }}>
              <Avatar size={80} shape="square" icon="user" />
            </div>
          }
          else {
            return (
              <div style={{ width: 80,
               textAlign:'center',height: 80, 
               backgroundImage:`url(${API_PREFIX}/SophonFace/api/images/${text})`,
               backgroundSize:'contain',
               backgroundRepeat:'no-repeat',
               backgroundPosition:'center',
               borderRadius: 5, overflow: 'hidden', backgroundColor: "black", margin: '0 auto' }}>
              </div>

            )
          }
        }
      },
      {
        title: <FormattedMessage id='event.bar.original' />,
        dataIndex: "eventRecoFaceId",
        key: "eventRecoFaceId",
        width: 100,
        align: 'center',
        fixed: 'left',
        render: (text, record) => (
          <div style={{ width: 80,
            height: 80,
            backgroundSize:'contain',
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
            backgroundImage:`url(${API_PREFIX}/SophonFace/api/event_images/${record.eventRecoFaceId}?datetime=${record.time})`,
           borderRadius: 5, overflow: 'hidden', backgroundColor: 'black', margin: '0 auto' }}>
          </div>
        )
      },
      {
        title: <FormattedMessage id="event.filter.userType" />,
        dataIndex: "userType",
        key: "userType",
        align: 'center',
        width: 80,
        render: this.renderUserType
      },
      {
        title: <FormattedMessage id='user.list.form.name' />,
        dataIndex: "name",
        key: "name",
        width: 80,
        align: 'center',
        render: (text) => {
          if (!text) {
            // return <span color="red"><FormattedMessage id='event.stream.unknown' /> </span>
            return <FormattedMessage id='event.stream.unknown' />
          }
          else {
            return text
          }
        }
      },
      {
        title: <FormattedMessage id='event.stream.Datetime' />,
        dataIndex: "time",
        key: "time",
        width: 80,
        align: 'center',
        render: this.formTime
      },
      {
        title: <FormattedMessage id='event.bar.score.title' />,
        dataIndex: "score",
        key: "score",
        align: 'center',
        width: 75,
        render: (text, record) => <span>{text}</span>
      },
      {
        title: <FormattedMessage id="event.filter.room" />,
        dataIndex: "room",
        key: "room",
        width: 80,
        align: 'center'
      }
    ];
    this.columns1 = [
      {
        title: <FormattedMessage id='event.bar.face' />,
        dataIndex: "regId",
        key: "regId",
        width: 100,
        align: 'center',
        render: (text, record) => {
          if (!record.regId) {
            return <div style={{ width: 80, height: 80, overflow: 'hidden', margin: '0 auto' }}>
              <Avatar size={80} shape="square" icon="user" />
            </div>
          }
          else {
            return (
              <div style={{ width: 80,
               textAlign:'center',height: 80, 
               backgroundImage:`url(${API_PREFIX}/SophonFace/api/images/${text})`,
               backgroundSize:'contain',
               backgroundRepeat:'no-repeat',
               backgroundPosition:'center',
               borderRadius: 5, overflow: 'hidden', backgroundColor: "black", margin: '0 auto' }}>
              </div>

            )
          }
        }
      },
      {
        title: <FormattedMessage id='event.bar.original' />,
        dataIndex: "eventRecoFaceId",
        key: "eventRecoFaceId",
        width: 100,
        align: 'center',
        render: (text, record) => (
          <div style={{ width: 80,
            height: 80,
            backgroundSize:'contain',
            backgroundRepeat:'no-repeat',
            backgroundPosition:'center',
            backgroundImage:`url(${API_PREFIX}/SophonFace/api/event_images/${record.eventRecoFaceId}?datetime=${record.time})`,
           borderRadius: 5, overflow: 'hidden', backgroundColor: 'black', margin: '0 auto' }}>
          </div>
        )
      },
      {
        title: <FormattedMessage id="event.filter.userType" />,
        dataIndex: "userType",
        key: "userType",
        align: 'center',
        width: 80,
        render: this.renderUserType
      },
      {
        title: <FormattedMessage id='user.list.form.name' />,
        dataIndex: "name",
        key: "name",
        width: 80,
        align: 'center',
        render: (text, record) => {
          if(!text) {
            return <FormattedMessage id='event.stream.unknown' />
          }
          else {
            return text
          }
        }
      },
      {
        title: <FormattedMessage id='event.stream.Datetime' />,
        dataIndex: "time",
        key: "time",
        width: 80,
        align: 'center',
        render: this.formTime
      },
      {
        title: <FormattedMessage id='event.bar.score.title' />,
        dataIndex: "score",
        key: "score",
        align: 'center',
        width: 80,
        render: (text, record) => <span>{text}</span>
      }
    ];
  }
  formTime = (value) => {
    return moment(value * 1000).format('HH:mm:ss');
  }
  renderUserType = (value) => {
    let NEW_USER_TYPE = null;
    if (this.state.theme === 'PARK') {
      NEW_USER_TYPE = USER_TYPE;
    } else {
      NEW_USER_TYPE = USER_TYPE1;
    }
    for (let item of NEW_USER_TYPE) {
      if (item.typeId === value) {
        return <FormattedMessage id={`user.add.type.${item.description}`} />
      }
    }
  }
  componentDidMount = async () => {
    getTheme().then(res => {
      this.setState({
        theme : res
      },() => {
        this.fetch();
      })
    })

    const video_list = (await getDevices()).devices_list;
    let videos = [];
    for (let i = 0; i < video_list.length; ++i) {
      videos.push({
        key: video_list[i].device_id,
        name: video_list[i].device_name,
      })
    }
    this.setState({ videoData: videos })

  }

  fetch = async () => {
    this.setState({ tableLoading: true })
    let result = (await axios.get(`${API_PREFIX}/SophonFace/api/events`, { params: this.params, timeout: 60000 })).data;
    if (result.error) {
      message.error(intl.formatMessage({ id: 'network.error' }), 1.5)
      return
    }
    if (result.data) {
      let pagination = result.pagination;
      let tableData = result.data.map((data, index) => {
        return {
          key: data._id,
          regId: data.common.identity_info.image_id !== undefined ? data.common.identity_info.image_id : null,
          room: data.common.identity_info.ext_info !== undefined ? data.common.identity_info.ext_info.room : null,
          floor: data.common.identity_info.ext_info !== undefined ? data.common.identity_info.ext_info.floor : null,
          name: data.common.identity_name,
          userType: data.common.person_type || 4,
          eventOutTime: data.video_data.out_timestamp ? data.video_data.out_timestamp : data.timestamp,
          eventRecoFaceId: data.common.recognize_face_pic_id,
          eventRecoBackgroundId: data.common.spot_pic_id,
          score: data.common.score.toFixed(2),
          groupName: data.common.groupName,
          videoId: data.common.ep_info.ep_id,
          videoName: data.common.ep_info.ep_name,
          time: data.timestamp,//修改后
          age: data.common.recognize_face_attr.age,
          gender: data.common.recognize_face_attr.gender,
          glasses: data.common.recognize_face_attr.glasses
        };
      });
      this.setState({
        selectedEvent: tableData[0],
        queryResult: tableData,
        pagination: pagination,
        tableLoading: false
      })
    }

  }

  queryCallback = async (formParams) => {
    this.params = Object.assign({}, formParams, { page: 0, per_page: this.params.per_page })
    this.fetch()
  };

  exportCSV = () => {
    var csv = 'Name,Gate,Score,Norm,Time,Review,Enroll\n';
    this.state.queryResult.forEach(function (item) {
      let row = [
        item.name,
        item.gate,
        item.score,
        item.norm,
        item.time,
        item.review,
        item.enroll
      ]
      csv += row.join(',') + "\n";
    });

    var hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = 'events.csv';
    hiddenElement.click();
  }

  handleTableChange = (pagination) => {
    this.params.page = pagination.current - 1;
    this.fetch()
  }

  render() {
    const selectedEvent = this.state.selectedEvent;
    const theme = this.state.theme;
    let column = null; //表格
    let isHasDescItemRoom = null; // 右下角房间号
    let isHasDescItemFloor = null; // 右下角楼层号
    let faceAttrItem = []; //人脸属性
    if (theme === 'PARK') {
      column = this.columns1;
    } else {
      column = this.columns;
      if (selectedEvent) {
        isHasDescItemFloor = selectedEvent.room ? <Descriptions.Item label={<FormattedMessage id='event.filter.room' />}>{selectedEvent.room}</Descriptions.Item> : null;
        isHasDescItemRoom = selectedEvent.floor ? <Descriptions.Item label={<FormattedMessage id='event.filter.floor' />}>{selectedEvent.floor}</Descriptions.Item> : null;
      }
    }

    if (selectedEvent) {
      if (selectedEvent.age) {
        faceAttrItem.push(
          <Descriptions.Item label={<FormattedMessage id='config.detail.ipc.stream.box.age' />}>{<Popover content={intl.formatMessage({ id: 'config.video.face.attr.age.' + selectedEvent.age })}>
            {selectedEvent.age ? intl.formatMessage({ id: 'config.video.face.attr.age.' + selectedEvent.age }) : ''}
          </Popover>}</Descriptions.Item>)
      }
      if (selectedEvent.gender) {
        faceAttrItem.push(
          <Descriptions.Item label={<FormattedMessage id='config.detail.ipc.stream.box.gender' />}>{<Popover content={intl.formatMessage({ id: 'config.video.face.attr.gender.' + selectedEvent.gender })}>
            {selectedEvent.gender ? intl.formatMessage({ id: 'config.video.face.attr.gender.' + selectedEvent.gender }) : ''}
          </Popover>}</Descriptions.Item>
        )
      }
      if (selectedEvent.glasses) {
        faceAttrItem.push(
          <Descriptions.Item label={<FormattedMessage id='config.detail.ipc.stream.box.glasses' />}>{<Popover content={intl.formatMessage({ id: 'config.video.face.attr.glasses.' + selectedEvent.glasses })}>
            {selectedEvent.glasses ? intl.formatMessage({ id: 'config.video.face.attr.glasses.' + selectedEvent.glasses }) : ''}
          </Popover>}</Descriptions.Item>
        )
      }
    }
    return (
      <div>
        <Title items={breadcrumbSetting} />
        <div style={{ margin: "24px",minWidth: 1100, padding: "24px",overflowX:'scroll', "backgroundColor": "rgb(255, 255, 255)" }}>
          <Content style={{ minHeight: 280 }}>
            <WrappedLogQueryForm
               theme={this.state.theme}
              queryCallback={this.queryCallback}
              // groupData={this.state.groupData}
              videoData={this.state.videoData}
              queryStart={() => { this.setState({ tableLoading: true }) }}
            />
            <div >
              <Row>
                <Col span={12}>
                  <div style={{ height:30, lineHeight: '30px', color: 'rgba(0,0,0,0.85)', fontSize: 16, fontWeight: 700 }}><FormattedMessage id="event.title.list" /></div>
                  <Table
                    columns={column}
                    size="small"
                    dataSource={this.state.queryResult}
                    loading={this.state.tableLoading}
                    scroll={{ x:"650px",y: "calc(100vh - 380px)" }}
                    onChange={this.handleTableChange}
                    queryCallback={this.queryCallback}
                    pagination={{ showQuickJumper: true, defaultPageSize: this.params.per_page, total: this.state.pagination.total_count || 0, current: this.params.page + 1 }}
                    rowClassName={(record, index) => {
                      if (record.userType === 4 || !record.userType) {
                        return "red"
                      }
                    }}
                    onRow={(record) => {
                      return {
                        onClick: () => { this.setState({ key: record.key, selectedEvent: record }) },
                      }
                    }}
                  />
                </Col>
                {selectedEvent ? <Col span={11} offset={1}>
                  <div style={{ height:30, lineHeight: '30px', color: 'rgba(0,0,0,0.85)', fontSize: 16, fontWeight: 700}}><FormattedMessage id="event.title.img" /></div>
                  <div style={{
                    height: "40vh", textAlign: 'center', backgroundColor: "rgb(238, 240, 243)",
                    backgroundImage: `url(${API_PREFIX}/SophonFace/api/event_images/${selectedEvent.eventRecoBackgroundId}?datetime=${selectedEvent.time})`,
                    backgroundSize: "contain",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat"
                  }}>
                    {/* <img alt="" style={{objectFit: 'contain' }} src={`${API_PREFIX}/SophonFace/api/event_images/${selectedEvent.eventRecoBackgroundId}?datetime=${selectedEvent.time}`} /> */}
                  </div>
                  <div style={{ marginTop: "20px" }}>
                    <Descriptions size="small" column={2} bordered title={<FormattedMessage id='event.title.details' />}>
                      <Descriptions.Item label={<FormattedMessage id='user.list.form.name' />}>{selectedEvent.name}</Descriptions.Item>
                      <Descriptions.Item label={<FormattedMessage id='event.filter.userType' />}>
                        {this.renderUserType(selectedEvent.userType)}
                      </Descriptions.Item>
                      <Descriptions.Item label={<FormattedMessage id='event.history.epName' />}>{selectedEvent.videoName}</Descriptions.Item>
                      <Descriptions.Item label={<FormattedMessage id='event.description.entry' />}>{<Popover content={(new Date(selectedEvent.time * 1000)).toLocaleString()}>
                        {selectedEvent.time ? new Date(selectedEvent.time * 1000).toLocaleString() : ''}
                      </Popover>}</Descriptions.Item>
                      <Descriptions.Item label={<FormattedMessage id='event.bar.score.title' />}>{selectedEvent.score}</Descriptions.Item>
                      <Descriptions.Item label={<FormattedMessage id='event.description.go' />}>{<Popover content={(new Date(selectedEvent.eventOutTime * 1000)).toLocaleString()}>
                        {selectedEvent.eventOutTime ? (new Date(selectedEvent.eventOutTime * 1000)).toLocaleString() : ''}
                      </Popover>}</Descriptions.Item>
                      {isHasDescItemFloor}
                      {isHasDescItemRoom}
                      {faceAttrItem}
                    </Descriptions>
                  </div>
                </Col> : <Col span={10} offset={1}>
                    <p style={{ height: 24, lineHeight: '24px', color: 'rgba(0,0,0,0.85)', fontSize: 16, fontWeight: 700, marginBottom: '24px' }}><FormattedMessage id='event.title.img' /></p>
                    <p> <FormattedMessage id='event.title.seleted' /></p></Col>}

              </Row>

            </div>
          </Content>
        </div>
      </div>


    );
  }
}

export default StreamEventMain;

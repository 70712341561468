import React, { Component } from 'react';
import { Alert, Form } from 'antd'
import { FormattedMessage } from 'react-intl';
import EditForm from './EditForm';

const WrapperedEditForm = Form.create()(EditForm);
class SetupWan extends Component {

	handleSubmit = (values,isReboot) => {
		if(isReboot){
			this.props.history.push(
				{
					pathname: '/SetupWorkMode',
					eth0: values
				}
			)
		}else{
			this.props.history.push(
				{
					pathname: '/SetupWorkMode'
				}
			)
		}
	}
	render() {
		return (
			<div>
				<div style={{ margin: '18px 0' }}>
					<div><Alert message={<FormattedMessage id='setup.wan.alert' />} showIcon /></div>
				</div>
				<div style={{ backgroundColor: '#FFFFFF', padding: '48px 120px 33px', marginBottom: 242 }}>
					<WrapperedEditForm onSubmit={this.handleSubmit} entry="eth0" {...this.props} />
				</div>
			</div>
		)
	}
}

export default SetupWan